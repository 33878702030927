import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useContextState } from '../../ContextAPI';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export function LineChart() {
    const { clientIndex, totalClicks, totalImpressions, totalClicksByClient, totalImpressionsByClient, singleClicks, singleImpressions, campaignIndex } = useContextState()

    const options = {
        elements: {
            point: {
                radius: 0
            },
            line: {
                tension: 0.3
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Impressions/Clicks',
            },
        },
        scales: {
            x: {
                grid: {
                    display: true
                },
            },
            y: {
                grid: {
                    display: true
                },
            }
        },
    };

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const labels = months
    const clickData = clientIndex === 'all' ? totalClicks : campaignIndex === 'all' ? totalClicksByClient : singleClicks
    const impressionData = clientIndex === 'all' ? totalImpressions : campaignIndex === 'all' ? totalImpressionsByClient : singleImpressions

    const data = {
        labels,
        datasets: [
            {
                label: 'Ad Impressions',
                data: impressionData,
                borderColor: 'rgb(42, 46, 69)',
                backgroundColor: 'rgba(42, 46, 69, 1)',
            },
            {
                label: 'Ad Clicks',
                data: clickData,
                borderColor: 'rgb(235, 130, 88)',
                backgroundColor: 'rgba(235, 130, 88, 1)',
            },
        ],
    };

    return <Line options={options} data={data} />;
}
