import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useContextState } from '../ContextAPI';


/* const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}; */

export default function ClientModalAdd() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { createClient, fillDetailsToast } = useContextState()

    const [clientFormData, setClientFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: ''
    })

    const clearForm = () => {

        setClientFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            companyName: '',
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (clientFormData.firstName === '' &&
            clientFormData.lastName === '' &&
            clientFormData.email === '' &&
            clientFormData.phone === '' &&
            clientFormData.companyName === ''
        ) {

            fillDetailsToast() //pop up to fill details

        } else {

            createClient(clientFormData)
            handleClose()
            clearForm()
        }
    }

    return (
        <div>
            <Button variant='outlined' onClick={handleOpen}>Add Client</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='absolute top-1/2 left-1/2 border-2 p-4 bg-white -translate-x-1/2 -translate-y-1/2 sm:w-1/2 w-full'>
                    <div className='flex justify-between'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Client Information
                        </Typography>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleClose}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    {/* form */}
                    <form autoComplete='off' onSubmit={handleSubmit}>
                        <TextField variant='outlined' label='First Name' fullWidth value={clientFormData.firstName} onChange={(e) => setClientFormData({ ...clientFormData, firstName: e.target.value })} />
                        <TextField variant='outlined' label='Last Name' fullWidth value={clientFormData.lastName} onChange={(e) => setClientFormData({ ...clientFormData, lastName: e.target.value })} />
                        <TextField variant='outlined' type='email' label='E-mail' fullWidth value={clientFormData.email} onChange={(e) => setClientFormData({ ...clientFormData, email: e.target.value })} />
                        <TextField variant='outlined' type='number' label='Phone' fullWidth value={clientFormData.phone} onChange={(e) => setClientFormData({ ...clientFormData, phone: e.target.value })} />
                        <TextField variant='outlined' label='Company Name' fullWidth value={clientFormData.companyName} onChange={(e) => setClientFormData({ ...clientFormData, companyName: e.target.value })} />


                        <div className='flex mt-5'>
                            <Button variant='contained' color='primary' size='large' type='submit' fullWidth>Submit</Button>

                            <Button variant='contained' color='secondary' size='small' onClick={clearForm} fullWidth>Clear</Button>

                        </div>


                    </form>

                </Box>
            </Modal>
        </div>
    );
}
