import React, { useEffect } from 'react'
import { useContextState } from '../../../ContextAPI'

const LiveNHL = () => {

    const { liveNHL, getLiveNHL, getNHLClosing, nhlClose } = useContextState()

    useEffect(() => {
        getLiveNHL()
        const interval = setInterval(() => {
            getLiveNHL()
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getNHLClosing()
    }, [])

    console.log(liveNHL)

    const gameRow = (time, period, away_score, home_score, away_team, home_team, away_shot, home_shot, away_fo, home_fo, away_pp, home_pp, away_ppg, home_ppg, away_pim, home_pim, away_blocks, home_blocks, away_hits, home_hits, away_takeaways, home_takeaways, away_giveaways, home_giveaways, away_open, away_close, home_open, home_close) => {
        return (
            <>
                <tr className='border-t border-r '>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{time}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_score}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_team}</td>
                    {/* <td className='text-center text-xs border-r md:px-0 px-6'>{away_open}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_close}</td> */}
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_shot}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_fo}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_pp}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_ppg}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_pim}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_blocks}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_hits}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_takeaways}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{away_giveaways}</td>
                </tr>
                <tr className='border-b border-r text-xs'>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{period}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_score}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_team}</td>
                    {/* <td className='text-center text-xs border-r md:px-0 px-6'>{home_open}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_close}</td> */}
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_shot}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_fo}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_pp}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_ppg}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_pim}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_blocks}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_hits}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_takeaways}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{home_giveaways}</td>
                </tr>
            </>
        )
    }

    return (
        <div className='mb-20 mt-14'>
            {/* <button onClick={() => getNHLClosing()}>nhl close</button> */}
            <p className='md:text-3xl text-xl font-bold text-center '>Current LIVE NHL games stats</p>
            <p className='text-sm text-center mb-4'>*Only returns data if there are live games going on</p>
            {/* <button onClick={() => getLiveNHL()} className='border-2 px-6 py-2 rounded-xl bg-blue-300 hover:bg-blue-500 text-white font-bold my-4 md:w-auto w-full'>Live NHL stats</button>
 */}
            <div className='overflow-x-auto'>
                <table className='border-2 w-full table-auto overflow-x-auto'>
                    <thead>
                        <tr>
                            <th className='text-xs'>Time</th>
                            <th className='text-xs'>Score</th>
                            <th className='text-xs'>Teams</th>
                            {/* <th className='text-xs'>Open</th>
                            <th className='text-xs'>Close</th> */}
                            <th className='text-xs'>Shots</th>
                            <th className='text-xs'>Faceoff %</th>
                            <th className='text-xs'>Power Plays</th>
                            <th className='text-xs'>PP Goals</th>
                            <th className='text-xs'>Penalty (mins)</th>
                            <th className='text-xs'>Blocked</th>
                            <th className='text-xs'>Hits</th>
                            <th className='text-xs'>Takeaways</th>
                            <th className='text-xs'>Giveaways</th>
                        </tr>
                    </thead>
                    <tbody>
                        {liveNHL?.data?.games?.map((g) => {
                            if (g?.status?.state === "LIVE") {
                                var away_team = g.teams.away.abbreviation
                                var home_team = g.teams.home.abbreviation
                                var away_shots
                                var home_shots
                                var away_score
                                var home_score
                                if (g?.status?.state === "LIVE"){
                                    var time = g?.status?.progress?.currentPeriodTimeRemaining?.pretty
                                } else if (g?.status?.state === "FINAL") {
                                    var time = 'Final'
                                }
                                
                                var period = g?.status?.progress?.currentPeriodOrdinal
                                var away_fo
                                var home_fo
                                var away_pp
                                var home_pp
                                var away_ppg
                                var home_ppg
                                var away_pim
                                var home_pim
                                var away_blocked
                                var home_blocked
                                var away_hits
                                var home_hits
                                var away_takeaways
                                var home_takeaways
                                var away_giveaways
                                var home_giveaways

                                //score
                                Object.entries(g?.scores)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_score = e[1]
                                    } else if (e[0] == home_team) {
                                        home_score = e[1]
                                    }
                                })

                                //blocked
                                Object.entries(g?.gameStats?.shots)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_shots = e[1]
                                    } else if (e[0] == home_team) {
                                        home_shots = e[1]
                                    }
                                })

                                //fo
                                Object.entries(g?.gameStats?.faceOffWinPercentage)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_fo = e[1]
                                    } else if (e[0] == home_team) {
                                        home_fo = e[1]
                                    }
                                })

                                //power plays
                                Object.entries(g?.gameStats?.powerPlay)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_pp = e[1].opportunities
                                    } else if (e[0] == home_team) {
                                        home_pp = e[1].opportunities
                                    }
                                })

                                //power plays goals
                                Object.entries(g?.gameStats?.powerPlay)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_ppg = e[1].goals
                                    } else if (e[0] == home_team) {
                                        home_ppg = e[1].goals
                                    }
                                })

                                //penalty
                                Object.entries(g?.gameStats?.pim)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_pim = e[1]
                                    } else if (e[0] == home_team) {
                                        home_pim = e[1]
                                    }
                                })

                                //blocks
                                Object.entries(g?.gameStats?.blocked)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_blocked = e[1]
                                    } else if (e[0] == home_team) {
                                        home_blocked = e[1]
                                    }
                                })

                                //hits
                                Object.entries(g?.gameStats?.hits)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_hits = e[1]
                                    } else if (e[0] == home_team) {
                                        home_hits = e[1]
                                    }
                                })

                                //takeaways
                                Object.entries(g?.gameStats?.takeaways)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_takeaways = e[1]
                                    } else if (e[0] == home_team) {
                                        home_takeaways = e[1]
                                    }
                                })

                                //giveaways
                                Object.entries(g?.gameStats?.giveaways)?.map((e) => {
                                    if (e[0] == away_team) {
                                        away_giveaways = e[1]
                                    } else if (e[0] == home_team) {
                                        home_giveaways = e[1]
                                    }
                                })

                                let roadData
                                let homeData
                                let roadOpen
                                let homeOpen
                                let roadClose
                                let homeClose

                                nhlClose?.data?.values.forEach((row) => {
                                    let road = row[3]
                                    let home = row[4]
                                    //let roadOpen = row[5]
                                    //let homeOpen = row[6]
                                    //let roadClose = row[15]
                                    //let homeClose = row[16]

                                    if (road === "Anaheim Ducks") {
                                        road = "ANA"
                                    } else if (road === "Arizona Coyotes") {
                                        road = "ARI"
                                    } else if (road === "Boston Bruins") {
                                        road = "BOS"
                                    } else if (road === "Buffalo Sabres") {
                                        road = "BUF"
                                    } else if (road === "Carolina Hurricanes") {
                                        road = "CAR"
                                    } else if (road === "Columbus Blue Jackets") {
                                        road = "CBJ"
                                    } else if (road === "Calgary Flames") {
                                        road = "CGY"
                                    } else if (road === "Chicago Blackhawks") {
                                        road = "CHI"
                                    } else if (road === "Colorado Avalanche") {
                                        road = "COL"
                                    } else if (road === "Dallas Stars") {
                                        road = "DAL"
                                    } else if (road === "Detroit Red Wings") {
                                        road = "DET"
                                    } else if (road === "Edmonton Oilers") {
                                        road = "EDM"
                                    } else if (road === "Florida Panthers") {
                                        road = "FLA"
                                    } else if (road === "Los Angeles Kings") {
                                        road = "LAK"
                                    } else if (road === "Minnesota Wild") {
                                        road = "MIN"
                                    } else if (road === "Montreal Canadiens") {
                                        road = "MTL"
                                    } else if (road === "New Jersey Devils") {
                                        road = "NJD"
                                    } else if (road === "Nashville Predators") {
                                        road = "NSH"
                                    } else if (road === "New York Islanders") {
                                        road = "NYI"
                                    } else if (road === "New York Rangers") {
                                        road = "NYR"
                                    } else if (road === "Ottawa Senators") {
                                        road = "OTT"
                                    } else if (road === "Philadelphia Flyers") {
                                        road = "PHI"
                                    } else if (road === "Pittsburgh Penguins") {
                                        road = "PIT"
                                    } else if (road === "Seattle Kraken") {
                                        road = "SEA"
                                    } else if (road === "San Jose Sharks") {
                                        road = "SJS"
                                    } else if (road === "St. Louis Blues") {
                                        road = "STL"
                                    } else if (road === "Tampa Bay Lightning") {
                                        road = "TBL"
                                    } else if (road === "Toronto Maple Leafs") {
                                        road = "TOR"
                                    } else if (road === "Vancouver Canucks") {
                                        road = "VAN"
                                    } else if (road === "Vegas Golden Knights") {
                                        road = "VGK"
                                    } else if (road === "Winnipeg Jets") {
                                        road = "WPG"
                                    } else if (road === "Washington Capitals") {
                                        road = "WSH"
                                    }



                                    if (home === "Anaheim Ducks") {
                                        home = "ANA"
                                    } else if (home === "Arizona Coyotes") {
                                        home = "ARI"
                                    } else if (home === "Boston Bruins") {
                                        home = "BOS"
                                    } else if (home === "Buffalo Sabres") {
                                        home = "BUF"
                                    } else if (home === "Carolina Hurricanes") {
                                        home = "CAR"
                                    } else if (home === "Columbus Blue Jackets") {
                                        home = "CBJ"
                                    } else if (home === "Calgary Flames") {
                                        home = "CGY"
                                    } else if (home === "Chicago Blackhawks") {
                                        home = "CHI"
                                    } else if (home === "Colorado Avalanche") {
                                        home = "COL"
                                    } else if (home === "Dallas Stars") {
                                        home = "DAL"
                                    } else if (home === "Detroit Red Wings") {
                                        home = "DET"
                                    } else if (home === "Edmonton Oilers") {
                                        home = "EDM"
                                    } else if (home === "Florida Panthers") {
                                        home = "FLA"
                                    } else if (home === "Los Angeles Kings") {
                                        home = "LAK"
                                    } else if (home === "Minnesota Wild") {
                                        home = "MIN"
                                    } else if (home === "Montreal Canadiens") {
                                        home = "MTL"
                                    } else if (home === "New Jersey Devils") {
                                        home = "NJD"
                                    } else if (home === "Nashville Predators") {
                                        home = "NSH"
                                    } else if (home === "New York Islanders") {
                                        home = "NYI"
                                    } else if (home === "New York Rangers") {
                                        home = "NYR"
                                    } else if (home === "Ottawa Senators") {
                                        home = "OTT"
                                    } else if (home === "Philadelphia Flyers") {
                                        home = "PHI"
                                    } else if (home === "Pittsburgh Penguins") {
                                        home = "PIT"
                                    } else if (home === "Seattle Kraken") {
                                        home = "SEA"
                                    } else if (home === "San Jose Sharks") {
                                        home = "SJS"
                                    } else if (home === "St. Louis Blues") {
                                        home = "STL"
                                    } else if (home === "Tampa Bay Lightning") {
                                        home = "TBL"
                                    } else if (home === "Toronto Maple Leafs") {
                                        home = "TOR"
                                    } else if (home === "Vancouver Canucks") {
                                        home = "VAN"
                                    } else if (home === "Vegas Golden Knights") {
                                        home = "VGK"
                                    } else if (home === "Winnipeg Jets") {
                                        home = "WPG"
                                    } else if (home === "Washington Capitals") {
                                        home = "WSH"
                                    }

                                    //set data for table
                                    if (road === away_team) {
                                        roadData = road
                                        roadOpen = row[5]
                                        roadClose = row[15]
                                    }

                                    if (home === home_team) {
                                        homeData = home
                                        homeOpen = row[6]
                                        homeClose = row[16]
                                    }

                                })

                                return gameRow(
                                    time,
                                    period,
                                    away_score,
                                    home_score,
                                    away_team,
                                    home_team,
                                    away_shots,
                                    home_shots,
                                    away_fo,
                                    home_fo,
                                    away_pp,
                                    home_pp,
                                    away_ppg,
                                    home_ppg,
                                    away_pim,
                                    home_pim,
                                    away_blocked,
                                    home_blocked,
                                    away_hits,
                                    home_hits,
                                    away_takeaways,
                                    home_takeaways,
                                    away_giveaways,
                                    home_giveaways,
                                    roadOpen,
                                    roadClose,
                                    homeOpen,
                                    homeClose
                                )
                            }
                        })}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default LiveNHL