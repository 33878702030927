import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useContextState } from '../../../ContextAPI';


ChartJS.register(ArcElement, Tooltip, Legend);



export function MarvinPieChart() {

    const { marvinData } = useContextState()

    let existingClient = 0
    let newClient = 0

    marvinData?.rows?.map((r) => {
        if (r.c[4].v === 'Existing') {
            existingClient += 1
        }
        if (r.c[4].v === 'New') {
            newClient += 1
        }
        return
    })

    const clientData = ([existingClient, newClient])

    const data = {
        labels: ['Existing Customers', 'New Customers'],
        datasets: [
            {
                label: 'Total Sales',
                data: clientData,
                backgroundColor: [
                    'rgba(0, 153, 255, 1)',
                    'rgba(255, 128, 0, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderColor: [
                    'rgba(0, 153, 255, 1)',
                    'rgba(255, 128, 0, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 3,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "white",
                /* formatter: function (value, context) {
                    return context.chart.data.labels[context.dataIndex];
                } */
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Customer Category',
            },
        },
    };
    return <Pie data={data} options={options} plugins={[ChartDataLabels]} />;
}
