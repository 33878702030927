import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContextState } from '../../ContextAPI';

export default function DashboardClientSelector() {
    const [age, setAge] = React.useState('all');

    const { clientData, setClientIndex } = useContextState()

    const handleChange = (event) => {
        setAge(event.target.value)
        setClientIndex(event.target.value)
        //console.log(event.target.value)
    };


    React.useEffect(() => {
        setClientIndex('all')
    }, [])

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                    defaultValue='all'
                >
                    <MenuItem value={'all'}>All Clients</MenuItem>
                    {clientData.map((client, index) => (
                        <MenuItem key={index} value={client._id}>{client.firstName} {client.lastName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box >
    );
}
