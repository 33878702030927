import React from 'react'
import { useContextState } from '../ContextAPI'
import { BsCheck } from 'react-icons/bs';

const Settings = () => {
  const { setColor, color } = useContextState()

  return (
    <div className='m-2 p-2 md:p-10 bg-white rounded-3xl'>
      <p className='text-xl mb-5'>Select A Theme Color</p>
      <div className='relative mt-2 cursor-pointer flex gap-5 items-center'>

        <button type='button' className='h-10 w-10 rounded-full cursor-pointer bg-indigo-500' onClick={() => {
          setColor('#6366F1')
          localStorage.setItem('color', '#6366F1')
        }}>
          <BsCheck className={`ml-2 text-2xl text-white ${color === '#6366F1' ? 'block' : 'hidden'}`} />
        </button>

        <button type='button' className='h-10 w-10 rounded-full cursor-pointer bg-blue-500' onClick={() => {
          setColor('#3B82F6')
          localStorage.setItem('color', '#3B82F6')
        }}>
          <BsCheck className={`ml-2 text-2xl text-white ${color === '#3B82F6' ? 'block' : 'hidden'}`} />
        </button>

        <button type='button' className='h-10 w-10 rounded-full cursor-pointer bg-emerald-500' onClick={() => {
          setColor('#10B981')
          localStorage.setItem('color', '#10B981')
        }}>
          <BsCheck className={`ml-2 text-2xl text-white ${color === '#10B981' ? 'block' : 'hidden'}`} />
        </button>

        <button type='button' className='h-10 w-10 rounded-full cursor-pointer bg-red-400' onClick={() => {
          setColor('#F87171')
          localStorage.setItem('color', '#F87171')
        }}>
          <BsCheck className={`ml-2 text-2xl text-white ${color === '#F87171' ? 'block' : 'hidden'}`} />
        </button>

        <button type='button' className='h-10 w-10 rounded-full cursor-pointer bg-stone-500' onClick={() => {
          setColor('#78716C')
          localStorage.setItem('color', '#78716C')
        }}>
          <BsCheck className={`ml-2 text-2xl text-white ${color === '#78716C' ? 'block' : 'hidden'}`} />
        </button>

      </div>
    </div>



  )
}

export default Settings