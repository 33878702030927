import React from 'react'
import { PieChart } from '../components/charts/PieChart'
import { BarAgeChart } from './charts/BarAgeChart'
import { BarHoriAudChart } from './charts/BarHoriAudChart'

const AudienceSection = () => {
    return (
        <section className="flex-row text-gray-600 body-font mb-10 w-full">
            <div className="container flex flex-wrap">
                <div className='flex items-center justify-center w-full'>
                    <p className='font-bold text-2xl my-5'>Audience Data: <span className='text-sm font-normal'>*data in this section are randomly generated</span></p>
                </div>

                <div className='flex flex-wrap w-full'>

                    {/* piechart */}
                    <div className="p-4 xl:w-1/2 w-full grid">
                        <div className="border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-0 ">
                            <div className="flex text-xl text-gray-600 md:mb-5 mb-0 md:justify-start justify-center mt-5">
                                Lead Source
                            </div>
                            {/* content */}
                            <div className="bg-white w-full h-96 flex justify-center">
                                <div className="flex items-center justify-center md:p-4 p-2">
                                    <PieChart />
                                </div>
                            </div>
                            {/* content */}

                        </div>
                    </div>
                    {/* piechart */}



                    {/* vertical bar */}
                    <div className="p-4 xl:w-1/2 md:w-full w-full">
                        <div className="flex border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                            {/* content */}
                            <div className="bg-white w-full">
                                <div className="flex text-xl text-gray-600  mb-5 md:justify-start justify-center mt-5">
                                    Age and Gender by %
                                </div>
                                <div className="relative flex items-center pt-1 h-96">
                                    <BarAgeChart />
                                </div>
                            </div>

                            {/* content */}
                        </div>
                    </div>
                    {/* vertical bar */}

                    {/* vertical bar */}
                    <div className="p-4 lg:w-full md:w-full w-full">
                        <div className="flex border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                            {/* content */}
                            <div className="bg-white w-full">
                                <div className="flex text-xl text-gray-600 mb-5 md:justify-start justify-center mt-5">
                                    Top Cities
                                </div>
                                <div className="relative flex items-center pt-1 h-96">
                                    <BarHoriAudChart />
                                </div>
                            </div>

                            {/* content */}
                        </div>
                    </div>
                    {/* vertical bar */}
                </div>
            </div>


        </section >
    )
}

export default AudienceSection