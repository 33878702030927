import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContextState } from '../../../ContextAPI';

export default function ArielSelectorClient() {
    const [age, setAge] = React.useState('');

    const { setArielClient } = useContextState()

    const handleChange = (event) => {
        setAge(event.target.value);
        setArielClient(event.target.value)            
    };
    

    return (
        <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={process.env.REACT_APP_FB_ACCOUNT1}>Don Johnson</MenuItem>
                    <MenuItem value={process.env.REACT_APP_FB_ACCOUNT2}>Local Partner Network (Campaign: Dusten)</MenuItem>
                    <MenuItem value={process.env.REACT_APP_FB_ACCOUNT3}>Kasey</MenuItem>
                    <MenuItem value={process.env.REACT_APP_FB_ACCOUNT4}>Eli</MenuItem>

                </Select>
            </FormControl>
        </Box >
    );
}
