import React, { useEffect, useState } from 'react'
import moment from 'moment'
import betonline from '../../../assets/betonline.png'
import lowvig from '../../../assets/lowvig.jpg'
import fanduel from '../../../assets/fanduel.png'
import draftkings from '../../../assets/draftkings.png'
import circa from '../../../assets/circa.png'
import matchbook from '../../../assets/matchbook.png'
import pinnacle from '../../../assets/pinnacle.jpeg'
import betfair from '../../../assets/betfair.jpg'
import betmgm from '../../../assets/betmgm.png'
import mybookie from '../../../assets/mybookie.png'
import { useContextState } from '../../../ContextAPI'

const SportsOdds = () => {

    const { oddsNFL, getOddsNFL, lastRun, getOddsNHL, oddsNHL, oddsNBA, getOddsNBA, getOddsNFL_GC, getOddsNHL_GC, getOddsNBA_GC } = useContextState()


    useEffect(() => {
        getOddsNFL_GC()
        getOddsNHL_GC()
        getOddsNBA_GC()

        const interval = setInterval(() => {
            getOddsNFL()
            getOddsNHL()
            getOddsNBA()
        }, 1000 * 60 * 60);

        return () => clearInterval(interval);
    }, []);

    /* dates */
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const tomorrow = new Date(new Date().setDate(today.getDate() + 2))
    tomorrow.setHours(0, 0, 0, 0)
    const nextWeek = new Date(new Date().setDate(today.getDate() + 7))

    const [showNFL, setShowNFL] = useState(false)
    const [showNHL, setShowNHL] = useState(false)
    const [showNBA, setShowNBA] = useState(true)

    const showNFLTable = () => {
        setShowNFL(true)
        setShowNHL(false)
        setShowNBA(false)
    }

    const showNHLTable = () => {
        setShowNFL(false)
        setShowNHL(true)
        setShowNBA(false)
    }

    const showNBATable = () => {
        setShowNFL(false)
        setShowNHL(false)
        setShowNBA(true)
    }

    const gameRow = (time, away, home, bol1, bol2, lv1, lv2, fd1, fd2, dk1, dk2, cr1, cr2) => {
        return (
            <>
                <tr className='border-t border-r '>
                    <td rowSpan="2" className='border text-xs break-words'>
                        {time}
                    </td>
                    <td className='border-r text-xs break-words'>{away}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{bol1}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{lv1}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{fd1}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{dk1}</td>
                    <td className='text-center text-xs md:px-0 px-6'>{cr1}</td>
                </tr>
                <tr className='border-b border-r text-xs'>
                    <td className='border-r text-xs break-words'>{home}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{bol2}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{lv2}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{fd2}</td>
                    <td className='text-center text-xs border-r md:px-0 px-6'>{dk2}</td>
                    <td className='text-center text-xs md:px-0 px-6'>{cr2}</td>
                </tr>
            </>
        )
    }


    return (
        <div>
            {/* odds table */}
            <div className='mt-14'>
                <p className='font-bold text-center md:text-4xl text-xl mb-5'>Current Market Odds</p>
                <div className='md:block flex'>
                    <button className={`${(showNFL) ? 'bg-blue-500' : 'text-blue-500'} border-2 px-6 rounded text-white font-bold md:w-fit w-full`} onClick={() => showNFLTable()}>NFL</button>
                    <button className={`${(showNBA) ? 'bg-blue-500' : 'text-blue-500'} border-2 px-6 rounded text-white font-bold md:w-fit w-full`} onClick={() => showNBATable()}>NBA</button>
                    <button className={`${(showNHL) ? 'bg-blue-500' : 'text-blue-500'} border-2 px-6 rounded text-white font-bold md:w-fit w-full`} onClick={() => showNHLTable()}>NHL</button>
                </div>

                {/* nfl table */}
                {showNFL &&
                    <div className='overflow-x-auto'>
                        {/* <button onClick={() => getOddsNFL()}>get odds</button>
          <button onClick={() => getOddsNHL()}>get odds NHL</button> */}
                        {/* <p className='text-xs py-1'>last updated at: {lastRun?.toLocaleString("en-US", { timeZone: "EST" })}</p> */}
                        <table className='border-2 w-full table-auto overflow-x-auto'>
                            <thead className='bg-slate-200'>
                                <tr>
                                    <th className='text-xs'>Start Time</th>
                                    <th className='text-xs'>Teams</th>
                                    <th><img src={betonline} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={lowvig} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={fanduel} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={draftkings} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={circa} alt='' className='h-6 w-20 mx-auto' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {oddsNFL?.data?.[0]?.map((game) => {
                                    var bol1 = ''
                                    var bol2 = ''
                                    var lv1 = ''
                                    var lv2 = ''
                                    var fd1 = ''
                                    var fd2 = ''
                                    var dk1 = ''
                                    var dk2 = ''
                                    var cr1 = ''
                                    var cr2 = ''
                                    var date = moment(game?.commence_time).format('MM/DD/YYYY:hh:mm')
                                    //var gameday = new Date().setDate(game?.commence_time)
                                    //console.log(game?.commence_time)
                                    //console.log(nextWeek.toJSON())
                                    if (game?.commence_time < nextWeek.toJSON()) {

                                        game?.bookmakers.map((book) => {
                                            if (book.key === "betonlineag") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    bol1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    bol2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    bol1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    bol2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "lowvig") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    lv1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    lv2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    lv1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    lv2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "fanduel") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    fd1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    fd2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    fd1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    fd2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "draftkings") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    dk1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    dk2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    dk1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    dk2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "circasports") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    cr1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    cr2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    cr1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    cr2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                        })

                                        return gameRow(date, game?.away_team, game?.home_team, bol1, bol2, lv1, lv2, fd1, fd2, dk1, dk2, cr1, cr2)
                                    }

                                })}
                            </tbody>
                        </table>
                    </div>}
                {/* nfl table */}


                {/* nhl table */}
                {showNHL &&
                    <div className='overflow-x-auto'>
                        {/* <button onClick={() => getOddsNHL()}>get odds NHL</button> */}
                        {/* <p className='text-xs py-1'>last updated at: {lastRun?.toLocaleString("en-US", { timeZone: "EST" })}</p> */}
                        <table className='border-2 w-full table-auto'>
                            <thead className='bg-slate-200'>
                                <tr>
                                    <th className='text-xs'>Start Time</th>
                                    <th className='text-xs'>Teams</th>
                                    <th><img src={betonline} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={pinnacle} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={matchbook} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={betfair} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th><img src={mybookie} alt='' className='h-6 w-20 mx-auto' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {oddsNHL?.data?.[0]?.map((game) => {
                                    var bol1 = ''
                                    var bol2 = ''
                                    var lv1 = ''
                                    var lv2 = ''
                                    var fd1 = ''
                                    var fd2 = ''
                                    var dk1 = ''
                                    var dk2 = ''
                                    var cr1 = ''
                                    var cr2 = ''
                                    var date = moment(game?.commence_time).format('MM/DD/YYYY:hh:mm')
                                    //var gameday = new Date().setDate(game?.commence_time)
                                    console.log(game)
                                    //console.log(nextWeek.toJSON())
                                    if (game?.commence_time < tomorrow.toJSON()) {

                                        game.bookmakers.map((book) => {
                                            if (book.key === "betonlineag") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    bol1 = book.markets[0].outcomes[0].price
                                                    bol2 = book.markets[0].outcomes[1].price
                                                } else {
                                                    bol1 = book.markets[0].outcomes[1].price
                                                    bol2 = book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "pinnacle") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    lv1 = book.markets[0].outcomes[0].price
                                                    lv2 = book.markets[0].outcomes[1].price
                                                } else {
                                                    lv1 = book.markets[0].outcomes[1].price
                                                    lv2 = book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "matchbook") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    fd1 = book.markets[0].outcomes[0].price
                                                    fd2 = book.markets[0].outcomes[1].price
                                                } else {
                                                    fd1 = book.markets[0].outcomes[1].price
                                                    fd2 = book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "betfair") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    dk1 = book.markets[0].outcomes[0].price
                                                    dk2 = book.markets[0].outcomes[1].price
                                                } else {
                                                    dk1 = book.markets[0].outcomes[1].price
                                                    dk2 = book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "mybookieag") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    cr1 = book.markets[0].outcomes[0].price
                                                    cr2 = book.markets[0].outcomes[1].price
                                                } else {
                                                    cr1 = book.markets[0].outcomes[1].price
                                                    cr2 = book.markets[0].outcomes[0].price
                                                }
                                            }
                                        })

                                        return gameRow(date, game?.away_team, game?.home_team, bol1, bol2, lv1, lv2, fd1, fd2, dk1, dk2, cr1, cr2)
                                    }

                                })}
                            </tbody>
                        </table>
                    </div>}
                {/* nhl table */}

                {/* nba table */}
                {showNBA &&
                    <div className='overflow-x-auto'>
                        {/* <button onClick={() => getOddsNBA()}>get odds NHL</button> */}
                        {/* <p className='text-xs py-1'>last updated at: {lastRun?.toLocaleString("en-US", { timeZone: "EST" })}</p> */}
                        <table className='border-2 w-full table-auto'>
                            <thead className='bg-slate-200'>
                                <tr>
                                    <th className='text-xs'>Start Time</th>
                                    <th className='text-xs'>Teams</th>
                                    <th><img src={betonline} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th ><img src={lowvig} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th ><img src={fanduel} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th ><img src={draftkings} alt='' className='h-6 w-20 mx-auto' /></th>
                                    <th ><img src={betmgm} alt='' className='h-6 w-20 mx-auto' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {oddsNBA?.data?.[0]?.map((game) => {
                                    var bol1 = ''
                                    var bol2 = ''
                                    var lv1 = ''
                                    var lv2 = ''
                                    var fd1 = ''
                                    var fd2 = ''
                                    var dk1 = ''
                                    var dk2 = ''
                                    var cr1 = ''
                                    var cr2 = ''
                                    var date = moment(game?.commence_time).format('MM/DD/YYYY:hh:mm')
                                    //var gameday = new Date().setDate(game?.commence_time)
                                    //console.log(game?.commence_time)
                                    //console.log(nextWeek.toJSON())
                                    if (game?.commence_time < tomorrow.toJSON()) {

                                        game.bookmakers.map((book) => {
                                            if (book.key === "betonlineag") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    bol1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    bol2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    bol1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    bol2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "lowvig") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    lv1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    lv2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    lv1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    lv2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "fanduel") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    fd1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    fd2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    fd1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    fd2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "draftkings") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    dk1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    dk2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    dk1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    dk2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                            if (book.key === "betmgm") {
                                                if (book.markets[0].outcomes[0].name === game?.away_team) {
                                                    cr1 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                    cr2 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                } else {
                                                    cr1 = book.markets[0].outcomes[1].point + " " + book.markets[0].outcomes[1].price
                                                    cr2 = book.markets[0].outcomes[0].point + " " + book.markets[0].outcomes[0].price
                                                }
                                            }
                                        })

                                        return gameRow(date, game?.away_team, game?.home_team, bol1, bol2, lv1, lv2, fd1, fd2, dk1, dk2, cr1, cr2)
                                    }

                                })}
                            </tbody>
                        </table>
                    </div>}
            </div>
            {/* nba table */}
            {/* end odds table */}
        </div>
    )
}

export default SportsOdds