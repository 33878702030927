import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebase';


const ContextState = createContext()

const ContextAPI = ({ children }) => {

    const [activeMenu, setActiveMenu] = useState(true)
    const [screenSize, setScreenSize] = useState(undefined)
    const [showClientModal, setShowClientModal] = useState(false)

    const [clientData, setClientData] = useState([])
    const [masterData, setMasterData] = useState([])
    const [selectedID, setSelectedID] = useState(null)
    const [campaignID, setCampaignID] = useState(null)
    const [clientCampaignID, setClientCampaignID] = useState('all')
    const [monthID, setMonthID] = useState(null)
    const [campaignNameAndParent, setCampaignNameAndParent] = useState({
        campaignName: '',
        parentID: null,
    })
    const [showCampaigns, setShowCampaigns] = useState(true)
    const [showMonths, setShowMonths] = useState(false)
    const [clientIndex, setClientIndex] = useState('all')
    const [campaignIndex, setCampaignIndex] = useState('all')


    const lastColor = localStorage.getItem('color') || '#6366F1'

    const [color, setColor] = useState(lastColor)

    //toasts
    const addToast = () => toast.success('New Client Added');
    const deleteToast = () => toast.error('Client Removed');
    const fillDetailsToast = () => toast.warning('Please Fill In Client Information')
    const selectOneToast = () => toast.warning('Please Select Only 1 Client To Edit')
    const editToast = () => toast.success('Client Details Edited')
    const addCampaignToast = () => toast.success('New Campaign Added')
    const deleteCampaignToast = () => toast.error('Campaign Deleted')
    const selectOneCampaignToast = () => toast.warning('Select Only 1 Campaign')
    const loginSuccessToast = () => toast.success('Logged In Succesfully')
    const loginErrorToast = () => toast.error('Incorrect Username or Password')
    const loginFillToast = () => toast.warning('Please Fill In All The Fields')
    const logoutSuccessToast = () => toast.success('Logged Out Successfully')
    const contactGCToast = () => toast.warning('Please Contact Gilbert Directly')
    const arielClientToast = () => toast.warning('Please Select A Client')
    const arielDateToast = () => toast.warning('Please Select Both Start and End Date')
    const sendToSlackToast = () => toast.success('Report Sent To Slack!')


    //const clientURL = 'http://localhost:5000/client'
    const clientURL = 'https://gchome-backend.herokuapp.com/client'

    const getClient = () => {
        axios.get(clientURL)
            .then((response) => {
                //console.log(response.data)
                setClientData(response.data)
            })
            .catch((error) => console.log(error))
    }

    const createClient = (newClient) => {
        axios.post(`${clientURL}/add`, newClient)
            .then((response) => {
                //console.log(response.data)
                getClient() //call this so clientdata updates when new data added
                addToast()
            })
            .catch((error) => console.log(error))
    }

    const deleteClient = (id) => {
        axios.delete(`${clientURL}/delete/${id}`)
            .then((response) => {
                //console.log(response.data)
                getClient() //call this so clientdata updates when new data added
                deleteToast()
            })
            .catch((error) => console.log(error))
    }

    const editClient = (id, update) => {
        axios.patch(`${clientURL}/edit/${id}`, update)
            .then((response) => {
                //console.log(response.data)
                getClient()
                editToast()
            })
            .catch((error) => console.log(error))
    }


    const getMasterData = () => {
        axios.get(`${clientURL}/campaign`)
            .then((response) => {
                //console.log(response.data)
                setMasterData(response.data)
            })
            .catch((error) => console.log(error))
    }


    const addCampaign = (newCampaign) => {
        axios.post(`${clientURL}/campaign/add`, newCampaign)
            .then((response) => {
                //console.log(response.data)
                getMasterData() //call this so clientdata updates when new data added
                addCampaignToast()
            })
            .catch((error) => console.log(error))
    }

    const deleteCampaign = (id) => {
        axios.delete(`${clientURL}/campaign/delete/${id}`)
            .then((response) => {
                //console.log(response.data)
                getMasterData() //call this so clientdata updates when new data added
                deleteCampaignToast()
            })
            .catch((error) => console.log(error))
    }


    const editMonthData = (id, update) => {
        axios.patch(`${clientURL}/campaign/edit/${id}`, update)
            .then((response) => {
                //console.log(response.data)
                getMasterData()
                editToast()
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getClient()
        getMasterData()
    }, [])


    //chart data
    /* CLICKS */
    const clicks = masterData.map((campaign) => (
        Object.entries(campaign.data).map((e) => {
            return (e[1].clicks)
        }).filter(f => f !== undefined)
    ))

    const clicksByClient = masterData.map((campaign) => {
        if (campaign.parentId === clientIndex) {
            return Object.entries(campaign.data).map((e) => {
                return (e[1].clicks)
            })?.filter(f => f !== undefined)
        }
    })?.filter(f => f !== undefined)


    const totalClicks = clicks[0]?.map((x, idx) => clicks.reduce((sum, curr) => sum + curr[idx], 0));
    const totalClicksByClient = clicksByClient[0]?.map((x, idx) => clicksByClient.reduce((sum, curr) => sum + curr[idx], 0));
    const totalClicksAsOne = totalClicks?.reduce((a, b) => a + b, 0)
    const totalClicksAsClient = totalClicksByClient?.reduce((a, b) => a + b, 0)


    /* IMPRESSIONS */
    const impressions = masterData.map((campaign) => (
        Object.entries(campaign.data).map((e) => {
            return (e[1].impressions)
        }).filter(f => f !== undefined)
    ))

    const impressionsByClient = masterData.map((campaign) => {
        if (campaign.parentId === clientIndex) {
            return Object.entries(campaign.data).map((e) => {
                return (e[1].impressions)
            })?.filter(f => f !== undefined)
        }
    })?.filter(f => f !== undefined)



    const totalImpressions = impressions[0]?.map((x, idx) => impressions.reduce((sum, curr) => sum + curr[idx], 0));
    const totalImpressionsByClient = impressionsByClient[0]?.map((x, idx) => impressionsByClient.reduce((sum, curr) => sum + curr[idx], 0));
    const totalImpressionsAsOne = totalImpressions?.reduce((a, b) => a + b, 0)
    const totalImpressionsAsClient = totalImpressionsByClient?.reduce((a, b) => a + b, 0)



    /* SPEND */
    const spend = masterData.map((campaign) => (
        Object.entries(campaign.data).map((e) => {
            return (e[1].spend)
        }).filter(f => f !== undefined)
    ))

    const spendByClient = masterData.map((campaign) => {
        if (campaign.parentId === clientIndex) {
            return Object.entries(campaign.data).map((e) => {
                return (e[1].spend)
            })?.filter(f => f !== undefined)
        }
    })?.filter(f => f !== undefined)

    const totalSpend = spend[0]?.map((x, idx) => spend.reduce((sum, curr) => sum + curr[idx], 0));
    const totalSpendByClient = spendByClient[0]?.map((x, idx) => spendByClient.reduce((sum, curr) => sum + curr[idx], 0));
    const totalSpendAsOne = totalSpend?.reduce((a, b) => a + b, 0)
    const totalSpendAsClient = totalSpendByClient?.reduce((a, b) => a + b, 0)


    const campaignCount = clientIndex === 'all' ? clicks.length : clicksByClient.length

    const singleClicks = clicks[campaignIndex]
    const totalSingleClicks = singleClicks?.reduce((a, b) => a + b, 0)

    const singleImpressions = impressions[campaignIndex]
    const totalSingleImpressions = singleImpressions?.reduce((a, b) => a + b, 0)

    const singleSpend = spend[campaignIndex]
    const totalSingleSpend = singleSpend?.reduce((a, b) => a + b, 0)



    //AUTHENTICATION
    const [user, setUser] = useState(null)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        })
    }, [])


    /* PINNY */
    const [pinnyFixtures, setPinnyFixtures] = useState()
    const [pinnyOdds, setPinnyOdds] = useState()
    const [pinnySport, setPinnySport] = useState(3)
    const [pinnyFixtureSince, setPinnyFixtureSince] = useState(0)
    const [pinnyOddsSince, setPinnyOddsSince] = useState(0)

    const getPinnyFixture = (pinnySport) => {
        axios.get('https://www.gcbackend.ml/ps3838/fixtures', { params: { sportId: pinnySport, last: pinnyFixtureSince } })
            .then((response) => {
                //console.log(response)
                setPinnyFixtureSince(response.data.last)
                setPinnyFixtures(response.data)
            })
            .catch((error) => console.log(error))
    }

    const getPinnyOdds = (pinnySport) => {
        axios.get('https://www.gcbackend.ml/ps3838/odds', { params: { sportId: pinnySport, last: pinnyOddsSince } })
            .then((response) => {
                //console.log(response.data.last)
                setPinnyOddsSince(response.data.last)
                setPinnyOdds(response.data)
            })
            .catch((error) => console.log(error))
    }

    //Google sheets marvin
    const [marvinData, setMarvinData] = useState([])
    const [query, setQuery] = useState('')

    const googleURL = process.env.REACT_APP_GOOGLE_URL
    const getMarvin = (query) => {
        axios.get(`${googleURL}${encodeURIComponent(query)}`)
            .then((response) => {
                const data = JSON.parse(response.data.substr(47).slice(0, -2))
                setMarvinData(data.table)
            })
            .catch((error) => console.log(error))
    }

    //ARIEL
    const [arielData, setArielData] = useState([])
    const [arielFBDates, setArielFBDates] = useState({
        startDate: null,
        endDate: null,
    })
    const [arielStartEnd, setArielStartEnd] = useState({
        startDate: null,
        endDate: null,
    })
    const [datePreset, setDatePreset] = useState('last_7d')
    const [arielLevel, setArielLevel] = useState('adset')
    const [arielClient, setArielClient] = useState('')
    const [arielClientGoogle, setArielClientGoogle] = useState('')
    const [arielGoogleData, setArielGoogleData] = useState()
    const [arielGoogleLeadCount, setArielGoogleLeadCount] = useState(0)

    const arielAllClients = [process.env.REACT_APP_FB_ACCOUNT1, process.env.REACT_APP_FB_ACCOUNT2, process.env.REACT_APP_FB_ACCOUNT3, process.env.REACT_APP_FB_ACCOUNT4]
    const arielAllGoogleSheets = [process.env.REACT_APP_GOOGLESHEET1, process.env.REACT_APP_GOOGLESHEET2, process.env.REACT_APP_GOOGLESHEET3, process.env.REACT_APP_GOOGLESHEET4]
    const [arielFBAllData, setArielFBAllData] = useState([])
    const [arielGSAllData, setArielGSAllData] = useState([])


    const getArielGeneral = async (clientID, preset) => {
        return await axios.get('https://gchome-backend.herokuapp.com/ariel', { params: { client: clientID, datePreset: preset, level: 'campaign' } })
            .then((response) => {
                /*                 setArielOverviewStartEnd({
                                    startDate: new Date((response.data?.data?.[0]?.date_start).replace(/-/g, '\/')),
                                    endDate: new Date((response.data?.data?.[0]?.date_stop).replace(/-/g, '\/'))
                                }) */
                //console.log(response.data.data)
                const data = response?.data.data
                for (var i = 0; i < data?.length; i++) {
                    if (data?.length > 0) {
                        data[i]["clientID"] = clientID
                    }
                }
                return data
            })
            .catch((error) => console.log(error))

    }

    const getArielAll = (preset) => {
        //get fb stats
        setArielFBAllData([])
        Promise.all(arielAllClients?.forEach(async (client) => {
            const data = await getArielGeneral(client, preset)
            //data.push(await client)
            setArielFBAllData((prevState) => [...prevState, data])
        }))

        //get leads from google sheets
        setArielGSAllData([])
        arielAllGoogleSheets.forEach((url) => {
            axios.get(url)
                .then((response) => {
                    const data = JSON.parse(response.data.substr(47).slice(0, -2))
                    data.table['url'] = url
                    setArielGSAllData((prevState) => [...prevState, data.table])
                })
                .catch((error) => console.log(error))
        })
    }



    const getAriel = () => {
        axios.get('https://gchome-backend.herokuapp.com/ariel', { params: { client: arielClient, datePreset: datePreset, level: arielLevel } })
            .then((response) => {
                setArielData(response.data)
                //console.log(response.data)
                setArielStartEnd({
                    startDate: new Date((response.data?.data?.[0].date_start).replace(/-/g, '\/')),
                    endDate: new Date((response.data?.data?.[0].date_stop).replace(/-/g, '\/'))
                })
            })
            .catch((error) => console.log(error))
    }


    const getArielCustom = () => {
        axios.get('https://gchome-backend.herokuapp.com/arielcustom', { params: { client: arielClient, startDate: arielFBDates.startDate, endDate: arielFBDates.endDate, level: arielLevel } })
            .then((response) => {
                setArielData(response.data)
                setArielStartEnd({
                    startDate: new Date((response.data?.data[0].date_start).replace(/-/g, '\/')),
                    endDate: new Date((response.data?.data[0].date_stop).replace(/-/g, '\/'))
                })
            })
            .catch((error) => console.log(error))
    }

    /* keeping this in front */
    const getArielGoogle = (url) => {
        axios.get(url)
            .then((response) => {
                const data = JSON.parse(response.data.substr(47).slice(0, -2))
                setArielGoogleData(data.table)
            })
            .catch((error) => console.log(error))
    }

    const getArielGoogleBackEnd = (url) => {
        axios.get('http://localhost:5000/arielgoogle', { params: { url: url } })
            .then((response) => setArielGoogleData(response.data.table))
            .catch((error) => console.log(error))
    }




    /* SLACK */
    const [slackData, setSlackData] = useState({
        name: '',
        spend: 0,
        reach: 0,
        clicks: 0,
        leads: 0,
        cpl: 0,
        cpm: 0,
        startDate: null,
        endDate: null
    })


    const sendToSlack = async () => {
        const webHookURL = process.env.REACT_APP_SLACK_WEBHOOK

        const data = {
            "text": `Client: Felton Denistry \n From: ${slackData.startDate} To: ${slackData.endDate} \n Total Spend: $${slackData.spend} \n Total Reach: ${slackData.reach} \n Total Clicks: ${slackData.clicks} \n Total Leads: ${slackData.leads} \n CPL: $${slackData.cpl} \n CPM: $${slackData.cpm}`
        }

        let res = await axios.post(webHookURL, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        }).then(() => sendToSlackToast())
    }


    //Rory
    const [NHLData, setNHLData] = useState([])
    const [homeTeam, setHomeTeam] = useState("Anaheim Ducks")
    const [awayTeam, setAwayTeam] = useState("Anaheim Ducks")
    const [NHLTeam, setNHLTeam] = useState("")

    const getUltimateDB = async () => {
        axios.get('https://gchome-backend.herokuapp.com/google')
            .then((response) => {
                //console.log(response.data)
                setNHLData(response.data)
            })
            .catch((error) => console.log(error))
    }


    //sports portfolio
    const [oddsNFL, setOddsNFL] = useState([])
    const [oddsNHL, setOddsNHL] = useState([])
    const [oddsNBA, setOddsNBA] = useState([])
    const [lastRun, setLastRun] = useState()



    const getOddsNFL_GC = async () => {
        axios.get('https://gchome-backend.herokuapp.com/odds_nfl')
            .then((response) => {
                setOddsNFL(response)
            })
            .catch((error) => console.log(error))
    }
    const getOddsNHL_GC = async () => {
        axios.get('https://gchome-backend.herokuapp.com/odds_nhl')
            .then((response) => {
                setOddsNHL(response)
            })
            .catch((error) => console.log(error))
    }
    const getOddsNBA_GC = async () => {
        axios.get('https://gchome-backend.herokuapp.com/odds_nba')
            .then((response) => {
                setOddsNBA(response)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getUltimateDB()
    }, [])


    //liveNHL api
    const [liveNHL, setLiveNHL] = useState([])
    const getLiveNHL = async () => {
        axios.get('https://nhl-score-api.herokuapp.com/api/scores/latest')
            .then((response) => {
                setLiveNHL(response)
                //console.log(response)
            })
            .catch((error) => console.log(error))
    }

    const [nhlClose, setNhlClose] = useState([])

    const getNHLClosing = () => {
        axios.get('https://gchome-backend.herokuapp.com/nhlclose')
            .then((response) => {
                console.log(response.data)
                setNhlClose(response.data)
            })
            .catch((error) => console.log(error))
    }

    return (
        <ContextState.Provider value={{
            activeMenu,
            setActiveMenu,
            screenSize,
            setScreenSize,
            showClientModal,
            setShowClientModal,
            createClient,
            addToast,
            clientData,
            setClientData,
            getClient,
            deleteClient,
            deleteToast,
            fillDetailsToast,
            selectOneToast,
            editClient,
            loginErrorToast,
            masterData,
            addCampaign,
            setSelectedID,
            selectedID,
            campaignID,
            setCampaignID,
            deleteCampaign,
            clientCampaignID,
            setClientCampaignID,
            selectOneCampaignToast,
            monthID,
            setMonthID,
            editMonthData,
            campaignNameAndParent,
            setCampaignNameAndParent,
            showCampaigns,
            setShowCampaigns,
            showMonths,
            setShowMonths,
            clientIndex,
            setClientIndex,
            clicks,
            totalClicks,
            impressions,
            totalImpressions,
            spend,
            totalSpend,
            totalClicksAsOne,
            totalImpressionsAsOne,
            totalSpendAsOne,
            totalClicksByClient,
            totalImpressionsByClient,
            totalSpendByClient,
            campaignCount,
            totalSpendAsClient,
            totalClicksAsClient,
            totalImpressionsAsClient,
            color,
            setColor,
            campaignIndex,
            setCampaignIndex,
            singleClicks,
            singleImpressions,
            singleSpend,
            totalSingleClicks,
            totalSingleImpressions,
            totalSingleSpend,
            loginSuccessToast,
            loginFillToast,
            user,
            logoutSuccessToast,
            contactGCToast,
            getMarvin,
            marvinData,
            setQuery,
            getAriel,
            arielData,
            setDatePreset,
            setArielClient,
            setArielClientGoogle,
            getArielGoogle,
            arielClientGoogle,
            arielClient,
            arielGoogleData,
            arielStartEnd,
            arielGoogleLeadCount,
            setArielGoogleLeadCount,
            arielFBDates,
            setArielFBDates,
            getArielCustom,
            arielClientToast,
            arielDateToast,
            getPinnyFixture,
            pinnyFixtures,
            pinnyOdds,
            getPinnyOdds,
            sendToSlack,
            setSlackData,
            slackData,
            sendToSlackToast,
            setPinnySport,
            pinnySport,
            setArielLevel,
            getArielAll,
            arielFBAllData,
            arielGSAllData,
            getUltimateDB,
            setHomeTeam,
            homeTeam,
            setAwayTeam,
            awayTeam,
            NHLData,
            oddsNFL,
            lastRun,
            oddsNHL,
            oddsNBA,
            setNHLTeam,
            NHLTeam,
            getOddsNHL_GC,
            getOddsNFL_GC,
            getOddsNBA_GC,
            getLiveNHL,
            liveNHL,
            getNHLClosing,
            nhlClose
        }}>
            {children}
        </ContextState.Provider>
    )
}

export default ContextAPI

export const useContextState = () => useContext(ContextState)