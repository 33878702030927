import React from 'react'
import { useContextState } from '../../../ContextAPI'

const ArielOverview = () => {

    const { arielFBAllData, arielGSAllData, arielOverviewStartEnd, color } = useContextState()
    /* 
        const arielAllClients = [process.env.REACT_APP_FB_ACCOUNT1, process.env.REACT_APP_FB_ACCOUNT2, process.env.REACT_APP_FB_ACCOUNT3, process.env.REACT_APP_FB_ACCOUNT4]
        const arielAllGoogleSheets = [process.env.REACT_APP_GOOGLESHEET1, process.env.REACT_APP_GOOGLESHEET2, process.env.REACT_APP_GOOGLESHEET3, process.env.REACT_APP_GOOGLESHEET4] */

    let reach1 = 0
    let reach2 = 0
    let reach3 = 0
    let reach4 = 0
    let clicks1 = 0
    let clicks2 = 0
    let clicks3 = 0
    let clicks4 = 0
    let impressions1 = 0
    let impressions2 = 0
    let impressions3 = 0
    let impressions4 = 0
    let spend1 = 0
    let spend2 = 0
    let spend3 = 0
    let spend4 = 0
    let ctr1 = 0
    let ctr2 = 0
    let ctr3 = 0
    let ctr4 = 0
    let leads1 = 0
    let leads2 = 0
    let leads3 = 0
    let leads4 = 0
    var date_start = null
    var date_stop = null



    arielFBAllData?.forEach((client) => {
        client?.forEach((campaign) => {
            if (campaign.clientID === process.env.REACT_APP_FB_ACCOUNT1) {
                reach1 += Number(campaign.reach)
                impressions1 += Number(campaign.impressions)
                spend1 += Number(campaign.spend)
                clicks1 += Number(campaign.clicks)
                ctr1 += Number(campaign.ctr)
            } else if (campaign.clientID === process.env.REACT_APP_FB_ACCOUNT2) {
                reach2 += Number(campaign.reach)
                impressions2 += Number(campaign.impressions)
                spend2 += Number(campaign.spend)
                clicks2 += Number(campaign.clicks)
                ctr2 += Number(campaign.ctr)
            } else if (campaign.clientID === process.env.REACT_APP_FB_ACCOUNT3) {
                reach3 += Number(campaign.reach)
                impressions3 += Number(campaign.impressions)
                spend3 += Number(campaign.spend)
                clicks3 += Number(campaign.clicks)
                ctr3 += Number(campaign.ctr)
            } else if (campaign.clientID === process.env.REACT_APP_FB_ACCOUNT4) {
                reach4 += Number(campaign.reach)
                impressions4 += Number(campaign.impressions)
                spend4 += Number(campaign.spend)
                clicks4 += Number(campaign.clicks)
                ctr4 += Number(campaign.ctr)
            }
            date_start = new Date((campaign?.date_start)?.replace(/-/g, '\/'))
            date_stop = new Date((campaign?.date_stop)?.replace(/-/g, '\/'))
        })
    })

    arielGSAllData?.map((client) => {
        client.rows.map((row) => {
            var newDate = new Date(row?.c[0]?.f)
            newDate.setHours(0, 0, 0, 0) //set time to 0 so only comparing dates
            if (newDate >= date_start && newDate <= date_stop) {
                if (client.url === process.env.REACT_APP_GOOGLESHEET1) {
                    leads1 += 1
                } else if (client.url === process.env.REACT_APP_GOOGLESHEET2) {
                    leads2 += 1
                } else if (client.url === process.env.REACT_APP_GOOGLESHEET3) {
                    leads3 += 1
                } else if (client.url === process.env.REACT_APP_GOOGLESHEET4) {
                    leads4 += 1
                }
            }
        })
    })


    return (
        <div className='overflow-x-auto '>

            <table className='w-full sm:shadow-sm mb-1'>
                <thead className='text-white'>
                    <tr className='mb-2 sm:mb-0' style={{ backgroundColor: color }}>
                        <th className='p-1 text-left'>Client</th>
                        <th className='p-1 text-left'>Reach</th>
                        <th className='p-1 text-left'>Impressions</th>
                        <th className='p-1 text-left'>Clicks</th>
                        <th className='p-1 text-left'>Spend</th>
                        <th className='p-1 text-left'>CTR</th>
                        <th className='p-1 text-left'>CPM</th>
                        <th className='p-1 text-left'>Leads</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='hover:bg-slate-200'>
                        <td className='p-1'>Don Johnson</td>
                        <td className='p-1'>{reach1}</td>
                        <td className='p-1'>{impressions1}</td>
                        <td className='p-1'>{clicks1}</td>
                        <td className='p-1'>${spend1.toFixed(2)}</td>
                        <td className='p-1'>{ctr1.toFixed(2)}%</td>
                        <td className='p-1'>${isNaN((spend1 / (impressions1 / 1000)).toFixed(2)) ? 0 : (spend1 / (impressions1 / 1000)).toFixed(2)}</td>
                        <td className='p-1'>{leads1}</td>
                    </tr>
                    <tr className='hover:bg-slate-200'>
                        <td className='p-1'>Dustan</td>
                        <td className='p-1'>{reach2}</td>
                        <td className='p-1'>{impressions2}</td>
                        <td className='p-1'>{clicks2}</td>
                        <td className='p-1'>${spend2.toFixed(2)}</td>
                        <td className='p-1'>{ctr2.toFixed(2)}%</td>
                        <td className='p-1'>${isNaN((spend2 / (impressions2 / 1000)).toFixed(2)) ? 0 : (spend2 / (impressions2 / 1000)).toFixed(2)}</td>
                        <td className='p-1'>{leads2}</td>
                    </tr>
                    <tr className='hover:bg-slate-200'>
                        <td className='p-1'>Kasey</td>
                        <td className='p-1'>{reach3}</td>
                        <td className='p-1'>{impressions3}</td>
                        <td className='p-1'>{clicks3}</td>
                        <td className='p-1'>${spend3.toFixed(2)}</td>
                        <td className='p-1'>{ctr3.toFixed(2)}%</td>
                        <td className='p-1'>${isNaN((spend3 / (impressions3 / 1000)).toFixed(2)) ? 0 : (spend3 / (impressions3 / 1000)).toFixed(2)}</td>
                        <td className='p-1'>{leads3}</td>
                    </tr>
                    <tr className='hover:bg-slate-200'>
                        <td className='p-1'>Eli</td>
                        <td className='p-1'>{reach4}</td>
                        <td className='p-1'>{impressions4}</td>
                        <td className='p-1'>{clicks4}</td>
                        <td className='p-1'>${spend4.toFixed(2)}</td>
                        <td className='p-1'>{ctr4.toFixed(2)}%</td>
                        <td className='p-1'>${isNaN((spend4 / (impressions4 / 1000)).toFixed(2)) ? 0 : (spend4 / (impressions4 / 1000)).toFixed(2)}</td>
                        <td className='p-1'>{leads4}</td>
                    </tr>
                </tbody>
            </table>
            <p className='text-xs'>*From: {date_start?.toDateString()} To: {date_stop?.toDateString()}</p>
        </div>
    )
}

export default ArielOverview