import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useContextState } from '../ContextAPI';
import ClientSelector from './selectors/ClientSelector';




export default function CampaignAddModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showResults, setShowResults] = useState(true)
    const [showJulyHalf, setShowJulyHalf] = useState(false)


    const {  selectedID, addCampaign } = useContextState()

    /* const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}; */


    const [clientFormData, setClientFormData] = useState({

        campaignName: '',
        parentId: null,
        data: {
            january: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            february: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            march: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            april: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            may: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            june: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            july: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            august: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            september: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            october: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            november: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },
            december: {
                clicks: 0,
                impressions: 0,
                spend: 0,
            },

        }

    })



    const clearForm = () => {

        setClientFormData({
            campaignName: '',
            parentId: null,
            data: {
                january: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                february: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                march: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                april: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                may: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                june: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                july: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                august: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                september: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                october: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                november: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                december: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },

            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        //addCampaign(selectedID, clientFormData)

        addCampaign(clientFormData)
        setShowResults(false)
        setShowJulyHalf(false)
        handleClose()
        clearForm()

        //console.log(clientFormData)
        /*         if (clientFormData.firstName === '' &&
                    clientFormData.lastName === '' &&
                    clientFormData.email === '' &&
                    clientFormData.phone === '' &&
                    clientFormData.campaignName === ''
                ) {
        
                    fillDetailsToast() //pop up to fill details
        
                } else {
        
                    createClient(clientFormData)
                    handleClose()
                    clearForm()
                } */
    }

    const MonthBox = ({ month }) => {

        return (
            <div className='md:flex flex-row items-center w-full justify-between'>
                <p className='px-5 text-center md:py-0 py-2 capitalize'>{month}</p>
                <div className='flex md:w-1/2 w-full'>
                    <TextField variant='outlined' label='Clicks' type='number' fullWidth value={clientFormData.data[month].clicks} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], clicks: e.target.value } } })} />
                    <TextField variant='outlined' label='Impressions' type='number' fullWidth value={clientFormData.data[month].impressions} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], impressions: e.target.value } } })} />
                    <TextField variant='outlined' label='Spend ($)' type='number' fullWidth value={clientFormData.data[month].spend} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], spend: e.target.value } } })} />
                </div>
            </div>
        )
    }



    return (
        <div>
            <Button variant='outlined' onClick={handleOpen}>Add Campaign</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box className='absolute top-1/2 left-1/2 border-2 p-4 bg-white -translate-x-1/2 -translate-y-1/2 sm:w-1/2 w-full overflow-y-scroll max-h-screen'>
                    <div className='flex justify-between'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Campaign Information
                        </Typography>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleClose}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    {/* form */}
                    <form autoComplete='off' onSubmit={handleSubmit}>
                        <ClientSelector />
                        {/* {selectedID} */}
                        <TextField variant='outlined' label='Campaign Name' fullWidth value={clientFormData.campaignName} onChange={(e) => setClientFormData({ ...clientFormData, campaignName: e.target.value })} />

                        <div className='flex justify-between items-center py-2'>
                            <p className='mt-2'>Results (manual entries):</p>
                            {/* <button className='border-2' type='button' onClick={() => setShowResults((prevShowResults) => !prevShowResults)}>Add Results</button> */}
                            {showResults && <button className='border-2 rounded-lg' type='button' onClick={() => setShowJulyHalf((prevShowJulyHalf) => !prevShowJulyHalf)}>{showJulyHalf ? 'Hide' : 'Show'} July - December</button>}
                        </div>

                        {/*                         <div className='flex flex-wrap text-sm my-1 border-2'>
                            {months.map((m) => <span className='mx-2' onClick={(e) => console.log(e.target.outerText)}>{m}</span>)}
                        </div> */}

                        {showResults &&
                            <div className='flex-row items-center'>
                                {/* january */}
                                {/*  <div className='md:flex flex-row items-center w-full justify-between'>
                                    <p className='px-5 text-center md:py-0 py-2'>January</p>
                                    <div className='flex md:w-1/2 w-full'>
                                        <TextField variant='outlined' label='Clicks' type='number' fullWidth value={clientFormData.data['january'].clicks} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, ['january']: { ...clientFormData.data['january'], clicks: e.target.value } } })} />
                                        <TextField variant='outlined' label='Impressions' type='number' fullWidth value={clientFormData.data['january'].impressions} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, ['january']: { ...clientFormData.data['january'], impressions: e.target.value } } })} />
                                        <TextField variant='outlined' label='Spend ($)' type='number' fullWidth value={clientFormData.data['january'].spend} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, ['january']: { ...clientFormData.data['january'], spend: e.target.value } } })} />
                                    </div>
                                </div> */}

                                {MonthBox({ month: 'january' })}
                                {MonthBox({ month: 'february' })}
                                {MonthBox({ month: 'march' })}
                                {MonthBox({ month: 'april' })}
                                {MonthBox({ month: 'may' })}
                                {MonthBox({ month: 'june' })}


                                {showJulyHalf &&
                                    (
                                        <div>
                                            {MonthBox({ month: 'july' })}
                                            {MonthBox({ month: 'august' })}
                                            {MonthBox({ month: 'september' })}
                                            {MonthBox({ month: 'october' })}
                                            {MonthBox({ month: 'november' })}
                                            {MonthBox({ month: 'december' })}
                                        </div>
                                    )
                                }
                            </div>

                        }



                        <div className='flex mt-5'>
                            <Button variant='contained' color='primary' size='large' type='submit' fullWidth onClick={() => setClientFormData({ ...clientFormData, parentId: selectedID })}>Submit</Button>

                            <Button variant='contained' color='secondary' size='small' onClick={clearForm} fullWidth>Clear</Button>

                        </div>


                    </form>

                </Box>
            </Modal>
        </div >
    );
}
