import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContextState } from '../../../ContextAPI';

export default function ArielLevelSelector() {
    const [age, setAge] = React.useState('adset');

    const { setArielLevel } = useContextState()

    const handleChange = (event) => {
        setAge(event.target.value);
        setArielLevel(event.target.value)
    };

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={'campaign'}>Campaign</MenuItem>
                    <MenuItem value={'adset'}>Ad Set</MenuItem>
                    <MenuItem value={'ad'}>Ad</MenuItem>
                </Select>
            </FormControl>
        </Box >
    );
}
