import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContextState } from '../../ContextAPI';

export default function DashboardCampaignSelector() {
    const [age, setAge] = React.useState('all');

    const { masterData, setCampaignIndex, clientIndex } = useContextState()

    const handleChange = (event) => {
        setAge(event.target.value)
        setCampaignIndex(event.target.value)
        //console.log(event.target.value)
    };


    React.useEffect(() => {
        setCampaignIndex('all')
        setAge('all')
        // eslint-disable-next-line
    }, [clientIndex])

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Campaigns</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                    defaultValue='all'
                >
                    <MenuItem value={'all'}>All Campaigns</MenuItem>
                    {masterData.map((client, index) => {
                        if (client.parentId === clientIndex) {
                            return <MenuItem key={index} value={index}>{client.campaignName}</MenuItem>
                        }
                    })}
                </Select>
            </FormControl>
        </Box >
    );
}
