import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




export function BarHoriAudChart() {


    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Top Cities',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        
    };

    const fakeCities = [
        faker.address.state(), 
        faker.address.state(), 
        faker.address.state(), 
        faker.address.state(), 
        faker.address.state(), 
        faker.address.state(), 
        faker.address.state(),
        faker.address.state(), 
        faker.address.state(),
        faker.address.state()
    ]

    const fakeResults = [
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 }),
        faker.datatype.number({ min: 1, max: 100 })
    ]



    const labels = fakeCities

    const data = {
        labels,
        datasets: [
            {
                label: 'Reach',
                data: fakeResults,
                backgroundColor: 'rgb(235, 130, 88)',
            },
        ],
    };

    return <Bar options={options} data={data}/>;
}
