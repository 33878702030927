import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useContextState } from '../../ContextAPI';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




export function BarChart() {

    const { clientIndex, totalClicksByClient, totalClicks, totalImpressions, totalImpressionsByClient, totalSpend, totalSpendByClient, singleClicks, singleImpressions, singleSpend, campaignIndex } = useContextState()

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Clicks/ Impressions/ Spend',
            },

        },
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y: {
                grid: {
                    display: true
                },
            }
        },
    };

    //const months = marketResult.map((i) => i.Month)


    const labels = months

    const data = {
        labels,
        datasets: [
            /*         {
                        label: 'CTR',
                        data: ctr,
                        backgroundColor: 'rgba(255, 99, 132, 0.8)',
                    }, */
            {
                label: 'Clicks',
                data: clientIndex === 'all' ? totalClicks : campaignIndex === 'all' ? totalClicksByClient : singleClicks,
                backgroundColor: 'rgba(53, 120, 235, 1)',
            },
            {
                label: 'Impressions',
                data: clientIndex === 'all' ? totalImpressions : campaignIndex === 'all' ? totalImpressionsByClient : singleImpressions,
                backgroundColor: 'rgba(235, 130, 88, 1)',
            },
            {
                label: 'Spent',
                data: clientIndex === 'all' ? totalSpend : campaignIndex === 'all' ? totalSpendByClient : singleSpend,
                backgroundColor: 'rgba(42, 46, 69, 1)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}
