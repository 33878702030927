import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




export function BarAgeChart() {


    const months = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']

    const total1 = faker.datatype.number({ max: 100 })
    const total2 = faker.datatype.number({ max: 100 })
    const total3 = faker.datatype.number({ max: 100 })
    const total4 = faker.datatype.number({ max: 100 })
    const total5 = faker.datatype.number({ max: 100 })
    const total6 = faker.datatype.number({ max: 100 })

    const male1 = (total1 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100
    const male2 = (total2 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100
    const male3 = (total3 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100
    const male4 = (total4 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100
    const male5 = (total5 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100
    const male6 = (total6 * faker.datatype.number({ min: 35, max: 65, precision: 1 })) / 100

    const female1 = total1 - male1
    const female2 = total2 - male2
    const female3 = total3 - male3
    const female4 = total4 - male4
    const female5 = total5 - male5
    const female6 = total6 - male6

    const male = [male1, male2, male3, male4, male5, male6]
    const female = [female1, female2, female3, female4, female5, female6]

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    display: true
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    callback: function (value) {
                        return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
                    },
                }
            }
        },
    };

    //const months = marketResult.map((i) => i.Month)


    const labels = months

    const data = {
        labels,
        datasets: [
            {
                label: 'Male',
                data: male,
                backgroundColor: 'rgba(53, 120, 235, 1)',
            },
            {
                label: 'Female',
                data: female,
                backgroundColor: 'rgba(250, 60, 178, 1)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}
