import { useContextState } from "./ContextAPI";
import './App.css'
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home'
import About from './pages/About'
import Portfolio from './pages/Portfolio'
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Clients from "./pages/Clients";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ClientLogin from "./pages/ClientsLogin";
import Campaigns from "./pages/Campaigns";
import Ariel from "./pages/clients/Ariel";
import Gilbert from "./pages/clients/Gilbert";
import GilbertRoute from "./routes/GilbertRoute";
import Marvin from "./pages/clients/Marvin";
import MarvinRoute from "./routes/MarvinRoute";
import ArielRoute from "./routes/ArielRoute";
import Rory from "./pages/clients/Rory";
import RoryRoute from "./routes/RoryRoute";
import SportsPortfolio from "./pages/SportsPortfolio";
import Home2023 from "./pages/Home2023";




function App() {
  const { activeMenu, user } = useContextState()

  return (
    <BrowserRouter>
      <div className="flex">
        {activeMenu ? (
          <div className='w-72 fixed sidebar z-50'>
            <Sidebar />
          </div>
        ) : (
          <div className='w-0'>
            <Sidebar />
          </div>
        )}
        <div className={`md:static z-50 w-full min-h-screen ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>

          <div className='sticky top-0 z-50'>
            <Navbar />
          </div>

          <div className=''>
            <Routes>
              <Route path='/' exact element={<Home2023 />} />
              <Route path='/about' exact element={<About />} />
              <Route path='/portfolio' exact element={<Portfolio />} />
              <Route path='/clientlogin' exact element={<ClientLogin />} />
              <Route path='/dashboard' exact element={<Dashboard />} />
              <Route path='/settings' exact element={<Settings />} />
              <Route path='/clients' exact element={<Clients />} />
              <Route path='/campaigns' exact element={<Campaigns />} />
              <Route path='/portfolio_sports/*' exact element={<SportsPortfolio />} />
              <Route path='/portfolio_sports' exact element={<Navigate to="/portfolio_sports/odds" />} />

              {(user) && <Route element={<MarvinRoute />}>
                <Route exact path='/marvin' element={<Marvin />} />
              </Route>}

              {(user) && <Route element={<ArielRoute />}>
                <Route exact path='/ariel' element={<Ariel />} />
              </Route>}

              {(user) && <Route element={<RoryRoute />}>
                <Route exact path='/rory' element={<Rory />} />
              </Route>}


              {(user) && <Route element={<GilbertRoute />}>
                <Route exact path='/gilbert' element={<Gilbert />} />
              </Route>}

            </Routes>

            <ToastContainer
              autoClose={5000}
              pauseOnFocusLoss={false}
              position="top-center"
            />
          </div>
        </div>

      </div>
    </BrowserRouter >
  );
}

export default App;
