import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ContextAPI from './ContextAPI';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjUX9cdHdRR2FUWEM=')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextAPI>
      <App />
  </ContextAPI>
);

