import React from 'react'
import { AccumulationChartComponent, AccumulationLegend, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries, AccumulationDataLabel, AccumulationTooltip, } from '@syncfusion/ej2-react-charts'

const data = [
    {
        city: 'Kuala Lumpur',
        years: 18,
    },
    {
        city: 'Vancouver',
        years: 9,
    },
    {
        city: 'London, UK',
        years: 2,
    },
    {
        city: 'Toronto',
        years: 4,

    },

]

const CountryChart = () => {
    return (
        <div>
            <AccumulationChartComponent height='200px' tooltip={{ enable: true }} legendSettings={{ position: 'Bottom' }} >
                <Inject services={[PieSeries, AccumulationTooltip, AccumulationLegend, AccumulationDataLabel]} />
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective dataSource={data} xName='city' yName='years' />
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
        </div>
    )
}

export default CountryChart