import React, { useEffect } from 'react'
import { useContextState } from '../../ContextAPI'
import MarvinDataTable from '../../components/clientcomponents/marvin/MarvinDataTable'
import { MarvinPieChart } from '../../components/clientcomponents/marvin/MarvinPieChart'
import { MarvinBarChart } from '../../components/clientcomponents/marvin/MarvinBarChart'
import { MarvinPieChart2 } from '../../components/clientcomponents/marvin/MarvinPieChart2'
import MarvinSummary from '../../components/clientcomponents/marvin/MarvinSummary'
import { Button } from '@mui/material'

const Marvin = () => {
    const { getMarvin } = useContextState()

    useEffect(() => {
        getMarvin('')
    }, [])

    return (
        
        <div className="flex-row text-gray-600 body container px-10 mx-auto flex-wrap justify-center w-full">
            <Button variant='outlined' onClick={() => getMarvin('')}>All</Button>
            <Button variant='outlined' onClick={() => getMarvin("SELECT * WHERE C='Store 1'")}>Store 1</Button>
            <Button variant='outlined' onClick={() => getMarvin("SELECT * WHERE C='Store 2'")}>Store 2</Button>
            <Button variant='outlined' onClick={() => getMarvin("SELECT * WHERE C='Store 3'")}>Store 3</Button>
            <Button variant='outlined' onClick={() => getMarvin("SELECT * WHERE C='Store 4'")}>Store 4</Button>
            {/* table */}

            <div>
                <MarvinSummary />
            </div>
            <div className='md:flex flex-row border-2 rounded-xl my-5 items-center md:h-96 h-auto'>
                <div className='md:w-3/12 w-full md:h-80 h-72 mt-5'><MarvinPieChart /></div>
                <div className='md:w-3/12 w-full md:h-80 h-72 mt-5'><MarvinPieChart2 /></div>
                <div className='flex relative items-center md:w-6/12 w-full md:h-96 h-96 mt-5'><MarvinBarChart /></div>
            </div>

            <div className=''>
                <MarvinDataTable />
            </div>
        </div >



    )
}

export default Marvin