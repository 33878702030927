import { Button } from '@mui/material'
import React, { useState } from 'react'
import RoryNHLAwaySelector from '../../components/clientcomponents/rory/RoryNHLAwaySelector'
import RoryNHLHomeSelector from '../../components/clientcomponents/rory/RoryNHLHomeSelector'
import RorySportSelector from '../../components/clientcomponents/rory/RorySportSelector'
import { useContextState } from '../../ContextAPI'



const Rory = () => {

    const { getPinnyFixture, pinnyFixtures, getPinnyOdds, pinnyOdds, pinnySport, getUltimateDB, NHLData, homeTeam, awayTeam } = useContextState()

    const [showAll, setShowAll] = useState(true)
    const [showHome, setShowHome] = useState(false)
    const [showAway, setShowAway] = useState(false)
    const [showAll1, setShowAll1] = useState(true)
    const [showHome1, setShowHome1] = useState(false)
    const [showAway1, setShowAway1] = useState(false)

    const showAllFixtures = () => {
        setShowAll(true)
        setShowHome(false)
        setShowAway(false)
    }

    const showHomeFixtures = () => {
        setShowAll(false)
        setShowHome(true)
        setShowAway(false)
    }

    const showAwayFixtures = () => {
        setShowAll(false)
        setShowHome(false)
        setShowAway(true)
    }

    const showAllFixtures1 = () => {
        setShowAll1(true)
        setShowHome1(false)
        setShowAway1(false)
    }

    const showHomeFixtures1 = () => {
        setShowAll1(false)
        setShowHome1(true)
        setShowAway1(false)
    }

    const showAwayFixtures1 = () => {
        setShowAll1(false)
        setShowHome1(false)
        setShowAway1(true)
    }

    /* dates */
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const tomorrow = new Date(new Date().setDate(today.getDate() + 2))
    tomorrow.setHours(0, 0, 0, 0)

    //set data for download
    var csvData = []

    const TableRows = () => {

        return pinnyFixtures?.league?.[0]?.events?.sort((a, b) => a.rotNum - b.rotNum).map((f, index) => {
            const pinDate = new Date(f.starts)
            if (pinDate >= today && pinDate <= tomorrow && f.liveStatus === 2 && f.rotNum < 1000) { /* && pinDate <= tomorrow && f.liveStatus === 2 && f.rotNum < 1000 */
                return (
                    <tr className='border-b hover:bg-gray-200' key={index}>
                        <td className='border-r text-center py-2'>{f.rotNum}</td>
                        <td className='border-r text-center py-2'>{f.starts}</td>
                        <td className='border-r text-center py-2'>{f.away}</td>
                        <td className='border-r text-center py-2'>{f.home}</td>
                        {pinnyOdds?.leagues?.[0]?.events?.map((o, i) => {
                            if (o.id === f.id) {
                                //jsonData for CSV
                                csvData.push({ rotNum: f.rotNum, startTime: f.starts, away: f.away, home: f.home, awayML: o?.periods?.[0]?.moneyline?.away, homeML: o?.periods?.[0]?.moneyline?.home })
                                return ([
                                    <td className='border-r text-center py-2' key={i}>{o?.periods?.[0]?.moneyline?.away}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.moneyline?.home}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.spreads?.[0]?.hdp}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.spreads?.[0]?.away}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.spreads?.[0]?.home}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.totals?.[0]?.points}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.totals?.[0]?.over}</td>,
                                    <td className='border-r text-center py-2'>{o?.periods?.[0]?.totals?.[0]?.under}</td>,
                                ])
                            }
                        })}
                    </tr>
                )
            }
        })
    }

    let last5Results = []
    let last5HomeResults = []
    let last5AwayResults = []
    let last5Price = []
    let last5HomePrice = []
    let last5AwayPrice = []
    let last5Results1 = []
    let last5HomeResults1 = []
    let last5AwayResults1 = []
    let last5Price1 = []
    let last5HomePrice1 = []
    let last5AwayPrice1 = []

    NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
        var team1 = row[1]
        var team2 = row[2]
        var score1 = row[24]
        var score2 = row[25]
        var open1 = 1 / row[38]
        var close1 = 1 / row[49]
        var pchange1 = ((open1 - close1) * 100).toFixed(2)

        if (team1 === homeTeam) {
            if (score1 > score2) {
                last5Results.push(["W"])
                last5HomeResults.push(["W"])
            } else if (score2 > score1) {
                last5Results.push(["L"])
                last5HomeResults.push(["L"])
            } else if (score1 == score2) {
                last5Results.push(["D"])
                last5HomeResults.push(["D"])
            }
            last5HomePrice.push(pchange1)
        }

        if (team2 === homeTeam) {
            if (score1 < score2) {
                last5Results.push(["W"])
                last5AwayResults.push(["W"])
            } else if (score2 < score1) {
                last5Results.push(["L"])
                last5AwayResults.push(["L"])
            } else if (score1 == score2) {
                last5Results.push(["D"])
                last5AwayResults.push(["L"])
            }
            last5AwayPrice.push(pchange1 * -1)
        }

        if (team1 === homeTeam) {
            last5Price.push(pchange1)
        } else if (team2 === homeTeam) {
            last5Price.push(pchange1 * -1)
        }

        //team2
        if (team1 === awayTeam) {
            if (score1 > score2) {
                last5Results1.push(["W"])
                last5HomeResults1.push(["W"])
            } else if (score2 > score1) {
                last5Results1.push(["L"])
                last5HomeResults1.push(["L"])
            } else if (score1 == score2) {
                last5Results1.push(["D"])
                last5HomeResults1.push(["D"])
            }
            last5HomePrice1.push(pchange1)
        }

        if (team2 === awayTeam) {
            if (score1 < score2) {
                last5Results1.push(["W"])
                last5AwayResults1.push(["W"])
            } else if (score2 < score1) {
                last5Results1.push(["L"])
                last5AwayResults1.push(["L"])
            } else if (score1 == score2) {
                last5Results1.push(["D"])
                last5AwayResults1.push(["L"])
            }
            last5AwayPrice1.push(pchange1 * -1)
        }

        if (team1 === awayTeam) {
            last5Price1.push(pchange1)
        } else if (team2 === awayTeam) {
            last5Price1.push(pchange1 * -1)
        }

    })

    console.log(last5Price)

    const last5Box = (team) => {
        return (
            <div className='border-2 w-1/2'>
                <div>
                    <p>last 5 games</p>
                    <div className='flex border-2 justify-between'>

                        <div>
                            <span>Form: </span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                        </div>

                        <div>
                            <span>Price: </span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                            <span>W</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const profilerRow = (date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un) => {
        return (
            <div>
                <div className={`md:flex flex-row md:h-32 h-auto md:py-0 py-1 border-2 border-black bg-${winColor}`}>
                    <div className='md:flex flex-row my-max items-center justify-center md:w-2/12 w-full md:border-r-2 border-b-2'>
                        <p className='text-xs text-center'>{date}</p>
                    </div>

                    <div className='flex my-max items-center justify-end md:w-4/12 w-full md:border-0 border-b-2'>
                        <div className='text-right md:w-9/12 md:mr-5 mr-0'>
                            <p className='text-xs font-bold'>{score1 > score2 ? <span className='bg-green-300 p-1 rounded-md'>{mov}</span> : <></>} <span className={team1_un}>{team1}</span></p>
                            <p className='text-xs'>GK: {gk1}</p>
                            <p className='text-xs text-clip overflow-hidden ...'>Inj: {inj1}</p>
                        </div>
                        <div className='flex my-max items-center justify-end w-3/12'>
                            <div className='px-2'>
                                <p className='text-xl text-center'>{score1}</p>
                                <p className='text-xs'>{xg1} xG</p>
                            </div>
                        </div>
                    </div>

                    <div className={`flex md:flex-row flex-row-reverse my-max items-center justify-end md:w-4/12 w-full`}>

                        <div className='flex my-max items-center md:justify-start justify-end w-3/12 '>
                            <div className='px-2'>
                                <p className='text-xl text-center'>{score2}</p>
                                <p className='text-xs'>{xg2} xG</p>
                            </div>
                        </div>
                        <div className='flex-col md:text-left text-right w-9/12 ml-5'>
                            <p className='font-bold text-xs'><span className={team2_un}>{team2}</span> {score2 > score1 ? <span className='bg-green-300 p-1 rounded-md'>{mov}</span> : <></>}</p>
                            <p className='text-xs'>GK: {gk2}</p>
                            <p className='text-xs text-clip overflow-hidden ...'>Inj: {inj2}</p>
                        </div>

                    </div>

                    <div className='flex my-max items-center justify-center border-l-2 md:w-2/12 w-full'>
                        <div className='md:block flex md:ml-2 ml-0 items-center '>
                            <p className='text-xs font-bold'>Price Move: <span className='font-normal'>{pchange1}%</span></p>
                            <p className='text-xs font-bold'>Value: <span className='font-normal'>{value}%</span></p>
                            {/* <p className='text-xs'>Close PNL: {closepnl}</p> */}
                        </div>
                    </div>

                </div>
            </div>

        )
    }


    const objectToCsv = (data) => {
        const csvRows = []

        //get headers
        const headers = Object?.keys(data?.[0])
        csvRows?.push(headers.join(','))

        //loop rows
        for (const row of data) {
            const values = headers?.map((head) => {
                const escaped = ('' + row[head])?.replace(/"/g, '\\"')
                //deal with empty/undefined values
                if (escaped === "undefined") {
                    return null
                } else {
                    return `"${escaped}"`
                }
            })
            csvRows.push(values.join(','))
        }
        return csvRows?.join('\n')
        //form escaped comma separated value
    }

    const downloadToCSV = (data) => {

        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', 'report.csv')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <div className="flex-row text-gray-600 container px-10 mx-auto flex-wrap justify-center w-full mb-10">
            <div className='md:flex flex-row'>

                {/* team1 */}
                <div className='md:w-1/2 w-full'>
                    <div className='w-1/2'>
                        <RoryNHLHomeSelector />
                    </div>

                    <div className='w-full border-2'>

                        <button onClick={() => getUltimateDB()} className='border-2'>get google</button>
                        <button onClick={() => showAllFixtures()} className={`${(showAll) ? 'bg-blue-300' : ''} border-2 px-6`}>All</button>
                        <button onClick={() => showHomeFixtures()} className={`${(showHome) ? 'bg-blue-300' : ''} border-2 px-6`}>Home</button>
                        <button onClick={() => showAwayFixtures()} className={`${(showAway) ? 'bg-blue-300' : ''} border-2 px-6`}>Away</button>
                    </div>

                    <div className='border-2'>
                        <div>

                            <div className='md:flex flex-row justify-between'>

                                <p className='flex items-center text-xs font-bold'>Last 5 Games:</p>

                                {/* win/loses */}
                                {showAll &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5Results[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[4]}</span>
                                        <span className={`${last5Results[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[3]}</span>
                                        <span className={`${last5Results[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[2]}</span>
                                        <span className={`${last5Results[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[1]}</span>
                                        <span className={`${last5Results[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[0]}</span>
                                    </div>}

                                {showHome &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5HomeResults[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[4]}</span>
                                        <span className={`${last5HomeResults[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[3]}</span>
                                        <span className={`${last5HomeResults[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[2]}</span>
                                        <span className={`${last5HomeResults[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[1]}</span>
                                        <span className={`${last5HomeResults[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[0]}</span>
                                    </div>}

                                {showAway &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5AwayResults[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[4]}</span>
                                        <span className={`${last5AwayResults[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[3]}</span>
                                        <span className={`${last5AwayResults[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[2]}</span>
                                        <span className={`${last5AwayResults[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[1]}</span>
                                        <span className={`${last5AwayResults[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[0]}</span>
                                    </div>}


                                {/* price changes */}
                                {showAll && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5Price[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price[4]}%</span>
                                    <span className={`${last5Price[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price[3]}%</span>
                                    <span className={`${last5Price[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price[2]}%</span>
                                    <span className={`${last5Price[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price[1]}%</span>
                                    <span className={`${last5Price[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price[0]}%</span>
                                </div>}

                                {showHome && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5HomePrice[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice[4]}%</span>
                                    <span className={`${last5HomePrice[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice[3]}%</span>
                                    <span className={`${last5HomePrice[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice[2]}%</span>
                                    <span className={`${last5HomePrice[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice[1]}%</span>
                                    <span className={`${last5HomePrice[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice[0]}%</span>
                                </div>}

                                {showAway && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5AwayPrice[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice[4]}%</span>
                                    <span className={`${last5AwayPrice[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice[3]}%</span>
                                    <span className={`${last5AwayPrice[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice[2]}%</span>
                                    <span className={`${last5AwayPrice[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice[1]}%</span>
                                    <span className={`${last5AwayPrice[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice[0]}%</span>
                                </div>}


                            </div>
                        </div>
                    </div>

                    <div className='flex border-2'>
                        <div className='w-2/12 border-r-2'>
                            <p className='text-center text-xs font-bold'>Date</p>
                        </div>
                        <div className='w-8/12'>
                            <div className='flex text-xs font-bold'>
                                <p className='w-1/2 text-center border-r-2'>Home</p>
                                <p className='w-1/2 text-center'>Away</p>
                            </div>
                        </div>
                        <div className='w-2/12 border-l-2 text-xs font-bold'>
                            <p className='text-center'>Details</p>
                        </div>
                    </div>

                    {/* all fixtures */}
                    {showAll && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (homeTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (homeTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (homeTeam === team1) {
                                var team1_un = "underline"
                            } else if (homeTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }


                            if (homeTeam === team1 || homeTeam === team2) {
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }

                    {/* home fixtures */}
                    {showHome && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (homeTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (homeTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (homeTeam === team1) {
                                var team1_un = "underline"
                            } else if (homeTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }

                            if (homeTeam === team1) {
                                console.log(open1 - close1 * 100)
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }


                    {/* away fixtures */}
                    {showAway && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (homeTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (homeTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (homeTeam === team1) {
                                var team1_un = "underline"
                            } else if (homeTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }

                            if (homeTeam === team2) {
                                console.log(open1 - close1 * 100)
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }
                </div>

                {/* team1 */}
                <div className='md:w-1/2 w-full'>
                    <div className='w-1/2'>
                        <RoryNHLAwaySelector />
                    </div>

                    <div className='w-full border-2'>
                        <button onClick={() => getUltimateDB()} className='border-2'>get google</button>
                        <button onClick={() => showAllFixtures1()} className={`${(showAll1) ? 'bg-blue-300' : ''} border-2 px-6`}>All</button>
                        <button onClick={() => showHomeFixtures1()} className={`${(showHome1) ? 'bg-blue-300' : ''} border-2 px-6`}>Home</button>
                        <button onClick={() => showAwayFixtures1()} className={`${(showAway1) ? 'bg-blue-300' : ''} border-2 px-6`}>Away</button>
                    </div>

                    <div className='border-2'>
                        <div>

                            <div className='md:flex flex-row justify-between'>

                                <p className='flex items-center text-xs font-bold'>Last 5 Games:</p>

                                {/* win/loses */}
                                {showAll1 &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5Results1[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results1[4]}</span>
                                        <span className={`${last5Results1[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results1[3]}</span>
                                        <span className={`${last5Results1[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results1[2]}</span>
                                        <span className={`${last5Results1[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results1[1]}</span>
                                        <span className={`${last5Results1[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results1[0]}</span>
                                    </div>}

                                {showHome1 &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5HomeResults1[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults1[4]}</span>
                                        <span className={`${last5HomeResults1[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults1[3]}</span>
                                        <span className={`${last5HomeResults1[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults1[2]}</span>
                                        <span className={`${last5HomeResults1[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults1[1]}</span>
                                        <span className={`${last5HomeResults1[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults1[0]}</span>
                                    </div>}

                                {showAway1 &&
                                    <div className='flex items-center'>
                                        <span className='text-xs font-bold'>Form:</span>
                                        <span className={`${last5AwayResults1[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults1[4]}</span>
                                        <span className={`${last5AwayResults1[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults1[3]}</span>
                                        <span className={`${last5AwayResults1[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults1[2]}</span>
                                        <span className={`${last5AwayResults1[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults1[1]}</span>
                                        <span className={`${last5AwayResults1[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults1[0]}</span>
                                    </div>}


                                {/* price changes */}
                                {showAll1 && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5Price1[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price1[4]}%</span>
                                    <span className={`${last5Price1[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price1[3]}%</span>
                                    <span className={`${last5Price1[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price1[2]}%</span>
                                    <span className={`${last5Price1[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price1[1]}%</span>
                                    <span className={`${last5Price1[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5Price1[0]}%</span>
                                </div>}

                                {showHome1 && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5HomePrice1[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice1[4]}%</span>
                                    <span className={`${last5HomePrice1[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice1[3]}%</span>
                                    <span className={`${last5HomePrice1[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice1[2]}%</span>
                                    <span className={`${last5HomePrice1[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice1[1]}%</span>
                                    <span className={`${last5HomePrice1[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5HomePrice1[0]}%</span>
                                </div>}

                                {showAway1 && <div>
                                    <span className='text-xs font-bold'>Price: </span>
                                    <span className={`${last5AwayPrice1[4] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice1[4]}%</span>
                                    <span className={`${last5AwayPrice1[3] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice1[3]}%</span>
                                    <span className={`${last5AwayPrice1[2] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice1[2]}%</span>
                                    <span className={`${last5AwayPrice1[1] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice1[1]}%</span>
                                    <span className={`${last5AwayPrice1[0] > 0 ? 'bg-green-300' : 'bg-red-300'} rounded-xl px-1 py-1 text-white text-xs`}>{last5AwayPrice1[0]}%</span>
                                </div>}


                            </div>
                        </div>
                    </div>

                    <div className='flex border-2'>
                        <div className='w-2/12 border-r-2'>
                            <p className='text-center text-xs font-bold'>Date</p>
                        </div>
                        <div className='w-8/12'>
                            <div className='flex text-xs font-bold'>
                                <p className='w-1/2 text-center border-r-2'>Home</p>
                                <p className='w-1/2 text-center'>Away</p>
                            </div>
                        </div>
                        <div className='w-2/12 border-l-2 text-xs font-bold'>
                            <p className='text-center'>Details</p>
                        </div>
                    </div>

                    {/* all fixtures */}
                    {showAll1 && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (awayTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (awayTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (awayTeam === team1) {
                                var team1_un = "underline"
                            } else if (awayTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }


                            if (awayTeam === team1 || awayTeam === team2) {
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }

                    {/* home fixtures */}
                    {showHome1 && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (awayTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (awayTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (awayTeam === team1) {
                                var team1_un = "underline"
                            } else if (awayTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }

                            if (awayTeam === team1) {
                                console.log(open1 - close1 * 100)
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }


                    {/* away fixtures */}
                    {showAway1 && <>
                        {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                            var date = row[0]
                            var team1 = row[1]
                            var team2 = row[2]
                            var gk1 = row[3]
                            var gk2 = row[4]
                            var inj1 = row[5]
                            var inj2 = row[6]
                            var score1 = row[24]
                            var score2 = row[25]
                            var xg1 = row[27]
                            var xg2 = row[28]
                            if (row[26] === "0") {
                                var mov = "W"
                            } else {
                                var mov = row[26]
                            }
                            var open1 = 1 / row[38]
                            var close1 = 1 / row[49]
                            var open2 = 1 / row[39]
                            var close2 = 1 / row[50]
                            var pchange1 = ((open1 - close1) * 100).toFixed(2)
                            if (row[53] === "Home") {
                                var valueside = team1
                            } else if (row[53] === "Road") {
                                var valueside = team2
                            }
                            var value = valueside + " " + (row[54] * 100).toFixed(2)
                            var closepnl = Number(row[57]).toFixed(2)
                            if (awayTeam === team1 && score1 > score2) {
                                var winColor = "green-100"
                            } else if (awayTeam === team2 && score1 < score2) {
                                var winColor = "green-100"
                            } else {
                                var winColor = "red-100"
                            }
                            if (awayTeam === team1) {
                                var team1_un = "underline"
                            } else if (awayTeam === team2) {
                                var team2_un = "underline"
                            } else {
                                var team1_un = ""
                                var team2_un = ""
                            }

                            if (awayTeam === team2) {
                                console.log(open1 - close1 * 100)
                                return (
                                    profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                                )
                            }
                        })}
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Rory