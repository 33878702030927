import React, { useEffect } from 'react'
import { useContextState } from '../ContextAPI'
import DataTable from '../components/DataTable'
import CampaignAddModal from '../forms/CampaignModalAdd'
import DataClientSelector from '../forms/selectors/DataClientSelector'
import DataMonthTable from '../components/DataMonthTable'

const Campaigns = () => {
  const { showMonths, setShowMonths, showCampaigns, setShowCampaigns } = useContextState()

  useEffect(() => {
    setShowMonths(false)
    setShowCampaigns(true)
  }, [])

  return (

    <div className='m-2 p-2 md:p-10 bg-white rounded-3xl'>
      <p className='pb-4 text-4xl font-bold text-gray-600 w-full'>Campaigns</p>
      <p className='pb-4 text-base text-gray-600 w-full'>Add or delete campaigns. Click on an individual campaign to further view or edit monthly results data</p>

      <div>
        {showCampaigns && <DataClientSelector />}
        {showCampaigns && <CampaignAddModal />}

        <div>
          {showCampaigns && <DataTable />}
          {showMonths && <DataMonthTable />}
        </div>
      </div>

    </div>
  )
}

export default Campaigns