import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useContextState } from '../../../ContextAPI';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    /*     {
            id: 'campaign_id',
            numeric: false,
            disablePadding: false,
            label: 'campaign_id',
        }, */
    {
        id: 'campaign_name',
        numeric: false,
        disablePadding: false,
        label: 'campaign_name',
    },
    {
        id: 'adset_name',
        numeric: true,
        disablePadding: false,
        label: 'adset_name',
    },
    /*     {
            id: 'ad_id',
            numeric: true,
            disablePadding: false,
            label: 'ad_id',
        }, */
    {
        id: 'ad_name',
        numeric: true,
        disablePadding: false,
        label: 'ad_name',
    },
    {
        id: 'reach',
        numeric: true,
        disablePadding: false,
        label: 'reach',
    },
    {
        id: 'impressions',
        numeric: true,
        disablePadding: false,
        label: 'impressions',
    },
    {
        id: 'spend',
        numeric: true,
        disablePadding: false,
        label: 'spend',
    },
    {
        id: 'clicks',
        numeric: true,
        disablePadding: false,
        label: 'clicks',
    },
    {
        id: 'ctr',
        numeric: true,
        disablePadding: false,
        label: 'ctr',
    },
    {
        id: 'cpm',
        numeric: true,
        disablePadding: false,
        label: 'cpm',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    FB Data
                </Typography>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ArielTable() {

    const { arielData } = useContextState()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    function createData(campaign_id, campaign_name, adset_name, ad_id, ad_name, reach, impressions, spend, clicks, ctr, cpm) {
        return {
            campaign_id,
            campaign_name,
            adset_name,
            ad_id,
            ad_name,
            reach,
            impressions,
            spend,
            clicks,
            ctr,
            cpm
        };
    }

    const rows = []

    arielData?.data?.map((campaign) => rows.push(createData(campaign.campaign_id, campaign.campaign_name, campaign.adset_name, campaign.ad_id, campaign.ad_name, Number(campaign.reach), Number(campaign.impressions), Number(campaign.spend), Number(campaign.clicks), Number(campaign.ctr).toFixed(2), Number(campaign.cpm).toFixed(2))))


    //count totals
    var reach = 0
    var impressions = 0
    var spend = 0
    var clicks = 0
    var cpm = 0

    arielData?.data?.map((c, i) => {
        reach += Number(c.reach)
        impressions += Number(c.impressions)
        spend += Number(c.spend)
        clicks += Number(c.clicks)
        cpm += Number(c.cpm)
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.ad_id}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                            >
                                                {row.campaign_name}
                                            </TableCell>
                                            {/* <TableCell align="right">{row.campaign_name}</TableCell> */}
                                            <TableCell align="right">{row.adset_name}</TableCell>
                                            {/* <TableCell align="right">{row.ad_id}</TableCell> */}
                                            <TableCell align="right">{row.ad_name}</TableCell>
                                            <TableCell align="right">{row.reach}</TableCell>
                                            <TableCell align="right">{row.impressions}</TableCell>
                                            <TableCell align="right">${row.spend}</TableCell>
                                            <TableCell align="right">{row.clicks}</TableCell>
                                            <TableCell align="right">{row.ctr}%</TableCell>
                                            <TableCell align="right">${(row.spend/(row.impressions/1000)).toFixed(2)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {/* total */}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right"><b>Total</b></TableCell>
                                <TableCell align="right"><b>{reach}</b></TableCell>
                                <TableCell align="right"><b>{impressions}</b></TableCell>
                                <TableCell align="right"><b>${spend.toFixed(2)}</b></TableCell>
                                <TableCell align="right"><b>{clicks}</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
