import React from 'react'
import { useContextState } from '../../../ContextAPI'
import excel_positions from '../../../assets/excel_positions.gif'
import moneypuck from '../../../assets/moneypuck.gif'
import excel_google from '../../../assets/excel_google.gif'
import sheets_open from '../../../assets/sheets_open.gif'
import sheets_trade from '../../../assets/sheets_trade.gif'

const Projects = () => {

    const {  } = useContextState()

    return (
        <div className='w-full mt-14'>
            <p className='font-bold text-center text-4xl mb-20'>Other Projects And Tools</p>

            <div className='md:flex flex-row items-center justify-between md:mb-32 mb-10'>
                <p className='md:text-3xl text-lg font-bold md:pr-10 pr-0 md:w-5/12 w-full md:text-left text-center md:mb-0 mb-4'>Trading dashboard with real time price feed API (Apps Script)</p>
                <img src={sheets_trade} alt='' className='border-4 border-slate-300 rounded-xl md:w-7/12 w-full' />
            </div>

            <div className='md:flex flex-row md:flex-row-reverse items-center justify-between md:mb-32 mb-10'>
                <p className='md:text-3xl text-lg font-bold md:text-right text-center md:pl-10 pl-0 md:w-5/12 w-full md:mb-0 mb-4'>Custom Web Data Scraper to csv (Python)</p>
                <img src={moneypuck} alt='' className='border-4 border-slate-200 rounded-xl md:w-7/12 w-full' />
            </div>

            <div className='md:flex flex-row items-center justify-between md:mb-32 mb-10'>
                <p className='md:text-3xl text-lg font-bold md:pr-10 pr-0 md:w-5/12 w-full md:text-left text-center md:mb-0 mb-4'>Web Data Scraper to Google Sheets (Python)</p>
                <img src={sheets_open} alt='' className='border-4 border-slate-200 rounded-xl md:w-7/12 w-full' />
            </div>


            <div className='md:flex flex-row md:flex-row-reverse items-center justify-between md:mb-32 mb-10'>
                <p className='md:text-3xl text-lg font-bold md:text-right text-center md:pl-10 pl-0 md:w-5/12 w-full md:mb-0 mb-4'>Excel and Google Sheets Connection (VBA, Apps Script)</p>
                <img src={excel_google} alt='' className='border-4 border-slate-200 rounded-xl md:w-7/12 w-full' />
            </div>

            <div className='md:flex flex-row items-center justify-between md:mb-32 mb-10'>
                <p className='md:text-3xl text-lg font-bold md:pr-10 pr-0 md:w-5/12 w-full md:text-left text-center md:mb-0 mb-4'>Trading Positions Aggregator (VBA)</p>
                <img src={excel_positions} alt='' className='border-4 border-slate-200 rounded-xl md:w-7/12 w-full' />
            </div>
        </div>
    )
}

export default Projects