import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useContextState } from '../ContextAPI'
import { BiHome, BiInfoCircle, BiUser } from 'react-icons/bi'
import { CgWebsite } from 'react-icons/cg'
import { MdOutlineCancel, MdCampaign, MdLogin, MdSportsBasketball } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi'
import { CgProfile } from 'react-icons/cg'
import { BsFillBarChartFill } from 'react-icons/bs'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';



const Sidebar = () => {
    const { activeMenu, setActiveMenu, screenSize, setScreenSize, color, user } = useContextState()

    const activeLink = 'flex flex-row items-center h-10 px-3 rounded-lg text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-700 text-sm'
    const normalLink = 'flex flex-row items-center h-10 px-3 rounded-lg text-gray-300 hover:bg-gray-100 hover:text-gray-700 text-sm'

    const ItemBox = ({ item, icon, link, badge }) => {
        return (
            <NavLink to={`/${link}`} onClick={() => handleCloseSideBar()} className={({ isActive }) => isActive ? activeLink : normalLink}>
                <span className="flex items-center justify-center text-gray-400">
                    {icon}
                </span>
                <span className="ml-3" >{item}</span>
                <span className='md:block hidden items-center ml-3'>{badge}</span>
            </NavLink>

        )
    }



    const handleCloseSideBar = () => {
        if (activeMenu && screenSize <= 900) {
            setActiveMenu(false)
        }
    }


    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false)
        } else {
            setActiveMenu(true)
        }
    }, [screenSize])



    return (

        <div className="flex-row h-screen text-gray-800 md:overflow-hidden overflow-auto md:hover:overflow-auto" style={{ backgroundColor: color }}>
            {activeMenu && (
                <aside
                    className="w-72 transform translate-x-0 transition-transform duration-150 ease-in px-2"
                >
                    {/* logo */}
                    <div className="sidebar-header flex items-center justify-center py-4">
                        <div className="inline-flex items-center">
                            <a href="/" className="inline-flex flex-row items-center">
                                <span className="items-center gap-3 mt-4 flex text-xl font-extrabold leading-10 text-gray-100 uppercase sm:ml-0 ml-7">GC Consultancy</span>
                            </a>
                            <TooltipComponent content='' position='BottomCenter'>
                                <button type='button' onClick={() => { setActiveMenu((prevActiveMenu) => !prevActiveMenu) }} className='text-2xl rounded-full p-3 hover:bg-light-gray block md:hidden'>
                                    <MdOutlineCancel color='white' />
                                </button>
                            </TooltipComponent>
                        </div>
                    </div>
                    <div className="px-3 py-0">
                        <ul className="flex flex-col w-full">

                            {/* <li class="my-px">
                                <a
                                    href="#"
                                    class="flex flex-row items-center h-10 px-3 rounded-lg text-gray-700 bg-gray-100"
                                >
                                    <span class="flex items-center justify-center text-lg text-gray-400">
                                        <BiHome className='text-2xl'/>
                                    </span>
                                    <span className="ml-3 font-bold">Home</span>
                                </a>
                            </li> */}

                           {/*  <li className="my-px">
                                <span className="flex justify-start font-medium text-sm text-white  px-4 mb-2 uppercase">GC Consultancy</span>
                            </li> */}

                            <li className="my-px">
                                <ItemBox item='Home' link='' icon={<BiHome className='text-2xl' />} />
                            </li>
                            <li className="my-px">
                                <ItemBox item='About' link='about' icon={<BiInfoCircle className='text-2xl' />} />
                            </li>
                            <li className="my-px">
                                {/* <ItemBox item='Portfolio (Sports)' link='portfolio_sports' icon={<MdSportsBasketball className='text-2xl' />} /> */}
                                <NavLink to={`/portfolio_sports`} onClick={() => handleCloseSideBar()} className={({ isActive }) => isActive ? activeLink : normalLink}>
                                    <span className="flex items-center justify-center text-lg text-gray-400">
                                        <MdSportsBasketball className='text-2xl' />
                                    </span>
                                    <span className="ml-3" >
                                        {/*  ping */}
                                        <div className="relative">
                                            <div className="absolute top-0 right-0 -mr-3 -mt-0 w-2 h-2 rounded-full bg-amber-300 animate-ping"></div>
                                            <div className="absolute top-0 right-0 -mr-3 -mt-0 w-2 h-2 rounded-full bg-amber-300"></div>
                                        </div>
                                        {/*  ping */}
                                        Portfolio (Sports)
                                    </span>
                                </NavLink>
                            </li>
                            <li className="my-px">
                                <ItemBox item='Portfolio (Websites)' link='portfolio' icon={<CgWebsite className='text-2xl' />} />
                            </li>

                            {/* hide if user is logged in */}

                            {/* demo */}
                            <li className="my-px">
                                <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">Clients</span>
                            </li>
                            {/* demo */}

                            {!user
                                ? (
                                    <li className="my-px">
                                        <ItemBox item='Client Login' link='clientlogin' icon={<MdLogin className='text-2xl' />} />
                                    </li>
                                ) :
                                (
                                    < div >
                                        {user.email === process.env.REACT_APP_CLIENT1 ? (
                                            <div>
                                                <li className="my-px">
                                                    <ItemBox item='Gilbert' link='gilbert' icon={<BiUser className='text-2xl' />} />
                                                </li>
                                                <li className="my-px">
                                                    <ItemBox item='Ariel' link='ariel' icon={<BiUser className='text-2xl' />} />
                                                </li>
                                                <li className="my-px">
                                                    <ItemBox item='Marvin' link='marvin' icon={<BiUser className='text-2xl' />} />
                                                </li>
                                                <li className="my-px">
                                                    <ItemBox item='Rory' link='rory' icon={<BiUser className='text-2xl' />} />
                                                </li>
                                            </div>
                                        ) : <></>}

                                        {user.email === process.env.REACT_APP_CLIENT2 ? (
                                            <div>
                                                <li className="my-px">
                                                    <ItemBox item='Ariel' link='ariel' icon={<BiUser className='text-2xl' />} />
                                                </li>
                                            </div>
                                        ) : <></>}

                                        {user.email === process.env.REACT_APP_CLIENT3 ? (
                                            <li className="my-px">
                                                <ItemBox item='Marvin' link='marvin' icon={<BiUser className='text-2xl' />} />
                                            </li>
                                        ) : <></>}

                                        {user.email === process.env.REACT_APP_CLIENT4 ? (
                                            <li className="my-px">
                                                <ItemBox item='Rory' link='rory' icon={<BiUser className='text-2xl' />} />
                                            </li>
                                        ) : <></>}

                                    </div>
                                )}



                            {/* demo */}
                            <li className="my-px">
                                <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">Dashboard Demo</span>
                            </li>
                            {/* demo */}

                            <li className="my-px">
                                {/* <ItemBox item='Dashboard' link='dashboard' icon={<BsFillBarChartFill className='text-2xl' />} badge={<Badge />} /> */}

                                <NavLink to={`/dashboard`} onClick={() => handleCloseSideBar()} className={({ isActive }) => isActive ? activeLink : normalLink}>
                                    <span className="flex items-center justify-center text-lg text-gray-400">
                                        <BsFillBarChartFill className='text-2xl' />
                                    </span>
                                    <span className="ml-3" >
                                        {/*  ping */}
                                        <div className="relative">
                                            <div className="absolute top-0 right-0 -mr-3 -mt-0 w-2 h-2 rounded-full bg-emerald-300 animate-ping"></div>
                                            <div className="absolute top-0 right-0 -mr-3 -mt-0 w-2 h-2 rounded-full bg-emerald-300"></div>
                                        </div>
                                        {/*  ping */}
                                        Dashboard
                                    </span>
                                </NavLink>
                            </li>


                            <li className="my-px">
                                <ItemBox item='Clients' link='clients' icon={<CgProfile className='text-2xl' />} />
                            </li>

                            <li className="my-px">
                                <ItemBox item='Campaigns' link='campaigns' icon={<MdCampaign className='text-2xl' />} />
                            </li>

                            {/* other */}
                            <li className="my-px">
                                <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">Other</span>
                            </li>
                            {/* other */}

                            <li className="my-px">
                                <ItemBox item='Settings' link='settings' icon={<FiSettings className='text-2xl' />} />
                            </li>

                            {/* <li class="my-px">
                                <a
                                    href="#"
                                    class="flex flex-row items-center h-10 px-3 rounded-lg text-gray-300 hover:bg-gray-100 hover:text-gray-700"
                                >
                                    <span class="flex items-center justify-center text-lg text-gray-400">
                                        <svg
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            class="h-6 w-6"
                                        >
                                            <path
                                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                            />
                                        </svg>
                                    </span>
                                    <span class="ml-3">Notifications</span>
                                    <span
                                        class="flex items-center justify-center text-xs text-red-500 font-semibold bg-red-100 h-6 px-2 rounded-full ml-auto"
                                    >10</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </aside >
            )}
            <div className='mt-14 mb-5 mx-4'>
                <p className='text-xs text-white flex-1'>&copy; 2022 GC Consultancy</p>
                <p className='text-xs text-white flex-1'><a className='hover:cursor-pointer'>Privacy Policy</a> | <a className='hover:cursor-pointer'>Terms and Conditions</a> </p>
            </div>
        </div >


    )
}

export default Sidebar