import React, { useEffect, useState } from 'react'
//import { useContextState } from '../../../ContextAPI'
import { AiFillLock } from 'react-icons/ai'


const Tools = () => {

    //const { } = useContextState()

    const [risk, setRisk] = useState(0)
    const [win, setWin] = useState(0)
    const [odds, setOdds] = useState(0)

    useEffect(() => {
        /* document.getElementById('push5').value = pushProb.push5
        document.getElementById('push6').value = pushProb.push6
        document.getElementById('push7').value = pushProb.push7
        document.getElementById('push8').value = pushProb.push8
        document.getElementById('push9').value = pushProb.push9
        document.getElementById('push10').value = pushProb.push10
        document.getElementById('push11').value = pushProb.push11
        document.getElementById('push12').value = pushProb.push12
        document.getElementById('push13').value = pushProb.push13
        document.getElementById('push14').value = pushProb.push14
        document.getElementById('push15').value = pushProb.push15 */

        document.getElementById('push5').value = '*'
        document.getElementById('push6').value = '*'
        document.getElementById('push7').value = '*'
        document.getElementById('push8').value = '*'
        document.getElementById('push9').value = '*'
        document.getElementById('push10').value = '*'
        document.getElementById('push11').value = '*'
        document.getElementById('push12').value = '*'
        document.getElementById('push13').value = '*'
        document.getElementById('push14').value = '*'
        document.getElementById('push15').value = '*'

        document.getElementById('pushNBA1').value = pushProbNBA.push1
        document.getElementById('pushNBA2').value = pushProbNBA.push2
        document.getElementById('pushNBA3').value = pushProbNBA.push3
        document.getElementById('pushNBA4').value = pushProbNBA.push4
        document.getElementById('pushNBA5').value = pushProbNBA.push5
        document.getElementById('pushNBA6').value = pushProbNBA.push6
        document.getElementById('pushNBA7').value = pushProbNBA.push7
        document.getElementById('pushNBA8').value = pushProbNBA.push8
        document.getElementById('pushNBA9').value = pushProbNBA.push9
        document.getElementById('pushNBA10').value = pushProbNBA.push10
        document.getElementById('pushNBA11').value = pushProbNBA.push11
        document.getElementById('pushNBA12').value = pushProbNBA.push12
        document.getElementById('pushNBA13').value = pushProbNBA.push13
        document.getElementById('pushNBA14').value = pushProbNBA.push14
        document.getElementById('pushNBA15').value = pushProbNBA.push15
        document.getElementById('pushNBA16').value = pushProbNBA.push16
        document.getElementById('pushNBA17').value = pushProbNBA.push17
        document.getElementById('pushNBA18').value = pushProbNBA.push18
        document.getElementById('pushNBA19').value = pushProbNBA.push19

        document.getElementById('mlbLine').value = 8
        document.getElementById('mlbOdds').value = 100
        document.getElementById('fvLine').value = 8.2
        document.getElementById('fvMarketLine').value = 8

        document.getElementById('nbaLine').value = 6
        document.getElementById('nbaOdds').value = 100
        document.getElementById('fvMarketLineNBA').value = 6
        document.getElementById('fvLineNBA').value = 6.2
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getResult = () => {

        var convertedOdds = odds


        //if US odds positive
        if (odds > 99) {
            convertedOdds = odds / 100 + 1
        }
        //if US odds negative
        if (odds < 0) {
            convertedOdds = -100 / odds + 1
        }

        if (risk !== 0 && odds !== 0 && win == 0) {
            const result = risk * convertedOdds - risk
            document.getElementById('win_id').value = result.toFixed(2)
            //setWin(result)

        }

        if (risk == 0 && odds !== 0 && win !== 0) {
            const inverse = 1 - 1 / convertedOdds
            const result = win * (1 / inverse) - win
            document.getElementById('risk_id').value = result.toFixed(2)
            //setRisk(result)
        }

        if (risk !== 0 && odds == 0 && win !== 0) {
            const result = win / risk + 1
            document.getElementById('odds_id').value = result.toFixed(2)
            //setOdds(result)

        }

        if (risk !== 0 && odds !== 0 && win !== 0) {
            //setShowError(true)
            //clearInputs()
        }
    }

    const clearInputs = () => {
        setRisk(0)
        setOdds(0)
        setWin(0)
        document.getElementById('risk_id').value = ""
        document.getElementById('odds_id').value = ""
        document.getElementById('win_id').value = ""
    }


    const clearInputsSoccer = () => {
        setHdp(0)
        setScore1(0)
        setScore2(0)
        document.getElementById('hdp_id').value = ""
        document.getElementById('score_id1').value = ""
        document.getElementById('score_id2').value = ""
        document.getElementById('grade_soccer').value = ""
    }



    const [side, setSide] = useState("Side1")
    const [hdp, setHdp] = useState(0)
    const [score1, setScore1] = useState(0)
    const [score2, setScore2] = useState(0)

    const gradeResult = () => {

        var grade2 = ''
        //var totalGame = Number(score1) + Number(score2)

        //grade AH
        if (Number.isInteger(Number(hdp) * 2)) {
            //grade normal
            if (side === "Side1") {
                if (Number(score1) + Number(hdp) > Number(score2)) {
                    grade2 = "Win"
                } else if (Number(score1) + Number(hdp) < Number(score2)) {
                    grade2 = "Lose"
                } else if (Number(score1) + Number(hdp) === Number(score2)) {
                    grade2 = "Push"
                }
            } else if (side === "Side2") {
                if (Number(score2) + Number(hdp) > Number(score1)) {
                    grade2 = "Win"
                } else if (Number(score2) + Number(hdp) < Number(score1)) {
                    grade2 = "Lose"
                } else if (Number(score2) + Number(hdp) === Number(score1)) {
                    grade2 = "Push"
                }
            } else if (side === "Over") {
                var totalGame = Number(score1) + Number(score2)
                if (Number(hdp) > totalGame) {
                    grade2 = "Lose"
                } else if (Number(hdp) < totalGame) {
                    grade2 = "Win"
                } else if (Number(hdp) === totalGame) {
                    grade2 = "Push"
                }
            } else if (side === "Under") {

                if (Number(hdp) > totalGame) {
                    grade2 = "Win"
                } else if (Number(hdp) < totalGame) {
                    grade2 = "Lose"
                } else if (Number(hdp) === totalGame) {
                    grade2 = "Push"
                }
            }
        } else {
            var hdp1 = Number(hdp) - 0.25
            var hdp2 = Number(hdp) + 0.25
            if (side === "Side1") {
                if (Number(score1) + hdp1 > score2 && Number(score1) + hdp2 > score2) {
                    grade2 = "Win"
                } else if (Number(score1) + hdp1 === Number(score2) && Number(score1) + hdp2 > Number(score2)) {
                    grade2 = "Win Half"
                } else if (Number(score1) + hdp1 < Number(score2) && Number(score1) + hdp2 === Number(score2)) {
                    grade2 = "Lose Half"
                } else if (Number(score1) + hdp1 < Number(score2) && Number(score1) + hdp2 < Number(score2)) {
                    grade2 = "Lose"
                }
            } else if (side === "Side2") {
                if (Number(score2) + hdp1 > Number(score1) && Number(score2) + hdp2 > score1) {
                    grade2 = "Win"
                } else if (Number(score2) + hdp1 === Number(score1) && Number(score2) + hdp2 > Number(score1)) {
                    grade2 = "Win Half"
                } else if (Number(score2) + hdp1 < Number(score1) && Number(score2) + hdp2 === Number(score1)) {
                    grade2 = "Lose Half"
                } else if (Number(score2) + hdp1 < Number(score1) && Number(score2) + hdp2 < Number(score1)) {
                    grade2 = "Lose"
                }
            } else if (side === "Over") {
                if (hdp1 < Number(totalGame) && hdp2 < Number(totalGame)) {
                    grade2 = "Win"
                } else if (hdp1 < Number(totalGame) && hdp2 === Number(totalGame)) {
                    grade2 = "Win Half"
                } else if (hdp1 === Number(totalGame) && hdp2 > Number(totalGame)) {
                    grade2 = "Lose Half"
                } else if (hdp1 > Number(totalGame) && hdp2 > Number(totalGame)) {
                    grade2 = "Lose"
                }
            } else if (side === "Under") {
                if (hdp1 < Number(totalGame) && hdp2 < Number(totalGame)) {
                    grade2 = "Lose"
                } else if (hdp1 < Number(totalGame) && hdp2 === Number(totalGame)) {
                    grade2 = "Lose Half"
                } else if (hdp1 === Number(totalGame) && hdp2 > Number(totalGame)) {
                    grade2 = "Win Half"
                } else if (hdp1 > Number(totalGame) && hdp2 > Number(totalGame)) {
                    grade2 = "Win"
                }
            }
        }
        document.getElementById('grade_soccer').value = grade2

    }

    const [mlbBet, setMlbBet] = useState("Over")
    const [mlbLine, setMlbLine] = useState(8)
    const [mlbLine2, setMlbLine2] = useState(8)
    const [mlbFVLine, setMlbFVLine] = useState(8.2)
    const [mlbOdds, setMlbOdds] = useState(100)
    const [altUS, setAltUS] = useState(118.01)
    const [altUS2, setAltUS2] = useState(-118.01)
    const [pushProb, setPushProb] = useState({
        push5: 12.28,
        push6: 8.26,
        push7: 14.9,
        push8: 8.26,
        push9: 10.32,
        push10: 8.26,
        push11: 10.32,
        push12: 8.26,
        push13: 9.67,
        push14: 8.26,
        push15: 5.5,
    })
    const [fairValue, setFairValue] = useState(-106.83)

    const getAltLines = () => {
        var pushProbability
        var pushProbability2
        if (Number.isInteger(Number(mlbLine))) { //if main line is whole number
            //console.log('whole number')
            //var pushProbability
            if (Number(mlbLine) === 5) {
                pushProbability = pushProb.push5
            } else if (Number(mlbLine) === 6) {
                pushProbability = pushProb.push6
            } else if (Number(mlbLine) === 7) {
                pushProbability = pushProb.push7
            } else if (Number(mlbLine) === 8) {
                pushProbability = pushProb.push8
            } else if (Number(mlbLine) === 9) {
                pushProbability = pushProb.push9
            } else if (Number(mlbLine) === 10) {
                pushProbability = pushProb.push10
            } else if (Number(mlbLine) === 11) {
                pushProbability = pushProb.push11
            } else if (Number(mlbLine) === 12) {
                pushProbability = pushProb.push12
            } else if (Number(mlbLine) === 13) {
                pushProbability = pushProb.push13
            } else if (Number(mlbLine) === 14) {
                pushProbability = pushProb.push14
            } else if (Number(mlbLine) === 15) {
                pushProbability = pushProb.push15
            } else {
                pushProbability = 0
            }


            var decOdd
            if (Number(mlbOdds) < 100) {
                decOdd = -100 / Number(mlbOdds) + 1
            } else if (Number(mlbOdds) >= 100) {
                decOdd = Number(mlbOdds) / 100 + 1
            }
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb - (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb + (pushProbability / 100 / 2) //push % here

            var altWinDec = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb2)
            var altWinUS2

            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            if (mlbBet === "Over") {
                setAltUS(altWinUS)
                setAltUS2(altWinUS2)
            } else if (mlbBet === "Under") {
                setAltUS(altWinUS2)
                setAltUS2(altWinUS)
            }

        } else {


            if (Number(mlbLine) === 5.5) {
                pushProbability = pushProb.push5
                pushProbability2 = pushProb.push6
            } else if (Number(mlbLine) === 6.5) {
                pushProbability = pushProb.push6
                pushProbability2 = pushProb.push7
            } else if (Number(mlbLine) === 7.5) {
                pushProbability = pushProb.push7
                pushProbability2 = pushProb.push8
            } else if (Number(mlbLine) === 8.5) {
                pushProbability = pushProb.push8
                pushProbability2 = pushProb.push9
            } else if (Number(mlbLine) === 9.5) {
                pushProbability = pushProb.push9
                pushProbability2 = pushProb.push10
            } else if (Number(mlbLine) === 10.5) {
                pushProbability = pushProb.push10
                pushProbability2 = pushProb.push11
            } else if (Number(mlbLine) === 11.5) {
                pushProbability = pushProb.push11
                pushProbability2 = pushProb.push12
            } else if (Number(mlbLine) === 12.5) {
                pushProbability = pushProb.push12
                pushProbability2 = pushProb.push13
            } else if (Number(mlbLine) === 13.5) {
                pushProbability = pushProb.push13
                pushProbability2 = pushProb.push14
            } else if (Number(mlbLine) === 14.5) {
                pushProbability = pushProb.push14
                pushProbability2 = pushProb.push15
            } else if (Number(mlbLine) === 15.5) {
                pushProbability = pushProb.push15
            } else {
                pushProbability = 0
            }


            var decOdd
            if (Number(mlbOdds) < 100) {
                decOdd = -100 / Number(mlbOdds) + 1
            } else if (Number(mlbOdds) >= 100) {
                decOdd = Number(mlbOdds) / 100 + 1
            }
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb + (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb - (pushProbability2 / 100 / 2) //push % here


            var altWinDec = 1 / altWinProb2
            //var altWinDec2 = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb)
            var altWinUS2


            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            if (mlbBet === "Over") {
                setAltUS(altWinUS)
                setAltUS2(altWinUS2)
            } else if (mlbBet === "Under") {
                setAltUS(altWinUS2)
                setAltUS2(altWinUS)
            }
        }

    }

    const getFVLine = () => {
        if (Number.isInteger(Number(mlbLine2))) {
            var pushProbability
            if (Number(mlbLine2) === 5) {
                pushProbability = pushProb.push5
            } else if (Number(mlbLine2) === 6) {
                pushProbability = pushProb.push6
            } else if (Number(mlbLine2) === 7) {
                pushProbability = pushProb.push7
            } else if (Number(mlbLine2) === 8) {
                pushProbability = pushProb.push8
            } else if (Number(mlbLine2) === 9) {
                pushProbability = pushProb.push9
            } else if (Number(mlbLine2) === 10) {
                pushProbability = pushProb.push10
            } else if (Number(mlbLine2) === 11) {
                pushProbability = pushProb.push11
            } else if (Number(mlbLine2) === 12) {
                pushProbability = pushProb.push12
            } else if (Number(mlbLine2) === 13) {
                pushProbability = pushProb.push13
            } else if (Number(mlbLine2) === 14) {
                pushProbability = pushProb.push14
            } else if (Number(mlbLine2) === 15) {
                pushProbability = pushProb.push15
            } else {
                pushProbability = 0
            }

            var decOdd = 2

            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb - (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb + (pushProbability / 100 / 2) //push % here

            var altWinDec = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb2)
            var altWinUS2

            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            var lineDiff = Number(winProb) - Number(altWinProb)
            var percentDiff = 1 - (Number(mlbLine2) + 0.5 - Number(mlbFVLine)) / 0.5
            var netDiff = lineDiff * percentDiff

            var fvWinProb = 0.5 - netDiff
            var fvDec = 1 / (1 - fvWinProb)
            var fvUS
            if (fvDec >= 2) {
                fvUS = (fvDec - 1) * 100
            } else if (altWinDec2 < 2) {
                fvUS = -1 / (fvDec - 1) * 100
            }

            setFairValue(fvUS.toFixed(2))

            document.getElementById('mlbFVLine').value = mlbLine2 + " " + fvUS.toFixed(2)
            //setMlbOdds(fvUS.toFixed(2))
        } else {

            var pushProbability
            var pushProbability2
            if (Number(mlbLine2) === 5.5) {
                pushProbability = pushProb.push5
                pushProbability2 = pushProb.push6
            } else if (Number(mlbLine2) === 6.5) {
                pushProbability = pushProb.push6
                pushProbability2 = pushProb.push7
            } else if (Number(mlbLine2) === 7.5) {
                pushProbability = pushProb.push7
                pushProbability2 = pushProb.push8
            } else if (Number(mlbLine2) === 8.5) {
                pushProbability = pushProb.push8
                pushProbability2 = pushProb.push9
            } else if (Number(mlbLine2) === 9.5) {
                pushProbability = pushProb.push9
                pushProbability2 = pushProb.push10
            } else if (Number(mlbLine2) === 10.5) {
                pushProbability = pushProb.push10
                pushProbability2 = pushProb.push11
            } else if (Number(mlbLine2) === 11.5) {
                pushProbability = pushProb.push11
                pushProbability2 = pushProb.push12
            } else if (Number(mlbLine2) === 12.5) {
                pushProbability = pushProb.push12
                pushProbability2 = pushProb.push13
            } else if (Number(mlbLine2) === 13.5) {
                pushProbability = pushProb.push13
                pushProbability2 = pushProb.push14
            } else if (Number(mlbLine2) === 14.5) {
                pushProbability = pushProb.push14
                pushProbability2 = pushProb.push15
            } else if (Number(mlbLine2) === 15.5) {
                pushProbability = pushProb.push15
            } else {
                pushProbability = 0
            }


            var decOdd = 2
            /*  if (Number(mlbOdds) < 100) {
                 decOdd = -100 / Number(mlbOdds) + 1
             } else if (Number(mlbOdds) >= 100) {
                 decOdd = Number(mlbOdds) / 100 + 1
             } */
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb + (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb - (pushProbability2 / 100 / 2) //push % here


            var altWinDec = 1 / altWinProb2
            //var altWinDec2 = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb)
            var altWinUS2


            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            if (Number(mlbFVLine) <= Number(mlbLine2)) {
                var lineDiff = Number(winProb) - Number(altWinProb)
                var percentDiff = 1 - (Number(mlbLine2) + 0.5 - Number(mlbFVLine)) / 0.5
                var netDiff = lineDiff * percentDiff

                var fvWinProb = 0.5 - netDiff
                var fvDec = 1 / (fvWinProb)
                var fvUS
                if (fvDec >= 2) {
                    fvUS = (fvDec - 1) * 100
                } else if (altWinDec2 < 2) {
                    fvUS = -1 / (fvDec - 1) * 100
                }
            } else if (Number(mlbFVLine) > Number(mlbLine2)) {
                var lineDiff = Number(winProb) - Number(altWinProb2)
                var percentDiff = 1 - (Number(mlbLine2) + 0.5 - Number(mlbFVLine)) / 0.5
                var netDiff = lineDiff * percentDiff

                var fvWinProb = 0.5 - netDiff
                var fvDec = 1 / (1 - fvWinProb)
                var fvUS
                if (fvDec >= 2) {
                    fvUS = (fvDec - 1) * 100
                } else if (altWinDec2 < 2) {
                    fvUS = -1 / (fvDec - 1) * 100
                }
            }

            setFairValue(fvUS.toFixed(2))

            document.getElementById('mlbFVLine').value = mlbLine2 + " " + fvUS.toFixed(2)
        }

    }

    //nba ps alt
    const [pushProbNBA, setPushProbNBA] = useState({
        push1: 2.27,
        push2: 4.03,
        push3: 3.83,
        push4: 3.48,
        push5: 4.37,
        push6: 4.16,
        push7: 4.11,
        push8: 4.2,
        push9: 4.76,
        push10: 4.16,
        push11: 3.87,
        push12: 3.51,
        push13: 3.85,
        push14: 3.49,
        push15: 3.3,
        push16: 4.99,
        push17: 2.99,
        push18: 3.41,
        push19: 3.33,
    })
    const [nbaBet, setNbaBet] = useState("Home")
    const [nbaLine, setNbaLine] = useState(5)
    const [nbaOdds, setNbaOdds] = useState(100)
    //const [mlbLine2, setMlbLine2] = useState(8)
    //const [mlbFVLine, setMlbFVLine] = useState(8.2)
    const [altUSNBA, setAltUSNBA] = useState(109.14)
    const [altUSNBA2, setAltUSNBA2] = useState(-109.14)
    const [nbaLine2, setNbaLine2] = useState(6)
    const [nbaFVLine, setNbaFVLine] = useState(6.2)
    const [fairValueNBA, setFairValueNBA] = useState(-103.38)


    const getAltLinesNBA = () => {
        if (Number.isInteger(Number(nbaLine))) { //if main line is whole number
            //console.log('whole number')
            var pushProbability
            if (Math.abs(Number(nbaLine)) === 1) {
                pushProbability = pushProbNBA.push1
            } else if (Math.abs(Number(nbaLine)) === 2) {
                pushProbability = pushProbNBA.push2
            } else if (Math.abs(Number(nbaLine)) === 3) {
                pushProbability = pushProbNBA.push3
            } else if (Math.abs(Number(nbaLine)) === 4) {
                pushProbability = pushProbNBA.push4
            } else if (Math.abs(Number(nbaLine)) === 5) {
                pushProbability = pushProbNBA.push5
            } else if (Math.abs(Number(nbaLine)) === 6) {
                pushProbability = pushProbNBA.push6
            } else if (Math.abs(Number(nbaLine)) === 7) {
                pushProbability = pushProbNBA.push7
            } else if (Math.abs(Number(nbaLine)) === 8) {
                pushProbability = pushProbNBA.push8
            } else if (Math.abs(Number(nbaLine)) === 9) {
                pushProbability = pushProbNBA.push9
            } else if (Math.abs(Number(nbaLine)) === 10) {
                pushProbability = pushProbNBA.push10
            } else if (Math.abs(Number(nbaLine)) === 11) {
                pushProbability = pushProbNBA.push11
            } else if (Math.abs(Number(nbaLine)) === 12) {
                pushProbability = pushProbNBA.push12
            } else if (Math.abs(Number(nbaLine)) === 13) {
                pushProbability = pushProbNBA.push13
            } else if (Math.abs(Number(nbaLine)) === 14) {
                pushProbability = pushProbNBA.push14
            } else if (Math.abs(Number(nbaLine)) === 15) {
                pushProbability = pushProbNBA.push15
            } else if (Math.abs(Number(nbaLine)) === 16) {
                pushProbability = pushProbNBA.push16
            } else if (Math.abs(Number(nbaLine)) === 17) {
                pushProbability = pushProbNBA.push17
            } else if (Math.abs(Number(nbaLine)) === 18) {
                pushProbability = pushProbNBA.push18
            } else if (Math.abs(Number(nbaLine)) === 19) {
                pushProbability = pushProbNBA.push19
            } else {
                pushProbability = 0
            }


            var decOdd
            if (Number(nbaOdds) < 100) {
                decOdd = -100 / Number(nbaOdds) + 1
            } else if (Number(nbaOdds) >= 100) {
                decOdd = Number(nbaOdds) / 100 + 1
            }
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb - (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb + (pushProbability / 100 / 2) //push % here

            var altWinDec = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb2)
            var altWinUS2

            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }


            if (Number(nbaLine) >= 0) {
                setAltUSNBA(altWinUS)
                setAltUSNBA2(altWinUS2)
            } else if (Number(nbaLine) < 0) {
                setAltUSNBA(altWinUS2)
                setAltUSNBA2(altWinUS)
            }

            //setAltUSNBA(altWinUS)
            //setAltUSNBA2(altWinUS2)

        } else {

            var pushProbability
            var pushProbability2
            if (Math.abs(Number(nbaLine)) === 1.5) {
                pushProbability = pushProbNBA.push1
                pushProbability2 = pushProbNBA.push2
            } else if (Math.abs(Number(nbaLine)) === 2.5) {
                pushProbability = pushProbNBA.push2
                pushProbability2 = pushProbNBA.push3
            } else if (Math.abs(Number(nbaLine)) === 3.5) {
                pushProbability = pushProbNBA.push3
                pushProbability2 = pushProbNBA.push4
            } else if (Math.abs(Number(nbaLine)) === 4.5) {
                pushProbability = pushProbNBA.push4
                pushProbability2 = pushProbNBA.push5
            } else if (Math.abs(Number(nbaLine)) === 5.5) {
                pushProbability = pushProbNBA.push5
                pushProbability2 = pushProbNBA.push6
            } else if (Math.abs(Number(nbaLine)) === 6.5) {
                pushProbability = pushProbNBA.push6
                pushProbability2 = pushProbNBA.push7
            } else if (Math.abs(Number(nbaLine)) === 7.5) {
                pushProbability = pushProbNBA.push7
                pushProbability2 = pushProbNBA.push8
            } else if (Math.abs(Number(nbaLine)) === 8.5) {
                pushProbability = pushProbNBA.push8
                pushProbability2 = pushProbNBA.push9
            } else if (Math.abs(Number(nbaLine)) === 9.5) {
                pushProbability = pushProbNBA.push9
                pushProbability2 = pushProbNBA.push10
            } else if (Math.abs(Number(nbaLine)) === 10.5) {
                pushProbability = pushProbNBA.push10
                pushProbability2 = pushProbNBA.push11
            } else if (Math.abs(Number(nbaLine)) === 11.5) {
                pushProbability = pushProbNBA.push11
                pushProbability2 = pushProbNBA.push12
            } else if (Math.abs(Number(nbaLine)) === 12.5) {
                pushProbability = pushProbNBA.push12
                pushProbability2 = pushProbNBA.push13
            } else if (Math.abs(Number(nbaLine)) === 13.5) {
                pushProbability = pushProbNBA.push13
                pushProbability2 = pushProbNBA.push14
            } else if (Math.abs(Number(nbaLine)) === 14.5) {
                pushProbability = pushProbNBA.push14
                pushProbability2 = pushProbNBA.push15
            } else if (Math.abs(Number(nbaLine)) === 15.5) {
                pushProbability = pushProbNBA.push15
                pushProbability2 = pushProbNBA.push16
            } else if (Math.abs(Number(nbaLine)) === 16.5) {
                pushProbability = pushProbNBA.push16
                pushProbability2 = pushProbNBA.push17
            } else if (Math.abs(Number(nbaLine)) === 17.5) {
                pushProbability = pushProbNBA.push17
                pushProbability2 = pushProbNBA.push18
            } else if (Math.abs(Number(nbaLine)) === 18.5) {
                pushProbability = pushProbNBA.push18
                pushProbability2 = pushProbNBA.push19
            } else if (Math.abs(Number(nbaLine)) === 19.5) {
                pushProbability = pushProbNBA.push19
            } else {
                pushProbability = 0
            }


            var decOdd
            if (Number(nbaOdds) < 100) {
                decOdd = -100 / Number(nbaOdds) + 1
            } else if (Number(nbaOdds) >= 100) {
                decOdd = Number(nbaOdds) / 100 + 1
            }
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb + (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb - (pushProbability2 / 100 / 2) //push % here


            var altWinDec = 1 / altWinProb2
            //var altWinDec2 = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb)
            var altWinUS2


            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }


            if (Number(nbaLine) >= 0) {
                setAltUSNBA(altWinUS)
                setAltUSNBA2(altWinUS2)
            } else if (Number(nbaLine) < 0) {
                setAltUSNBA(altWinUS2)
                setAltUSNBA2(altWinUS)
            }

        }

    }


    const getFVLineNBA = () => {
        if (Number.isInteger(Number(nbaLine2))) {
            var pushProbability
            if (Math.abs(Number(nbaLine2)) === 1) {
                pushProbability = pushProbNBA.push1
            } else if (Math.abs(Number(nbaLine2)) === 2) {
                pushProbability = pushProbNBA.push2
            } else if (Math.abs(Number(nbaLine2)) === 3) {
                pushProbability = pushProbNBA.push3
            } else if (Math.abs(Number(nbaLine2)) === 4) {
                pushProbability = pushProbNBA.push4
            } else if (Math.abs(Number(nbaLine2)) === 5) {
                pushProbability = pushProbNBA.push5
            } else if (Math.abs(Number(nbaLine2)) === 6) {
                pushProbability = pushProbNBA.push6
            } else if (Math.abs(Number(nbaLine2)) === 7) {
                pushProbability = pushProbNBA.push7
            } else if (Math.abs(Number(nbaLine2)) === 8) {
                pushProbability = pushProbNBA.push8
            } else if (Math.abs(Number(nbaLine2)) === 9) {
                pushProbability = pushProbNBA.push9
            } else if (Math.abs(Number(nbaLine2)) === 10) {
                pushProbability = pushProbNBA.push10
            } else if (Math.abs(Number(nbaLine2)) === 11) {
                pushProbability = pushProbNBA.push11
            } else if (Math.abs(Number(nbaLine2)) === 12) {
                pushProbability = pushProbNBA.push12
            } else if (Math.abs(Number(nbaLine2)) === 13) {
                pushProbability = pushProbNBA.push13
            } else if (Math.abs(Number(nbaLine2)) === 14) {
                pushProbability = pushProbNBA.push14
            } else if (Math.abs(Number(nbaLine2)) === 15) {
                pushProbability = pushProbNBA.push15
            } else if (Math.abs(Number(nbaLine2)) === 16) {
                pushProbability = pushProbNBA.push16
            } else if (Math.abs(Number(nbaLine2)) === 17) {
                pushProbability = pushProbNBA.push17
            } else if (Math.abs(Number(nbaLine2)) === 18) {
                pushProbability = pushProbNBA.push18
            } else if (Math.abs(Number(nbaLine2)) === 19) {
                pushProbability = pushProbNBA.push19
            } else {
                pushProbability = 0
            }

            var decOdd = 2
            /*  if (Number(mlbOdds) < 100) {
                 decOdd = -100 / Number(mlbOdds) + 1
             } else if (Number(mlbOdds) >= 100) {
                 decOdd = Number(mlbOdds) / 100 + 1
             } */
            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb - (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb + (pushProbability / 100 / 2) //push % here

            var altWinDec = 1 / altWinProb
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb2)
            var altWinUS2

            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            var lineDiff = Number(winProb) - Number(altWinProb)
            var percentDiff = 1 - (Number(nbaLine2) + 0.5 - Number(nbaFVLine)) / 0.5
            var netDiff = lineDiff * percentDiff

            var fvWinProb = 0.5 - netDiff
            var fvDec = 1 / (1 - fvWinProb)
            var fvUS
            if (fvDec >= 2) {
                fvUS = (fvDec - 1) * 100
            } else if (altWinDec2 < 2) {
                fvUS = -1 / (fvDec - 1) * 100
            }

            setFairValueNBA(fvUS.toFixed(2))

            document.getElementById('mlbFVLine').value = nbaLine2 + " " + fvUS.toFixed(2)
            //setMlbOdds(fvUS.toFixed(2))
        } else {

            var pushProbability
            var pushProbability2
            if (Math.abs(Number(nbaLine2)) === 1.5) {
                pushProbability = pushProbNBA.push1
                pushProbability2 = pushProbNBA.push2
            } else if (Math.abs(Number(nbaLine2)) === 2.5) {
                pushProbability = pushProbNBA.push2
                pushProbability2 = pushProbNBA.push3
            } else if (Math.abs(Number(nbaLine2)) === 3.5) {
                pushProbability = pushProbNBA.push3
                pushProbability2 = pushProbNBA.push4
            } else if (Math.abs(Number(nbaLine2)) === 4.5) {
                pushProbability = pushProbNBA.push4
                pushProbability2 = pushProbNBA.push5
            } else if (Math.abs(Number(nbaLine2)) === 5.5) {
                pushProbability = pushProbNBA.push5
                pushProbability2 = pushProbNBA.push6
            } else if (Math.abs(Number(nbaLine2)) === 6.5) {
                pushProbability = pushProbNBA.push6
                pushProbability2 = pushProbNBA.push7
            } else if (Math.abs(Number(nbaLine2)) === 7.5) {
                pushProbability = pushProbNBA.push7
                pushProbability2 = pushProbNBA.push8
            } else if (Math.abs(Number(nbaLine2)) === 8.5) {
                pushProbability = pushProbNBA.push8
                pushProbability2 = pushProbNBA.push9
            } else if (Math.abs(Number(nbaLine2)) === 9.5) {
                pushProbability = pushProbNBA.push9
                pushProbability2 = pushProbNBA.push10
            } else if (Math.abs(Number(nbaLine2)) === 10.5) {
                pushProbability = pushProbNBA.push10
                pushProbability2 = pushProbNBA.push11
            } else if (Math.abs(Number(nbaLine2)) === 11.5) {
                pushProbability = pushProbNBA.push11
                pushProbability2 = pushProbNBA.push12
            } else if (Math.abs(Number(nbaLine2)) === 12.5) {
                pushProbability = pushProbNBA.push12
                pushProbability2 = pushProbNBA.push13
            } else if (Math.abs(Number(nbaLine2)) === 13.5) {
                pushProbability = pushProbNBA.push13
                pushProbability2 = pushProbNBA.push14
            } else if (Math.abs(Number(nbaLine2)) === 14.5) {
                pushProbability = pushProbNBA.push14
                pushProbability2 = pushProbNBA.push15
            } else if (Math.abs(Number(nbaLine2)) === 15.5) {
                pushProbability = pushProbNBA.push15
                pushProbability2 = pushProbNBA.push16
            } else if (Math.abs(Number(nbaLine2)) === 16.5) {
                pushProbability = pushProbNBA.push16
                pushProbability2 = pushProbNBA.push17
            } else if (Math.abs(Number(nbaLine2)) === 17.5) {
                pushProbability = pushProbNBA.push17
                pushProbability2 = pushProbNBA.push18
            } else if (Math.abs(Number(nbaLine2)) === 18.5) {
                pushProbability = pushProbNBA.push18
                pushProbability2 = pushProbNBA.push19
            } else if (Math.abs(Number(nbaLine2)) === 19.5) {
                pushProbability = pushProbNBA.push19
            } else {
                pushProbability = 0
            }


            var decOdd = 2

            var winProb = 1 / Number(decOdd)
            var altWinProb = winProb + (pushProbability / 100 / 2) //push % here
            var altWinProb2 = winProb - (pushProbability2 / 100 / 2) //push % here

            var altWinDec = 1 / altWinProb2
            var altWinUS

            if (altWinDec >= 2) {
                altWinUS = (altWinDec - 1) * 100
            } else if (altWinDec < 2) {
                altWinUS = -1 / (altWinDec - 1) * 100
            }

            var altWinDec2 = 1 / (altWinProb)
            var altWinUS2

            if (altWinDec2 >= 2) {
                altWinUS2 = (altWinDec2 - 1) * 100
            } else if (altWinDec2 < 2) {
                altWinUS2 = -1 / (altWinDec2 - 1) * 100
            }

            if (Number(nbaFVLine) <= Number(nbaLine2)) {
                var lineDiff = Number(winProb) - Number(altWinProb)
                var percentDiff = 1 - (Number(nbaLine2) + 0.5 - Number(nbaFVLine)) / 0.5
                var netDiff = lineDiff * percentDiff

                var fvWinProb = 0.5 - netDiff
                var fvDec = 1 / (fvWinProb)
                var fvUS
                if (fvDec >= 2) {
                    fvUS = (fvDec - 1) * 100
                } else if (altWinDec2 < 2) {
                    fvUS = -1 / (fvDec - 1) * 100
                }
            } else if (Number(nbaFVLine) > Number(nbaLine2)) {
                var lineDiff = Number(winProb) - Number(altWinProb2)
                var percentDiff = 1 - (Number(nbaLine2) + 0.5 - Number(nbaFVLine)) / 0.5
                var netDiff = lineDiff * percentDiff

                var fvWinProb = 0.5 - netDiff
                var fvDec = 1 / (1 - fvWinProb)
                var fvUS
                if (fvDec >= 2) {
                    fvUS = (fvDec - 1) * 100
                } else if (altWinDec2 < 2) {
                    fvUS = -1 / (fvDec - 1) * 100
                }
            }

            setFairValueNBA(fvUS.toFixed(2))
        }

    }

    return (
        <div className='mt-14'>
            <div className='flex-row w-full'>
                {/* NBA total alt */}
                <p className='text-4xl font-bold text-center'>NBA Alt Line Calculators</p>
                <div className='md:flex flex-row justify-between mt-5 overflow-x-auto w-full'>
                    <div className=''>
                        <div className='flex-col'>
                            <div className='mb-2'>
                                <p className='font-bold'>NBA PS Alt Line Odds Calculator</p>
                                <p className='text-sm'>1. Enter a point spread</p>
                                <p className='text-sm'>2. Enter US Odds (default at +100)</p>
                                <p className='text-sm'>3. Hit Run and see the equivalent odds on the alternative lines based on the push probabilities</p>
                            </div>

                            <div className='md:flex flex-row'>
                                {/*  <div>
                                <p className='font-bold'>Bet</p>
                                <p className='text-center w-10'> &nbsp;</p>
                                <select name="side" id="nbaSide" className='border-2 h-10 w-24' onChange={(e) => setNbaBet(e.target.value)}>
                                    <option value="Home">Home</option>
                                    <option value="Away">Away</option>
                                </select>
                                <p> &nbsp;</p>
                            </div> */}
                                <div className='flex'>
                                    <div>
                                        <p className='font-bold'>Lines</p>
                                        <p className='w-10 ml-1'>{Number(nbaLine) - 0.5}</p>
                                        <input type='number' id='nbaLine' className='border-2 h-10 w-24' onChange={(e) => setNbaLine(e.target.value)}></input>
                                        <p className='w-10 ml-1'>{Number(nbaLine) + 0.5}</p>
                                    </div>
                                    <div>
                                        <p className='font-bold'>US Odds</p>
                                        <p id='alt2_us' className='text-center w-10'>{altUSNBA2?.toFixed(2)}</p>
                                        <input type='number' id='nbaOdds' className='border-2 h-10 w-24' onChange={(e) => setNbaOdds(e.target.value)}></input>
                                        <p id='alt_us' className='text-center w-10'>{altUSNBA?.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div>
                                    <p> &nbsp;</p>
                                    <p> &nbsp;</p>
                                    <button className='border-2 h-10 bg-yellow-200 md:w-24 w-full' onClick={() => getAltLinesNBA()}>Run</button>
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex-row mt-20'>
                            <div>
                                <p className='font-bold mb-4'>NBA point spread fair value to market calculator</p>
                            </div>

                            <div className='md:flex flex-row'>
                                <div>
                                    <p className='text-sm'>Market Line</p>
                                    <input type='number' id='fvMarketLineNBA' className='border-2 h-10 md:w-auto w-full ' onChange={(e) => setNbaLine2(e.target.value)}></input>
                                </div>
                                <div>
                                    <p className='text-sm'>Enter FV from {Number(nbaLine2) - 0.5} to {Number(nbaLine2) + 0.5}</p>
                                    <input type='number' id='fvLineNBA' className='border-2 h-10 md:w-auto w-full' onChange={(e) => {
                                        setNbaFVLine(e.target.value)
                                        setFairValueNBA('')
                                    }}></input>
                                </div>
                                <div>
                                    <p className='text-sm'> &nbsp;</p>
                                    <button className='border-2 md:w-24 w-full h-10 bg-yellow-200 ' onClick={() => getFVLineNBA()}>Get Value</button>
                                </div>
                            </div>

                            <div>
                                {/* <p className='mt-5'>Your point supremacy of <b>{nbaFVLine}</b> is equivalent to <b>{nbaLine2} {fairValueNBA}</b></p> */}
                                {nbaFVLine <= Number(nbaLine2) + 0.5 && nbaFVLine >= Number(nbaLine2) - 0.5
                                    ? <p className='mt-5'>Your fair value of <b>{nbaFVLine}</b> is equivalent to <b>{nbaLine2} {fairValueNBA}</b></p>
                                    : <p className='mt-5'>please enter value of only 0.5 away from market line</p>}
                            </div>
                        </div>


                    </div>


                    <div className='lg:w-fit w-full'>
                        <p className='font-bold text-center md:mt-0 mt-20 mb-4'>Edit Push %</p>
                        {/* <p className='text-center text-sm mb-4'>Edit push % here</p> */}

                        <div className='flex justify-center'>
                            <div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>1</p>
                                    <input type='text' id='pushNBA1' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push1: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>2</p>
                                    <input type='text' id='pushNBA2' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push2: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>3</p>
                                    <input type='text' id='pushNBA3' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push3: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>4</p>
                                    <input type='text' id='pushNBA4' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push4: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>5</p>
                                    <input type='text' id='pushNBA5' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push5: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>6</p>
                                    <input type='text' id='pushNBA6' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push6: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>7</p>
                                    <input type='text' id='pushNBA7' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push7: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>8</p>
                                    <input type='text' id='pushNBA8' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push8: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>9</p>
                                    <input type='text' id='pushNBA9' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push9: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>10</p>
                                    <input type='text' id='pushNBA10' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push10: e.target.value })}></input>
                                </div>
                            </div>
                            <div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>11</p>
                                    <input type='text' id='pushNBA11' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push11: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>12</p>
                                    <input type='text' id='pushNBA12' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push12: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>13</p>
                                    <input type='text' id='pushNBA13' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push13: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>14</p>
                                    <input type='text' id='pushNBA14' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push14: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>15</p>
                                    <input type='text' id='pushNBA15' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push15: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>16</p>
                                    <input type='text' id='pushNBA16' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push16: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>17</p>
                                    <input type='text' id='pushNBA17' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push17: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>18</p>
                                    <input type='text' id='pushNBA18' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push18: e.target.value })}></input>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <p className='text-center w-10'>19</p>
                                    <input type='text' id='pushNBA19' className='border-2 h-10 w-20' onChange={(e) => setPushProbNBA({ push19: e.target.value })}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex-row w-full'>
                {/* mlb total alt */}
                <p className='mt-32 text-4xl font-bold text-center'>MLB Alt Line Calculators</p>
                <div className='md:flex flex-row justify-between mt-5 overflow-x-auto w-full'>
                    <div className=''>
                        <div className='flex-col'>
                            <div className='mb-2'>
                                <p className='font-bold'>MLB Total Alt Line Odds Calculator</p>
                                <p className='text-sm'>1. Select Over or Under</p>
                                <p className='text-sm'>2. Enter a total line</p>
                                <p className='text-sm'>3. Enter US Odds (default at +100)</p>
                                <p className='text-sm'>4. Hit Run and see the equivalent odds on the alternative lines based on the push probabilities</p>
                            </div>

                            <div className='md:flex flex-row'>
                                <div>
                                    <p className='font-bold'>Bet</p>
                                    <p className='text-center w-10'> &nbsp;</p>
                                    <select name="side" id="mlbSide" className='border-2 h-10 w-24' onChange={(e) => setMlbBet(e.target.value)}>
                                        <option value="Over">Over</option>
                                        <option value="Under">Under</option>
                                    </select>
                                    <p> &nbsp;</p>
                                </div>
                                <div className='flex'>
                                    <div>
                                        <p className='font-bold'>Lines</p>
                                        <p className='w-10 ml-1'>{Number(mlbLine) - 0.5}</p>
                                        <input type='number' id='mlbLine' className='border-2 h-10 w-24' onChange={(e) => setMlbLine(e.target.value)}></input>
                                        <p className='w-10 ml-1'>{Number(mlbLine) + 0.5}</p>
                                    </div>
                                    <div>
                                        <p className='font-bold'>US Odds</p>
                                        <p id='alt2_us' className='text-center w-10'>{altUS2?.toFixed(2)}</p>
                                        <input type='number' id='mlbOdds' className='border-2 h-10 w-24' onChange={(e) => setMlbOdds(e.target.value)}></input>
                                        <p id='alt_us' className='text-center w-10'>{altUS?.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div>
                                    <p> &nbsp;</p>
                                    <p> &nbsp;</p>
                                    <button className='border-2 h-10 bg-yellow-200 md:w-24 w-full' onClick={() => getAltLines()}>Run</button>
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex-row mt-20'>
                            <div>
                                <p className='font-bold mb-4'>MLB total points fair value to market calculator</p>
                            </div>

                            <div className='md:flex flex-row'>
                                <div>
                                    <p className='text-sm'>Market Line</p>
                                    <input type='number' id='fvMarketLine' className='border-2 h-10 md:w-auto w-full ' onChange={(e) => setMlbLine2(e.target.value)}></input>
                                </div>
                                <div>
                                    <p className='text-sm'>Enter FV from {Number(mlbLine2) - 0.5} to {Number(mlbLine2) + 0.5}</p>
                                    <input type='number' id='fvLine' className='border-2 h-10 md:w-auto w-full' onChange={(e) => {
                                        setMlbFVLine(e.target.value)
                                        setFairValue('')
                                    }}></input>
                                </div>
                                <div>
                                    <p className='text-sm'> &nbsp;</p>
                                    <button className='border-2 md:w-24 w-full h-10 bg-yellow-200 ' onClick={() => getFVLine()}>Get Value</button>
                                </div>
                            </div>

                            <div>
                                {mlbFVLine <= Number(mlbLine2) + 0.5 && mlbFVLine >= Number(mlbLine2) - 0.5
                                    ? <p className='mt-5'>Your fair value of <b>{mlbFVLine}</b> is equivalent to  <b>Over {mlbLine2} {fairValue}</b></p>
                                    : <p className='mt-5'>please enter value of only 0.5 away from market line</p>}
                            </div>
                        </div>
                    </div>


                    <div className='lg:w-fit w-full'>
                        <p className='font-bold text-center md:mt-0 mt-20'>Edit Push %</p>
                        {/* <p className='text-center text-sm'>Edit push % here</p> */}
                        <p className='text-center text-xs mb-4'>Values are hidden but you can still enter your own % here</p>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>5</p>
                            <input type='text' id='push5' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push5: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>6</p>
                            <input type='text' id='push6' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push6: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>7</p>
                            <input type='text' id='push7' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push7: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>8</p>
                            <input type='text' id='push8' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push8: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>9</p>
                            <input type='text' id='push9' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push9: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>10</p>
                            <input type='text' id='push10' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push10: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>11</p>
                            <input type='text' id='push11' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push11: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>12</p>
                            <input type='text' id='push12' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push12: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>13</p>
                            <input type='text' id='push13' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push13: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>14</p>
                            <input type='text' id='push14' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push14: e.target.value })}></input>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-center w-10'>15</p>
                            <input type='text' id='push15' className='border-2 h-10 w-20' onChange={(e) => setPushProb({ push15: e.target.value })}></input>
                        </div>
                    </div>
                </div>
            </div>

            {/* soccer grader */}
            <div className='flex-row w-full'>
                <p className='mt-32 text-4xl font-bold text-center'>Asian Handicap Bet Grader</p>
                <div className='md:flex flex-row mt-5 overflow-x-auto w-full'>

                    <div>
                        <p className='text-sm'>Bet</p>
                        <select name="side" id="side" className='border-2 h-10 md:w-auto w-full' onChange={(e) => setSide(e.target.value)}>
                            <option value="Side1">Side 1</option>
                            <option value="Side2">Side 2</option>
                            <option value="Over">Over</option>
                            <option value="Under">Under</option>
                        </select>
                    </div>
                    <div>
                        <p className='text-sm'>Handicap (i.e. -0.5, 0.75)</p>
                        <input type='number' id='hdp_id' className='border-2 h-10 md:w-auto w-full' onChange={(e) => setHdp(e.target.value)}></input>
                    </div>
                    <div>
                        <p className='text-sm'>Score 1</p>
                        <input type='number' id='score_id1' className='border-2 h-10 md:w-32 w-full' onChange={(e) => setScore1(e.target.value)}></input>
                    </div>
                    <div>
                        <p className='text-sm'>Score 2</p>
                        <input type='number' id='score_id2' className='border-2 h-10 md:w-32 w-full' onChange={(e) => setScore2(e.target.value)}></input>
                    </div>
                    <div>
                        <p className='text-sm'>Result</p>
                        <input type='text' id='grade_soccer' className='border-2 h-10 md:w-32 w-full' ></input>
                    </div>

                    <div className='flex md:items-end items-center md:mt-0 mt-4'>
                        <button className='border-2 px-10 h-10 bg-blue-200 md:w-auto w-full' onClick={() => gradeResult()}>Grade</button>
                        <button className='border-2 px-10 h-10 bg-yellow-200 md:w-auto w-full' onClick={() => clearInputsSoccer()}>Reset</button>
                    </div>


                </div>
            </div>

            {/* Risk/Win/Odds */}
            <div className='mt-32 w-full'>
                <div>
                    <p className='text-4xl font-bold text-center'>Risk, Win, Odds Calculator</p>
                    <p className='text-sm mt-2'>Fill 2 out of the 3 boxes below</p>
                </div>

                <div className='md:flex flex-row mt-5'>
                    <div>
                        <p className='text-sm'>Risk $</p>
                        <input type='number' id='risk_id' className='border-2 h-10 md:w-auto w-full' onChange={(e) => setRisk(e.target.value)} ></input>
                    </div>
                    <div>
                        <p className='text-sm'>Odds (Decimal/US)</p>
                        <input type='number' id='odds_id' className='border-2 h-10 md:w-auto w-full' onChange={(e) => setOdds(e.target.value)} ></input>
                    </div>
                    <div>
                        <p className='text-sm'>Win $</p>
                        <input type='number' id='win_id' className='border-2 h-10 md:w-auto w-full' onChange={(e) => setWin(e.target.value)}></input>
                    </div>
                    <div className='md:flex flex-row items-end md:mt-0 mt-4'>
                        <button className='border-2 px-10 h-10 bg-blue-200 md:w-auto w-full' onClick={() => getResult()}>Calculate</button>
                        <button className='border-2 px-10 h-10 bg-yellow-200 md:w-auto w-full' onClick={() => clearInputs()}>Reset</button>
                    </div>
                </div>
            </div>






        </div>
    )
}

export default Tools