import React from 'react'
import { MdWeb, MdSportsBasketball } from 'react-icons/md'
import { ImDatabase } from 'react-icons/im'
import { SiGooglesheets, SiPython } from 'react-icons/si'
import { RiFileExcel2Fill } from 'react-icons/ri'
import sheets_trade from '../assets/sheets_trade.gif'
import excel_positions from '../assets/excel_positions.gif'
import moneypuck from '../assets/moneypuck.gif'
import services_port from '../assets/services_port.png'
import services_dash from '../assets/services_dash.png'
import services_sport from '../assets/services_sport.png'
import { Link } from 'react-router-dom'

const Services3 = () => {
    return (
        <div className="w-full lg:mx-0 mx-4">
            <div className='flex-row justify-center items-center text-center w-full'>
                <h2 className="font-bold text-3xl sm:text-6xl text-slate-700 my-20">
                    Services Offered
                </h2>
            </div>

            <div className='flex-row rounded-xl w-full '>

                <div className='lg:flex flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-green-700 mb-1'>
                            <RiFileExcel2Fill />
                        </div>
                        <p className='font-bold text-xl text-center'>Excel Sheets</p>
                        <p className='text-center text-sm mx-2'>VBA, Macro Development, Automation</p>
                    </div>
                    <img src={excel_positions} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>

                <div className='lg:flex lg:flex-row-reverse flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-green-500 mb-1'>
                            <SiGooglesheets />
                        </div>
                        <p className='font-bold text-xl text-center'>Google Sheets</p>
                        <p className='text-center text-sm mx-2'>Apps Script, API, JSON</p>
                    </div>
                    <img src={sheets_trade} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>

                <div className='lg:flex flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-yellow-500 mb-1'>
                            <SiPython />
                        </div>
                        <p className='font-bold text-xl text-center'>Python</p>
                        <p className='text-center text-sm mx-2'>Software Development, Web Scrape, Data Mining</p>
                    </div>
                    <img src={moneypuck} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>

                <div className='lg:flex lg:flex-row-reverse flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-sky-500 mb-1'>
                            <MdWeb />
                        </div>
                        <p className='font-bold text-xl text-center'>Front End</p>
                        <p className='text-center text-sm mx-2'>React, Javascript, HTML/CSS</p>
                        <Link to='/portfolio' className="">
                            <p className='text-center text-sm mx-2 text-blue-500 underline'>click here to check out my portfolio</p>
                        </Link>
                    </div>
                    <img src={services_port} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>

                <div className='lg:flex flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-yellow-500 mb-1'>
                            <ImDatabase />
                        </div>
                        <p className='font-bold text-xl text-center'>Back End</p>
                        <p className='text-center text-sm mx-2'>Express, Firebase, MongoDB, SQL</p>
                        <Link to='/dashboard' className="">
                            <p className='text-center text-sm mx-2 text-blue-500 underline'>click here to demo a Dashboard</p>
                        </Link>
                    </div>
                    <img src={services_dash} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>

                <div className='lg:flex lg:flex-row-reverse flex-row justify-between mx-4 rounded-lg mb-20'>
                    <div className='flex-wrap items-center justify-center my-auto sm:py-10 py-5 w-full lg:mx-4'>
                        <div className='flex md:text-9xl text-6xl justify-center text-sky-500 mb-1'>
                            <MdSportsBasketball />
                        </div>
                        <p className='font-bold text-xl text-center'>Specialized in Sports Betting Tools</p>
                        <p className='text-center text-sm mx-2'></p>
                        <Link to='/portfolio_sports' className="">
                            <p className='text-center text-sm mx-2 text-blue-500 underline'>click here to check out my portfolio in Sports</p>
                        </Link>
                    </div>
                    <img src={services_sport} alt='' className='border-4 border-slate-300 rounded-lg md:w-7/12 w-full' />
                </div>


            </div>

            <div className='flex justify-center mb-10 mt-20'>
                <a className='text-center border-2 py-4 px-20 rounded-xl text-white text-2xl bg-rose-500 hover:bg-rose-700 hover:border-red-500' href='mailto:gilbert@gcconsultancy.ca'>
                    <p>Contact Me Now!</p>
                    <p className='text-xs mt-1'>gilbert@gcconsultancy.ca</p>
                </a>
            </div>


        </div>
    )
}

export default Services3