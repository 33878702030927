import React, { useState } from 'react'
import { useContextState } from '../../../ContextAPI'
import { AiFillLock } from 'react-icons/ai'
import TeamSelector from './TeamSelector'

const Profiler = () => {

    const { NHLData, NHLTeam } = useContextState()

    const [showAll, setShowAll] = useState(true)
    const [showHome, setShowHome] = useState(false)
    const [showAway, setShowAway] = useState(false)

    const showAllFixtures = () => {
        setShowAll(true)
        setShowHome(false)
        setShowAway(false)
    }

    const showHomeFixtures = () => {
        setShowAll(false)
        setShowHome(true)
        setShowAway(false)
    }

    const showAwayFixtures = () => {
        setShowAll(false)
        setShowHome(false)
        setShowAway(true)
    }

    let last5Results = []
    let last5HomeResults = []
    let last5AwayResults = []
    let last5Price = []
    let last5HomePrice = []
    let last5AwayPrice = []

    NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
        var team1 = row[1]
        var team2 = row[2]
        var score1 = row[41]
        var score2 = row[42]
        var open1 = 1 / row[38]
        var close1 = 1 / row[49]
        var pchange1 = ((open1 - close1) * 100).toFixed(2)

        if (team1 === NHLTeam) {
            if (score1 > score2) {
                last5Results.push(["W"])
                last5HomeResults.push(["W"])
            } else if (score2 > score1) {
                last5Results.push(["L"])
                last5HomeResults.push(["L"])
            } else if (score1 === score2) {
                last5Results.push(["D"])
                last5HomeResults.push(["D"])
            }
            last5HomePrice.push(pchange1)
        }

        if (team2 === NHLTeam) {
            if (score1 < score2) {
                last5Results.push(["W"])
                last5AwayResults.push(["W"])
            } else if (score2 < score1) {
                last5Results.push(["L"])
                last5AwayResults.push(["L"])
            } else if (score1 === score2) {
                last5Results.push(["D"])
                last5AwayResults.push(["L"])
            }
            last5AwayPrice.push(pchange1 * -1)
        }

        if (team1 === NHLTeam) {
            last5Price.push(pchange1)
        } else if (team2 === NHLTeam) {
            last5Price.push(pchange1 * -1)
        }

    })

    const profilerRow = (date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un) => {
        return (
            <div>
                <div className={`md:flex flex-row md:h-32 h-auto md:py-0 py-1 border border-slate-800 bg-${winColor}`}>
                    <div className='md:flex flex-row my-max items-center justify-center md:w-2/12 w-full md:border-r-2 border-b border-slate-800'>
                        <p className='text-xs text-center'>{date}</p>
                    </div>

                    <div className='flex my-max items-center justify-end md:w-4/12 w-full md:border-0 border-b-2'>
                        <div className='text-right md:w-9/12 md:mr-5 mr-0'>
                            <p className='text-xs font-bold'>{score1 > score2 ? <span className='bg-green-300 p-1 rounded-md'>{mov}</span> : <></>} <span className={team1_un}>{team1}</span></p>
                            <p className='text-xs'>GK: {gk1}</p>
                            <p className='text-xs text-clip overflow-hidden ...'>Key Inj: {inj1}</p>
                        </div>
                        <div className='flex my-max items-center justify-end w-3/12'>
                            <div className='px-2'>
                                <p className='text-xl text-center'>{score1}</p>
                                <p className='text-xs'>{xg1} xG</p>
                            </div>
                        </div>
                    </div>

                    <div className={`flex md:flex-row flex-row-reverse my-max items-center justify-end md:w-4/12 w-full`}>

                        <div className='flex my-max items-center md:justify-start justify-end w-3/12 '>
                            <div className='px-2'>
                                <p className='text-xl text-center'>{score2}</p>
                                <p className='text-xs'>{xg2} xG</p>
                            </div>
                        </div>
                        <div className='flex-col md:text-left text-right w-9/12 ml-5'>
                            <p className='md:block hidden font-bold text-xs'><span className={team2_un}>{team2}</span> {score2 > score1 ? <span className='bg-green-300 p-1 rounded-md'>{mov}</span> : <></>}</p>
                            <p className='md:hidden block font-bold text-xs'>{score2 > score1 ? <span className='bg-green-300 p-1 rounded-md mr-1'>{mov}</span> : <></>}<span className={team2_un}>{team2}</span> </p>
                            <p className='text-xs'>GK: {gk2}</p>
                            <p className='text-xs text-clip overflow-hidden ...'>Key Inj: {inj2}</p>
                        </div>

                    </div>

                    <div className='flex my-max items-center justify-center md:border-l-2 border-l-0 border-slate-800 md:w-2/12 w-full'>
                        <div className='md:block flex md:ml-2 ml-0 items-center '>
                            <p className='flex text-xs font-bold items-center'>Open Price: <span className='ml-1 text-amber-500'><AiFillLock /></span></p>
                            <p className='flex text-xs font-bold items-center'>Close Price: <span className='ml-1 text-amber-500'><AiFillLock /></span></p>
                            <p className='flex text-xs font-bold items-center'>Value: <span className='ml-1 text-amber-500'><AiFillLock /></span></p>
                        </div>
                    </div>

                </div>
            </div>

        )
    }


    return (
        <div>
            {/* profiler */}
            {/* team1 */}
            <div className='w-full mt-14'>

                <p className='font-bold text-center text-4xl my-10'>NHL Team Profiler (2018 - 2019)</p>

                <div className='md:w-1/4 w-full'>
                    <TeamSelector />
                </div>

                <div className='w-full md:block flex'>
                    <button onClick={() => showAllFixtures()} className={`${(showAll) ? 'bg-blue-500' : 'text-blue-500'} border-2 md:px-6 rounded text-white font-bold md:w-fit w-full`}>Overall</button>
                    <button onClick={() => showHomeFixtures()} className={`${(showHome) ? 'bg-blue-500' : 'text-blue-500'} border-2 md:px-6 rounded text-white font-bold md:w-fit w-full`}>Home</button>
                    <button onClick={() => showAwayFixtures()} className={`${(showAway) ? 'bg-blue-500' : 'text-blue-500'} border-2 md:px-6 rounded text-white font-bold md:w-fit w-full`}>Away</button>
                </div>

                {last5AwayResults[0] !== undefined ? <div className='border-2'>
                    <div>
                        <div className='md:flex flex-row justify-between'>
                            <p className='flex items-center md:text-xs text-sm font-bold py-2'>Last 5 Games</p>

                            {/* win/loses */}
                            {showAll &&
                                <div className='flex items-center'>
                                    <span className='text-xs font-bold'>Form: </span>
                                    <span className={`${last5Results[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[0]}</span>
                                    <span className={`${last5Results[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[1]}</span>
                                    <span className={`${last5Results[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[2]}</span>
                                    <span className={`${last5Results[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[3]}</span>
                                    <span className={`${last5Results[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5Results[4]}</span>
                                </div>}

                            {showHome &&
                                <div className='flex items-center'>
                                    <span className='text-xs font-bold'>Form: </span>
                                    <span className={`${last5HomeResults[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[0]}</span>
                                    <span className={`${last5HomeResults[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[1]}</span>
                                    <span className={`${last5HomeResults[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[2]}</span>
                                    <span className={`${last5HomeResults[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[3]}</span>
                                    <span className={`${last5HomeResults[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5HomeResults[4]}</span>
                                </div>}

                            {showAway &&
                                <div className='flex items-center'>
                                    <span className='text-xs font-bold'>Form:</span>
                                    <span className={`${last5AwayResults[0] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[0]}</span>
                                    <span className={`${last5AwayResults[1] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[1]}</span>
                                    <span className={`${last5AwayResults[2] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[2]}</span>
                                    <span className={`${last5AwayResults[3] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[3]}</span>
                                    <span className={`${last5AwayResults[4] == "W" ? 'bg-green-300' : 'bg-red-300'} p-1 text-xs flex w-6 text-white justify-center rounded-2xl`}>{last5AwayResults[4]}</span>
                                </div>}


                            {/* price changes */}
                            {showAll && <div classname=''>
                                <div className='flex h-full items-center'>
                                    <span className='text-xs font-bold'>Price Change: </span>
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                </div>
                            </div>}

                            {showHome && <div>
                                <div className='flex h-full items-center'>
                                    <span className='text-xs font-bold'>Price Move: </span>
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                </div>
                            </div>}

                            {showAway && <div>
                                <div className='flex h-full items-center'>
                                    <span className='text-xs font-bold'>Price Move: </span>
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                    <AiFillLock className='ml-1 text-amber-500' />
                                </div>
                            </div>}

                        </div>
                    </div>
                </div> : <></>}

                <div className='flex border-2'>
                    <div className='w-2/12 border-r-2'>
                        <p className='text-center text-xs font-bold'>Date</p>
                    </div>
                    <div className='w-8/12'>
                        <div className='flex text-xs font-bold'>
                            <p className='w-1/2 text-center border-r-2'>Home</p>
                            <p className='w-1/2 text-center'>Away</p>
                        </div>
                    </div>
                    <div className='w-2/12 border-l-2 text-xs font-bold'>
                        <p className='text-center'>Details</p>
                    </div>
                </div>


                {/* all fixtures */}
                {showAll && <>
                    {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                        var date = row[0]
                        var team1 = row[1]
                        var team2 = row[2]
                        var gk1 = row[3]
                        var gk2 = row[4]
                        var inj1 = row[5]
                        var inj2 = row[6]
                        var score1 = row[41]
                        var score2 = row[42]
                        var xg1 = row[44]
                        var xg2 = row[45]
                        if (row[43] === "RT") {
                            var mov = "W"
                        } else {
                            var mov = row[43]
                        }
                        var open1 = 1 / row[59]
                        var close1 = 1 / row[69]
                        var open2 = 1 / row[39]
                        var close2 = 1 / row[50]
                        var pchange1 = ((open1 - close1) * 100).toFixed(2)
                        if (row[53] === "Home") {
                            var valueside = team1
                        } else if (row[53] === "Road") {
                            var valueside = team2
                        }
                        var value = valueside + " " + (row[54] * 100).toFixed(2)
                        var closepnl = Number(row[57]).toFixed(2)
                        if (NHLTeam === team1 && score1 > score2) {
                            var winColor = "green-100"
                        } else if (NHLTeam === team2 && score1 < score2) {
                            var winColor = "green-100"
                        } else {
                            var winColor = "red-100"
                        }
                        var team1_un = ""
                        var team2_un = ""
                        if (NHLTeam === team1) {
                            var team1_un = "underline"
                        } else if (NHLTeam === team2) {
                            var team2_un = "underline"
                        } else {
                            team1_un = ""
                            team2_un = ""
                        }


                        if (NHLTeam === team1 || NHLTeam === team2) {
                            return (
                                profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                            )
                        }
                    })}
                </>
                }

                {/* home fixtures */}
                {showHome && <>
                    {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                        var date = row[0]
                        var team1 = row[1]
                        var team2 = row[2]
                        var gk1 = row[3]
                        var gk2 = row[4]
                        var inj1 = row[5]
                        var inj2 = row[6]
                        var score1 = row[41]
                        var score2 = row[42]
                        var xg1 = row[44]
                        var xg2 = row[45]
                        if (row[43] === "RT") {
                            var mov = "W"
                        } else {
                            var mov = row[43]
                        }
                        var open1 = 1 / row[38]
                        var close1 = 1 / row[49]
                        var pchange1 = ((open1 - close1) * 100).toFixed(2)
                        if (row[53] === "Home") {
                            var valueside = team1
                        } else if (row[53] === "Road") {
                            var valueside = team2
                        }
                        var value = valueside + " " + (row[54] * 100).toFixed(2)
                        var closepnl = Number(row[57]).toFixed(2)
                        if (NHLTeam === team1 && score1 > score2) {
                            var winColor = "green-100"
                        } else if (NHLTeam === team2 && score1 < score2) {
                            var winColor = "green-100"
                        } else {
                            var winColor = "red-100"
                        }
                        var team1_un = ""
                        var team2_un = ""
                        if (NHLTeam === team1) {
                            team1_un = "underline"
                        } else if (NHLTeam === team2) {
                            team2_un = "underline"
                        } else {
                            team1_un = ""
                            team2_un = ""
                        }

                        if (NHLTeam === team1) {
                            //console.log(open1 - close1 * 100)
                            return (
                                profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                            )
                        }
                    })}
                </>
                }


                {/* away fixtures */}
                {showAway && <>
                    {NHLData?.data?.values?.slice(0)?.reverse().map((row) => {
                        var date = row[0]
                        var team1 = row[1]
                        var team2 = row[2]
                        var gk1 = row[3]
                        var gk2 = row[4]
                        var inj1 = row[5]
                        var inj2 = row[6]
                        var score1 = row[41]
                        var score2 = row[42]
                        var xg1 = row[44]
                        var xg2 = row[45]
                        if (row[43] === "RT") {
                            var mov = "W"
                        } else {
                            var mov = row[43]
                        }
                        var open1 = 1 / row[38]
                        var close1 = 1 / row[49]
                        var open2 = 1 / row[39]
                        var close2 = 1 / row[50]
                        var pchange1 = ((open1 - close1) * 100).toFixed(2)
                        if (row[53] === "Home") {
                            var valueside = team1
                        } else if (row[53] === "Road") {
                            var valueside = team2
                        }
                        var value = valueside + " " + (row[54] * 100).toFixed(2)
                        var closepnl = Number(row[57]).toFixed(2)
                        if (NHLTeam === team1 && score1 > score2) {
                            var winColor = "green-100"
                        } else if (NHLTeam === team2 && score1 < score2) {
                            var winColor = "green-100"
                        } else {
                            var winColor = "red-100"
                        }
                        if (NHLTeam === team1) {
                            var team1_un = "underline"
                        } else if (NHLTeam === team2) {
                            var team2_un = "underline"
                        } else {
                            var team1_un = ""
                            var team2_un = ""
                        }

                        if (NHLTeam === team2) {
                            return (
                                profilerRow(date, team1, gk1, inj1, score1, xg1, team2, gk2, inj2, score2, xg2, mov, pchange1, value, closepnl, winColor, team1_un, team2_un)
                            )
                        }
                    })}
                </>
                }
            </div>

            {/* profiler */}
        </div>
    )
}

export default Profiler