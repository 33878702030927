import React from 'react'
import { HiOutlineCode } from 'react-icons/hi'
import { GrLanguage } from 'react-icons/gr'
import CountryChart from '../components/charts/CountryChart'
import pic from '../assets/guu.png'
import { BsLinkedin, BsGithub, BsInstagram, BsFacebook } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import sfu from '../assets/sfu.png'
import google from '../assets/google.png'
import code from '../assets/code.png'
import resume from '../assets/resume.pdf'


const Badge = ({ title, subtitle, small, image }) => {
  return (
    <div className="border-gray-200 border-2 rounded-xl py-3 px-5 bg-white ">
      <div className="flex flex-row justify-between items-center">
        <div>
          <h6 className="text-xl">{title}</h6>
          <h4 className="text-black text-3xl font-bold text-left">{subtitle}</h4>
        </div>
        <div className="h-12 w-12">
          <img src={image} alt='' />
        </div>
      </div>
      <div className="text-left flex flex-row justify-start items-center">
        <p><span className="text-teal-500 font-bold">{small}</span></p>
      </div>
    </div>
  )
}

const About = () => {
  return (
    <div>

      <section className="text-gray-600 body-font mb-10">
        <div className="container px-5 mx-auto flex flex-wrap">

          <p className='py-4 px-5 text-4xl font-bold text-gray-600' >About</p>

          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">

              {/* info */}
              <div className="p-4 xl:w-1/2 lg:w-full w-full">
                <div className="flex border-2 rounded-xl border-gray-200 p-8 sm:flex-row flex-col items-center ">
                  <div className="w-32 h-32 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0 " >
                    <img src={pic} alt='gc' className='w-20' />
                  </div>
                  <div className="flex-grow lg:ml-5">
                    <h2 className="text-gray-900 text-4xl title-font font-bold mb-2 sm:text-left text-center">Gilbert Chang</h2>
                    <ol className='sm:text-left text-center'>
                      <li className="leading-relaxed text-base">Technical Consultant</li>
                      <li className="leading-relaxed text-base">Full Stack Web Developer</li>
                      <li className="leading-relaxed text-base">VBA Developer</li>
                    </ol>
                  </div>
                </div>
              </div>
              {/* info */}

              {/* contact */}
              <div className="p-4 xl:w-1/2 lg:w-full w-full ">
                <div className="flex border-2 rounded-xl border-gray-200 p-8 sm:flex-row h-full">
                  <div className="flex-1">
                    <h2 className="flex justify-center text-gray-900 text-4xl title-font font-bold mb-3">Contact Me</h2>
                    <div className='flex-row sm:flex items-center justify-evenly mt-7'>
                      <div className='flex flex-col sm:mb-0 mb-2 items-center'>
                        {/* <a href='https://github.com/changgilbert' target="_blank" rel="noreferrer"> */}
                        <BsGithub className='text-4xl' color='black' />
                        {/* </a> */}
                        <div>Github</div>
                      </div>
                      <div className='flex flex-col sm:mb-0 mb-2 items-center'>
                        <a href='https://www.linkedin.com/in/gilbert-chang-61578143/' target="_blank" rel="noreferrer"><BsLinkedin className='text-4xl' color='blue' /></a>
                        <div>Linkedin</div>
                      </div>
                      <div className='flex flex-col sm:mb-0 mb-2 items-center'>
                        <div><BsInstagram className='text-4xl' color='brown' /></div>
                        <div>Instagram</div>
                      </div>
                      <div className='flex flex-col sm:mb-0 mb-2 items-center'>
                        <div><BsFacebook className='text-4xl' color='blue' /></div>
                        <div>Facebook</div>
                      </div>
                      <div className='flex flex-col sm:mb-0 mb-2 items-center'>
                        <a href='mailto:gilbert@gcconsultancy.ca'><MdEmail className='text-4xl' color='purple' /></a>
                        <div>E-mail</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* contact */}

              {/* badges */}
              <div className="px-4 mb-4 mx-auto container align-middle mt-4">
                <div className="grid md:grid-cols-3 gap-8">
                  <Badge title='Economics' subtitle='SFU' small='2008 - 2011' image={sfu} />
                  <Badge title='Data Analytics' subtitle='Google' small='2021' image={google} />
                  <Badge title='Web Development' subtitle='Full Stack' small='2022' image={code} />
                </div>
              </div>
              {/* badges */}

            </div>

            <div className="p-4 lg:w-5/12 md:w-full w-full mb-2">
              <div className="flex border-2 rounded-xl border-gray-200  p-8 sm:flex-row items-center">
                <div className="flex-grow">
                  <h2 className="flex items-center text-gray-900 text-2xl title-font font-bold mb-3">Technical Skills <HiOutlineCode className='text-3xl ml-3' /></h2>

                  <div className='flex justify-between mb-4'>
                    {/* leftside */}
                    <ol>
                      <li className="leading-relaxed text-lg my-1">React</li>
                      <li className="leading-relaxed text-lg my-1">Javascript</li>
                      <li className="leading-relaxed text-lg my-1">Node JS</li>
                      <li className="leading-relaxed text-lg my-1">Express</li>
                      <li className="leading-relaxed text-lg my-1">Firebase</li>
                      <li className="leading-relaxed text-lg my-1">MongoDB</li>
                    </ol>
                    {/* right side */}
                    <ol>
                      <li className="leading-relaxed text-lg my-1">HTML/CSS</li>
                      <li className="leading-relaxed text-lg my-1">JSON</li>
                      <li className="leading-relaxed text-lg my-1">Git</li>
                      <li className="leading-relaxed text-lg my-1">VBA</li>
                      <li className="leading-relaxed text-lg my-1">Apps Script</li>
                      <li className="leading-relaxed text-lg my-1">Python</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 lg:w-7/12 md:w-full w-full">
              <div className="flex border-2 rounded-xl border-gray-200  p-8 sm:flex-row items-center">
                <div className="flex-grow">
                  <h2 className="flex items-center text-gray-900 text-2xl title-font font-bold mb-3">Language Skills<GrLanguage className='text-3xl ml-3' /></h2>
                  {/* english */}
                  <div>
                    <p>English</p>
                    <div className="w-full h-3 bg-gray-400 rounded-full my-1">
                      <div className="w-4/4 h-full text-center text-xs text-white bg-red-500 rounded-full" />
                    </div>
                  </div>

                  <div>
                    <p>Cantonese</p>
                    <div className="w-full h-3 bg-gray-400 rounded-full my-1">
                      <div className="w-10/12 h-full text-center text-xs text-white bg-blue-500 rounded-full" />
                    </div>
                  </div>

                  <div>
                    <p>Mandarin</p>
                    <div className="w-full h-3 bg-gray-400 rounded-full my-1">
                      <div className="w-8/12 h-full text-center text-xs text-white bg-green-500 rounded-full" />
                    </div>
                  </div>

                  <div>
                    <p>Japanese</p>
                    <div className="w-full h-3 bg-gray-400 rounded-full my-1">
                      <div className="w-5/12 h-full text-center text-xs text-white bg-purple-500 rounded-full" />
                    </div>
                  </div>

                  <div>
                    <p>Malay</p>
                    <div className="w-full h-3 bg-gray-400 rounded-full my-1">
                      <div className="w-2/12 h-full text-center text-xs text-white bg-yellow-500 rounded-full" />
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>
          {/* bottom */}

          <div className="px-4 py-2 lg:w-7/12 w-full">
            <div className="flex border-2 rounded-xl border-gray-200  p-8 sm:flex-row ">
              <div className="flex-grow">
                <div className='flex items-center justify-between sm:flex-row flex-col mb-9'>
                  <h2 className="text-gray-900 text-xl title-font font-bold">Professional History</h2>
                  <button onClick={() => window.open(resume, "_blank")} target="_blank" className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out">
                    Download Full Resume
                  </button>
                </div>
                <ol className="items-center sm:flex mb-10">
                  <li className="relative mb-6 sm:mb-0">
                    <div className="flex items-center">
                      <div className="flex z-10 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-0 ring-white  sm:ring-8  shrink-0">
                        <svg className="w-3 h-3 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                    </div>
                    <div className="mt-3 sm:pr-8">
                      <h3 className="text-lg font-semibold text-gray-900">Technical Consultant</h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-gray-400">2018 - Present</time>
                    </div>
                  </li>
                  <li className="relative mb-6 sm:mb-0">
                    <div className="flex items-center">
                      <div className="flex z-10 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-0 ring-white  sm:ring-8  shrink-0">
                        <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                    </div>
                    <div className="mt-3 sm:pr-8">
                      <h3 className="text-lg font-semibold text-gray-900 ">Sr. Market Analyst</h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-gray-400 ">2016 - 2018</time>
                    </div>
                  </li>
                  <li className="relative mb-6 sm:mb-0">
                    <div className="flex items-center">
                      <div className="flex z-10 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-0 ring-white  sm:ring-8  shrink-0">
                        <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                    </div>
                    <div className="mt-3 sm:pr-8">
                      <h3 className="text-lg font-semibold text-gray-900">Market Analyst</h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-gray-400">2015 - 2016</time>
                    </div>
                  </li>
                  <li className="relative mb-6 sm:mb-0">
                    <div className="flex items-center">
                      <div className="flex z-10 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-0 ring-white  sm:ring-8  shrink-0">
                        <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                    </div>
                    <div className="mt-3 sm:pr-8">
                      <h3 className="text-lg font-semibold text-gray-900 ">Jr. Market Analyst</h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-gray-400 ">2013 - 2015</time>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 lg:w-5/12 w-full ">
            <div className="flex border-2 rounded-xl border-gray-200 p-8 sm:flex-row ">
              <div className="flex-grow -mb-1">
                <h2 className="text-gray-900 text-xl title-font font-bold">Cites Lived In (Years)</h2>
                <div className=''>
                  <CountryChart />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section >
    </div>
  )
}

export default About