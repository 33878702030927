import React, { useState } from 'react'
//import { useContextState } from '../ContextAPI'
import Profiler from '../components/clientcomponents/sports/Profiler'
import SportsOdds from '../components/clientcomponents/sports/SportsOdds'
import Projects from '../components/clientcomponents/sports/Projects'
import Tools from '../components/clientcomponents/sports/Tools'
import { MdWeb } from 'react-icons/md'
import { ImDatabase } from 'react-icons/im'
import { SiGooglesheets, SiPython } from 'react-icons/si'
import { RiFileExcel2Fill } from 'react-icons/ri'
import LiveNHL from '../components/clientcomponents/sports/LiveNHL'
import { Routes, Route, Link } from 'react-router-dom'


const SportsPortfolio = () => {


  //const { } = useContextState()

  /* dates */
  /*   const today = new Date()
    today.setHours(0, 0, 0, 0)
    const tomorrow = new Date(new Date().setDate(today.getDate() + 2))
    tomorrow.setHours(0, 0, 0, 0)
    const nextWeek = new Date(new Date().setDate(today.getDate() + 7)) */


  const [showMarket, setShowMarket] = useState(true)
  const [showProfiler, setShowProfiler] = useState(false)
  const [showTools, setShowTools] = useState(false)
  const [showProjects, setShowProjects] = useState(false)
  const [showLiveNHL, setShowLiveNHL] = useState(false)

  const displayMarket = () => {
    setShowMarket(true)
    setShowProfiler(false)
    setShowTools(false)
    setShowProjects(false)
    setShowLiveNHL(false)
  }

  const displayProfiler = () => {
    setShowMarket(false)
    setShowProfiler(true)
    setShowTools(false)
    setShowProjects(false)
    setShowLiveNHL(false)
  }

  const displayTools = () => {
    setShowMarket(false)
    setShowProfiler(false)
    setShowTools(true)
    setShowProjects(false)
    setShowLiveNHL(false)
  }

  const displayProjects = () => {
    setShowMarket(false)
    setShowProfiler(false)
    setShowTools(false)
    setShowProjects(true)
    setShowLiveNHL(false)
  }

  const displayLiveNHL = () => {
    setShowMarket(false)
    setShowProfiler(false)
    setShowTools(false)
    setShowProjects(false)
    setShowLiveNHL(true)
  }

  return (
    <div className="flex-row text-gray-600 px-10 mx-auto flex-wrap justify-center w-full">
{/* 
      <div className='flex'>
        <button className={`${(showMarket) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs`} onClick={() => displayMarket()}>
          Market Odds
        </button>
        <button className={`${(showTools) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs`} onClick={() => displayTools()}>Betting Tools</button>
        <button className={`${(showProfiler) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs`} onClick={() => displayProfiler()}>Team Profiler</button>
        <button className={`${(showLiveNHL) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs`} onClick={() => displayLiveNHL()}>Live NHL</button>
        <button className={`${(showProjects) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs`} onClick={() => displayProjects()}>Other Projects</button>
      </div>
 */}
      {/*  {showMarket &&
        <div className='mb-20'>
          <SportsOdds />
        </div>
      }

      {showProfiler &&
        <div className='mb-20'>
          <Profiler />
        </div>
      }

      {showTools &&
        <div className='mb-32'>
          <Tools />
        </div>
      }

      {showProjects &&
        <div>
          <Projects />
        </div>
      }

      {showLiveNHL &&
        <div>
          <LiveNHL />
        </div>
      }
 */}

      <div className='flex'>
        <Link to='/portfolio_sports/odds' className={`${(showMarket) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs text-center flex items-center justify-center`} onClick={() => displayMarket()}>Market Odds</Link>
        <Link to='/portfolio_sports/tools' className={`${(showTools) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs text-center flex items-center justify-center`} onClick={() => displayTools()}>Betting Tools</Link>
        <Link to='/portfolio_sports/profiler' className={`${(showProfiler) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs text-center flex items-center justify-center`} onClick={() => displayProfiler()}>Team Profiler</Link>
        <Link to='/portfolio_sports/livenhl' className={`${(showLiveNHL) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs text-center flex items-center justify-center`} onClick={() => displayLiveNHL()}>Live NHL</Link>
        <Link to='/portfolio_sports/projects' className={`${(showProjects) ? 'bg-gray-900' : 'text-gray-900'} hover:bg-gray-900 hover:text-white md:border-2 border md:px-6 p-0 md:h-14 h-16 text-white font-bold w-full md:text-base text-xs text-center flex items-center justify-center`} onClick={() => displayProjects()}>Other Projects</Link>
      </div>

      <Routes>
        <Route path='/odds' exact element={<SportsOdds />} />
        <Route path='/tools' exact element={<Tools />} />
        <Route path='/profiler' exact element={<Profiler />} />
        <Route path='/livenhl' exact element={<LiveNHL />} />
        <Route path='/projects' exact element={<Projects />} />
      </Routes>


      <div className='flex justify-center my-20'>
        <a className='text-center border-2 py-4 px-20 rounded-xl text-white text-2xl bg-rose-500 hover:bg-rose-700 hover:border-red-500' href='mailto:gilbert@gcconsultancy.ca'>
          <p>Contact Me Now!</p>
          <p className='text-xs mt-1'>gilbert@gcconsultancy.ca</p>
        </a>
      </div>

      <div className='lg:flex flex-row rounded-xl w-full mb-10'>
        <div className='flex-wrap items-center justify-center hover:bg-sky-50 sm:py-10 py-5 lg:w-1/5 w-full lg:mx-4 my-4 mx-0 border-2 rounded-xl'>
          <div className='flex text-6xl justify-center text-sky-500 mb-1'>
            <MdWeb />
          </div>
          <p className='font-bold text-xl text-center'>Front End</p>
          <p className='text-center text-sm mx-2'>React, Javascript, HTML/CSS</p>
        </div>
        <div className='flex-wrap items-center justify-center hover:bg-sky-50 sm:py-10 py-5 lg:w-1/5 w-full lg:mx-4 my-4 border-2 rounded-xl'>
          <div className='flex text-6xl justify-center text-purple-500 mb-1'>
            <ImDatabase />
          </div>
          <p className='font-bold text-xl text-center'>Back End</p>
          <p className='text-center text-sm mx-2'>Express, Firebase, MongoDB, SQL</p>
        </div>
        <div className='flex-wrap items-center justify-center hover:bg-sky-50 sm:py-10 py-5 lg:w-1/5 w-full lg:mx-4 my-4 border-2 rounded-xl'>
          <div className='flex text-6xl justify-center text-green-500 mb-1'>
            <SiGooglesheets />
          </div>
          <p className='font-bold text-xl text-center'>Google Sheets</p>
          <p className='text-center text-sm mx-2'>Apps Script, API, JSON</p>
        </div>
        <div className='flex-wrap items-center justify-center hover:bg-sky-50 sm:py-10 py-5 lg:w-1/5 w-full lg:mx-4 my-4 border-2 rounded-xl'>
          <div className='flex text-6xl justify-center text-green-700 mb-1'>
            <RiFileExcel2Fill />
          </div>
          <p className='font-bold text-xl text-center'>Excel Sheets</p>
          <p className='text-center text-sm mx-2'>VBA, Macro Development</p>
        </div>
        <div className='flex-row flex-wrap items-center justify-center hover:bg-sky-50 sm:py-10 py-5 lg:w-1/5 w-full lg:mx-4 my-4 border-2 rounded-xl'>
          <div className='flex text-6xl justify-center text-yellow-500 mb-1'>
            <SiPython />
          </div>
          <p className='font-bold text-xl text-center'>Python</p>
          <p className='text-center text-sm mx-2'>Software Development</p>
        </div>
      </div>


    </div>


  )
}

export default SportsPortfolio