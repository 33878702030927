import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArielEndDatePicker from '../../components/clientcomponents/ariel/ArielEndDatePicker'
import ArielGoogleTable from '../../components/clientcomponents/ariel/ArielGoogleTable'
import ArielLevelSelector from '../../components/clientcomponents/ariel/ArielLevelSelector'
import ArielOverview from '../../components/clientcomponents/ariel/ArielOverview'
import ArielSelectorClient from '../../components/clientcomponents/ariel/ArielSelectorClient'
import ArielSelectorDate from '../../components/clientcomponents/ariel/ArielSelectorDate'
import ArielStartDatePicker from '../../components/clientcomponents/ariel/ArielStartDatePicker'
import ArielSummary from '../../components/clientcomponents/ariel/ArielSummary'
import ArielTable from '../../components/clientcomponents/ariel/ArielTable'
import ArielTableAll from '../../components/clientcomponents/ariel/ArielTableAll'
import { useContextState } from '../../ContextAPI'

const Ariel = () => {

    const { getAriel, setArielClientGoogle, arielClient, getArielGoogle, arielClientGoogle, getArielCustom, arielFBDates, arielClientToast, arielDateToast, getArielAll, color } = useContextState()


    useEffect(() => {
        if (arielClient === process.env.REACT_APP_FB_ACCOUNT1) {
            setArielClientGoogle(process.env.REACT_APP_GOOGLESHEET1)
        } else if (arielClient === process.env.REACT_APP_FB_ACCOUNT2) {
            setArielClientGoogle(process.env.REACT_APP_GOOGLESHEET2)
        } else if (arielClient === process.env.REACT_APP_FB_ACCOUNT3) {
            setArielClientGoogle(process.env.REACT_APP_GOOGLESHEET3)
        } else if (arielClient === process.env.REACT_APP_FB_ACCOUNT4) {
            setArielClientGoogle(process.env.REACT_APP_GOOGLESHEET4)
        }

        return
    }, [arielClient])

    useEffect(() => {
        getArielAll('last_7d')
    }, [])


    return (


        <div className="flex-row text-gray-600 body container px-10 mx-auto flex-wrap justify-center w-full">

            <div>
                <p className='py-4 text-4xl text-white font-bold border-2 p-6 rounded-xl' style={{ backgroundColor: color }}>
                    Ariel's Dashboard
                </p>

                <p className='w-full text-center font-semibold text-2xl my-5'>Overview</p>
                <div className='pb-14 border-b-2'>
                    <div className='sm:flex flex-row sm:justify-start items-center'>
                        <button className='w-full text-indigo-700 hover:text-white border border-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-xl text-sm px-5 py-2 text-center mr-2 mb-2' onClick={() => getArielAll('last_7d')}>Last 7 Days</button>
                        <button className='w-full text-indigo-700 hover:text-white border border-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-xl text-sm px-5 py-2 text-center mr-2 mb-2' onClick={() => getArielAll('last_14d')}>Last 14 Days</button>
                        <button className='w-full text-indigo-700 hover:text-white border border-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-xl text-sm px-5 py-2 text-center mr-2 mb-2' onClick={() => getArielAll('last_month')}>Last Month</button>
                        <button className='w-full text-indigo-700 hover:text-white border border-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-xl text-sm px-5 py-2 text-center mr-2 mb-2' onClick={() => getArielAll('this_month')}>This Month</button>
                    </div>

                    <div>
                        <ArielOverview />
                        {/* <ArielTableAll /> */}
                    </div>

                </div>

                <div>
                    <p className='w-full text-center font-semibold text-2xl mt-10'>Client Info</p>
                    <p className='mb-2'>Select Client</p>
                    <div className='lg:flex flex-row items-end'>
                        <ArielSelectorClient />
                        <ArielLevelSelector />
                        <ArielStartDatePicker />
                        <ArielEndDatePicker />
                        <Button fullWidth style={{ minHeight: '56px' }} variant='outlined' onClick={() => {
                            if (arielClient === '') {
                                arielClientToast()
                            } else {
                                if (arielFBDates.startDate === null || arielFBDates.endDate === null) {
                                    arielDateToast()
                                }
                            }
                            getArielCustom()
                            getArielGoogle(arielClientGoogle)
                        }}>Get Report</Button>
                    </div>
                </div>
                {/* <div className='xl:flex xl:justify-between flex-row my-5'>
                    <div>
                        <p className='mb-2'>Select Date Range</p>
                        <div className='md:flex flex-row'>
                            <ArielSelectorDate />
                            <Button fullWidth style={{ minHeight: '56px' }} variant='outlined' onClick={() => {
                                if (arielClient === '') {
                                    arielClientToast()
                                }
                                getAriel()
                                getArielGoogle(arielClientGoogle)
                            }}>Get Report</Button>
                        </div>
                    </div>
                    <div className='flex items-center justify-center mx-10'>
                        <p className='font-bold text-xl md:m-0 my-4'>Or</p>
                    </div>
                    <div className=''>
                        <p className='mb-2'>Select Date Range</p>
                        <div className='md:flex flex-row'>
                            <ArielStartDatePicker />
                            <ArielEndDatePicker />
                            <Button fullWidth style={{ minHeight: '56px' }} variant='outlined' onClick={() => {
                                if (arielClient === '') {
                                    arielClientToast()
                                } else {
                                    if (arielFBDates.startDate === null || arielFBDates.endDate === null) {
                                        arielDateToast()
                                    }
                                }
                                getArielCustom()
                                getArielGoogle(arielClientGoogle)
                            }}>Get Report</Button>
                        </div>
                    </div>
                </div> */}


            </div>

            <div>
                <div className='my-10'>
                    <ArielSummary />
                </div>

                <div>
                    <ArielTable />
                </div>

                <div className='my-5'>
                    <ArielGoogleTable />
                </div>
            </div>


        </div >
    )
}

export default Ariel