import React from 'react'
import { useContextState } from '../../../ContextAPI'

const MarvinSummary = () => {
    const { marvinData } = useContextState()

    let totalSales = 0
    let totalOrders = 0
    let existingClient = 0
    let newClient = 0
    let marketplace = 0
    let dashpass = 0
    let errors = 0
    marvinData?.rows?.map((r) => {
        totalOrders += 1
        totalSales += r.c[8].v
        if (r.c[4].v === 'Existing') {
            existingClient += 1
        }
        if (r.c[4].v === 'New') {
            newClient += 1
        }
        if (r.c[5].v === true) {
            marketplace += 1
        }
        if (r.c[6].v === true) {
            dashpass += 1
        }
        if (r.c[10].v === true) {
            errors += 1
        }

        return
    })

    return (
        <div className=''>
            <div>
                <p className='font-semibold text-2xl my-4'>Results:</p>
            </div>

            <div className='md:flex flex-row justify-between items-center w-full'>
                <div className='md:mt-0 mt-2'>
                    <p>Total Sales: ${totalSales.toFixed(2)}</p>
                    <p>Total Orders: {totalOrders}</p>
                    <p>Error Rate: {((errors / totalOrders) * 100).toFixed(1)}%</p>
                </div>
                <div className='md:mt-0 mt-2'>
                    <p>Existing Customers: {existingClient}</p>
                    <p>New Customers: {newClient}</p>
                </div>
                <div className='md:mt-0 mt-2'>
                    <p>Dashpass Users: {dashpass}</p>
                    <p>Marketplace Sales: {marketplace}</p>
                </div>
            </div>
        </div>
    )
}

export default MarvinSummary