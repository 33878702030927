import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useContextState } from '../../../ContextAPI';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




export function MarvinBarChart() {

    const { marvinData } = useContextState()

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    let monErrors = 0
    let monOrders = 0
    let tueErrors = 0
    let tueOrders = 0
    let wedErrors = 0
    let wedOrders = 0
    let thuErrors = 0
    let thuOrders = 0
    let friErrors = 0
    let friOrders = 0
    let satErrors = 0
    let satOrders = 0
    let sunErrors = 0
    let sunOrders = 0

    marvinData?.rows?.map((r) => {
        if (r.c[11].v === 'Mon') {
            monOrders += 1
            if (r.c[10].v === true) {
                monErrors += 1
            }
        }
        if (r.c[11].v === 'Tue') {
            tueOrders += 1
            if (r.c[10].v === true) {
                tueErrors += 1
            }
        }
        if (r.c[11].v === 'Wed') {
            wedOrders += 1
            if (r.c[10].v === true) {
                wedErrors += 1
            }
        }
        if (r.c[11].v === 'Thu') {
            thuOrders += 1
            if (r.c[10].v === true) {
                thuErrors += 1
            }
        }
        if (r.c[11].v === 'Fri') {
            friOrders += 1
            if (r.c[10].v === true) {
                friErrors += 1
            }
        }
        if (r.c[11].v === 'Sat') {
            satOrders += 1
            if (r.c[10].v === true) {
                satErrors += 1
            }
        }
        if (r.c[11].v === 'Sun') {
            sunOrders += 1
            if (r.c[10].v === true) {
                sunErrors += 1
            }
        }
        return
    })

    const orders = [monOrders, tueOrders, wedOrders, thuOrders, friOrders, satOrders, sunOrders]
    const errors = [monErrors, tueErrors, wedErrors, thuErrors, friErrors, satErrors, sunErrors]

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Clicks/ Impressions/ Spend',
            },

        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                stacked: true
            },
            y: {
                grid: {
                    display: true
                },
                stacked: true
            }
        },
    };

    //const months = marketResult.map((i) => i.Month)


    const labels = days

    const data = {
        labels,
        datasets: [
            /*         {
                        label: 'CTR',
                        data: ctr,
                        backgroundColor: 'rgba(255, 99, 132, 0.8)',
                    }, */
            {
                label: 'Total Orders',
                data: orders,
                backgroundColor: 'rgba(53, 120, 235, 1)',
            },
            {
                label: 'Errors',
                data: errors,
                backgroundColor: 'rgba(235, 130, 88, 1)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}
