import React from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { CgWebsite } from 'react-icons/cg'
import { MdLogin, MdSportsBasketball } from 'react-icons/md'
import { useContextState } from '../ContextAPI'
import { Link } from 'react-router-dom';
import { SiGooglesheets, SiFacebook, SiGoogleads } from 'react-icons/si'
import { TbApi } from 'react-icons/tb'
import { VscJson } from 'react-icons/vsc'
import { FcPieChart, FcBarChart, FcAreaChart, FcLineChart } from 'react-icons/fc'
import { TbArrowBigRightLines, TbArrowBigDownLines } from 'react-icons/tb'
import { GoDatabase } from 'react-icons/go'
import Services2 from '../components/Services2'
import Services3 from '../components/Services3'


const Home2023 = () => {

    const { color } = useContextState()

    return (
        <div>

            <section className="text-gray-600 body-font flex">
                <div className="container px-5 mx-auto flex flex-wrap">
                    {/* <p className='py-4 px-5 text-4xl font-bold text-gray-600'>Home</p> */}

                    <div className="flex flex-wrap w-full justify-center">

                        {/* banner */}
                        <section className={`py-2 rounded-xl w-full mx-4`} style={{ backgroundColor: color }}>
                            <div className="container px-2 mx-auto">
                                <div className="relative py-11 px-16 rounded-xl overflow-hidden">
                                    <div className="relative z-20 flex flex-wrap items-center -m-3">
                                        <div className="w-full md:w-1/2 p-3">
                                            <p className="mb-6 text-4xl font-bold text-white tracking-tighter"> Welcome to <span className='text-yellow-500'>GC Consultancy</span></p>
                                            <p className="text-white">Where your data works for you!</p>
                                            {/* <p className="text-gray-300">Feel free to browse and demo all the features and components! </p> */}
                                        </div>
                                        <div className="flex-row w-full md:w-1/2 p-3 justify-end">
                                            <div className='xl:flex flex-row justify-around items-center'>
                                                <div className='flex mb-2 justify-center '>
                                                    <GoDatabase className='text-4xl mr-2 text-white' />
                                                    <TbApi className='text-4xl mr-2 text-white' />
                                                    <VscJson className='text-4xl mr-2 text-white' />
                                                    <SiFacebook className='text-4xl mr-2 text-white' />
                                                    <SiGooglesheets className='text-4xl mr-2 text-white' />
                                                    <SiGoogleads className='text-4xl text-white' />
                                                </div>

                                                <div className='flex justify-center'>
                                                    <TbArrowBigRightLines className='xl:block hidden text-4xl text-white animate-moveRight' />
                                                    <TbArrowBigDownLines className='xl:hidden block text-4xl text-white my-3' />
                                                </div>



                                                <div className='flex justify-center'>
                                                    <Link to="/dashboard">
                                                        <div className='flex-row border-2 rounded-xl p-1 justify-center hover:scale-105 hover:border-emerald-300 h-40 w-40'>
                                                            {/*  ping */}
                                                            <div className="relative">
                                                                <div className="absolute top-0 right-0 -mr-2 -mt-2 w-3 h-3 rounded-full bg-emerald-300 animate-ping"></div>
                                                                <div className="absolute top-0 right-0 -mr-2 -mt-2 w-3 h-3 rounded-full bg-emerald-300"></div>
                                                            </div>
                                                            {/*  ping */}

                                                            <div className='flex justify-center'>
                                                                <FcAreaChart className='text-6xl' />
                                                                <FcLineChart className='text-6xl' />
                                                            </div>
                                                            <div className='flex justify-center'>
                                                                <FcBarChart className='text-6xl' />
                                                                <FcPieChart className='text-6xl' />
                                                            </div>
                                                            <div className='flex justify-center'>
                                                                <p className='text-white text-sm'>Demo Dashboard</p>
                                                            </div>

                                                        </div>

                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* banner */}



                        {/* services */}
                        <div className='flex container justify-center mb-10'>
                            <Services3 />
                        </div>
                        {/* services */}



                        {/* middle */}
                        <section className='lg:flex flex-row rounded-xl w-full lg:mx-auto mx-4 mb-20'>

                            <Link to='/about' className="container flex flex-wrap items-center justify-center hover:bg-emerald-100 sm:py-10 py-5 lg:w-1/3 w-full lg:mx-4 my-4 border-2 rounded-xl">
                                <div className='flex items-center px-4'>
                                    <div className='rounded-full bg-emerald-200 p-5'>
                                        <BiInfoCircle className='sm:text-5xl text-3xl text-emerald-600' />
                                    </div>
                                    <div className='text-left ml-4'>
                                        <p className='text-slate-700 font-bold text-xl'>About Me</p>
                                        <p className='text-slate-700 sm:block hidden'>Click here for information about me</p>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/portfolio_sports' className="container flex flex-wrap items-center justify-center hover:bg-sky-100 sm:py-10 py-5 lg:w-1/3 w-full lg:mx-4 my-4 border-2 rounded-xl">
                                <div className='flex items-center px-4 '>
                                    <div className='rounded-full bg-sky-200 p-5'>
                                        <MdSportsBasketball className='sm:text-5xl text-3xl text-sky-600' />
                                    </div>
                                    <div className='text-left ml-4'>
                                        <p className='text-slate-700 font-bold text-xl'>Portfolio</p>
                                        <p className='text-slate-700 sm:block hidden'>Check out my work in sports betting</p>
                                    </div>
                                </div>
                            </Link>

                            <Link to='clientlogin' className="container flex flex-wrap items-center justify-center hover:bg-rose-100 sm:py-10 py-5 lg:w-1/3 w-full lg:mx-4 my-4 border-2 rounded-xl">
                                <div className='flex items-center px-4'>
                                    <div className='rounded-full bg-rose-200 p-5'>
                                        <MdLogin className='sm:text-5xl text-3xl text-rose-600' />
                                    </div>
                                    <div className='text-left ml-4'>
                                        <p className='text-slate-700 font-bold text-xl'>Client Login</p>
                                        <p className='text-slate-700 sm:block hidden'>Login here for existing clients</p>
                                    </div>
                                </div>
                            </Link>

                        </section>
                        {/* middle */}



                    </div>
                </div >
            </section >



        </div >
    )
}

export default Home2023