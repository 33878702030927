import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useContextState } from '../ContextAPI'


const ArielRoute = () => {
    const { user } = useContextState()

    if (user?.email === process.env.REACT_APP_CLIENT2 || user?.email === process.env.REACT_APP_CLIENT1) {
        return <Outlet />
    } else {
        return <Navigate to="/clientlogin" />
    }


}

export default ArielRoute