import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { faker } from '@faker-js/faker'


ChartJS.register(ArcElement, Tooltip, Legend);



export function PieChart() {

    const fakerData = ([faker.datatype.number({ max: 100 }), faker.datatype.number({ max: 80 }), faker.datatype.number({ max: 30 }), faker.datatype.number({ max: 30 }), faker.datatype.number({ max: 30 })])


    const data = {
        labels: ['Facebook', 'Google', 'Instagram', 'Youtube', 'Other'],
        datasets: [
            {
                label: '',
                data: fakerData,
                backgroundColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 3,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                /* formatter: function (value, context) {
                    return context.chart.data.labels[context.dataIndex];
                } */
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Lead Sources',
            },
        },
    };
    return <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />;
}
