import React from 'react'
import ClientTable from '../components/ClientTable';
import ClientModalAdd from '../forms/ClientModalAdd';


const Clients = () => {



    return (
        <div className='m-2 md:p-10 p-2 bg-white rounded-3xl'>
            <p className='pb-4 text-4xl font-bold text-gray-600 w-full'>Clients</p>
            <p className='pb-4 text-base text-gray-600 w-full'>Add, Edit, or Delete clients</p>
            <div>
                <ClientModalAdd />
                <ClientTable />
            </div>


        </div>
    )
}

export default Clients