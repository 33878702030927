import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { useContextState } from '../../../ContextAPI'

const ArielSummary = () => {
    const { arielData, arielGoogleLeadCount, arielStartEnd } = useContextState()
    const [budget, setBudget] = useState(0)

    let spend = 0
    let clicks = 0
    let impressions = 0


    arielData?.data?.map((campaign) => {
        spend += parseFloat(campaign.spend)
        clicks += parseFloat(campaign.clicks)
        impressions += parseFloat(campaign.impressions)
    })


    return (
        <div>
            <div className='flex-row w-full my-5'>
                <p className='font-bold text-xl'>Report</p>
                <div className='md:flex flex-row items-end md:mb-2'>
                    <TextField label='Enter Budget' variant='standard' type='number' onChange={(e) => setBudget(e.target.value)} />
                    <p className='md:ml-5'><b>Remaining Budget: </b>${(budget - spend).toFixed(2)}</p>
                    <p className='md:ml-5'><b>Spend/Day: </b>${isNaN((spend / ((arielStartEnd?.endDate - arielStartEnd?.startDate) / (1000 * 3600 * 24))).toFixed(2)) ? 0 : (spend / ((arielStartEnd?.endDate - arielStartEnd?.startDate) / (1000 * 3600 * 24))).toFixed(2)}</p>
                </div>
                <div className='md:flex flex-row md:mb-2'>
                    <p><b>Total Spend:</b> ${spend.toFixed(2)}</p>
                    <p className='md:ml-5'><b>Total Clicks:</b> {clicks}</p>
                    <p className='md:ml-5'><b>Total Leads (Google Sheets):</b> {arielGoogleLeadCount}</p>
                    <p className='md:ml-5'><b>CPL:</b> ${arielGoogleLeadCount === 0 ? 0 : (spend / arielGoogleLeadCount).toFixed(2)} </p>
                    <p className='md:ml-5'><b>Campaign CPM:</b> ${isNaN((spend / (impressions / 1000)).toFixed(2)) ? 0 : (spend / (impressions / 1000)).toFixed(2)} </p>
                </div>
                <div className='md:flex flex-row'>
                    <p className=''><b>Start Date:</b> {arielStartEnd?.startDate?.toDateString()}</p>
                    <p className='md:ml-5'><b>End Date:</b> {arielStartEnd?.endDate?.toDateString()}</p>
                    <p className='md:ml-5'><b>Total Days:</b> {(arielStartEnd?.endDate - arielStartEnd?.startDate) / (1000 * 3600 * 24)}</p>
                </div>

            </div>
        </div>
    )
}

export default ArielSummary