import React from 'react'
import budget from '../assets/budget.jpg'
import crypto from '../assets/crypto.jpg'
import food from '../assets/food.jpg'
import league from '../assets/league.jpg'
import trends from '../assets/trends.jpg'
import web3 from '../assets/web3.jpg'
import oldsite from '../assets/oldsite.jpg'

const Card = ({ title, subtitle, pic, url }) => {
  return (

    <div className="lg:w-3/12 w-full bg-white rounded-lg border-2 border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700 my-6 mx-10">
      <a href={url} target='_blank' rel="noreferrer">
        <img className="rounded-t-lg " src={pic} alt="" />
        <div className="p-5">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
          <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">{subtitle}</p>
          <span className="inline-flex items-center py-2 px-3 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Demo Site
          </span>
        </div>
      </a>
    </div>


  )
}

const Portfolio = () => {
  return (
    <div className='mb-10'>

      <section className="py-4 w-full flex  items-center flex-wrap text-gray-600 body-font ">

        <div className='sm:flex-row flex flex-wrap' >
          <p className='px-10 text-4xl font-bold text-gray-600 mb-5' >Portfolio</p>

          <div className='sm:flex-row justify-center flex flex-wrap' >
            <Card title='Web 3.0 Project' subtitle='Send and record transactions on the ETH blockchain' pic={web3} url='https://web3-2e4df.web.app/' />
            <Card title='Crypto Tracker' subtitle='Live prices with charts with your own custom watchlist for all your Cryptocurrencies' pic={crypto} url='https://coin-table-411d1.web.app/' />
            <Card title='League Of Legends Stats' subtitle='Get statistics with charts for your recent LOL games' pic={league} url='https://leaguefront-4c1ec.web.app/' />
            <Card title='Expense Tracker' subtitle='Enter and store your daily expenses' pic={budget} url='https://budget-645aa.web.app/' />
            <Card title='Google Trends vs Stocks' subtitle='Check Google Trends vs Stocks vs Crypto charts to identify any trends and patterns' pic={trends} url='https://trends-1a963.web.app/' />
            <Card title='Food Recipe Site' subtitle='Search for your favourite receipes' pic={food} url='https://food-a15a0.web.app/' />
            <Card title='Old Personal Website' subtitle='About Me' pic={oldsite} url='https://gchome-5b8a4.web.app/' />
          </div>
        </div>




      </section>

    </div>
  )
}

export default Portfolio