import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useContextState } from '../../../ContextAPI';
import { Box, FormControl } from '@mui/material';


export default function ArielStartDatePicker() {
    const [value, setValue] = React.useState(null);
    const { arielFBDates, setArielFBDates } = useContextState()

    console.log(arielFBDates)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                    <DatePicker
                        label="Start Date"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            setArielFBDates({
                                ...arielFBDates, startDate: new Date(newValue).toISOString().split('T')[0]
                            })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
            </Box>
        </LocalizationProvider>
    );
}
