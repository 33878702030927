import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContextState } from '../../../ContextAPI';

export default function RoryNHLHomeSelector() {
    const [age, setAge] = React.useState("Anaheim Ducks");

    const { setHomeTeam } = useContextState()

    const handleChange = (event) => {
        setAge(event.target.value);
        setHomeTeam(event.target.value)
    };

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Team 1</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={"Anaheim Ducks"}>Anaheim Ducks</MenuItem>
                    <MenuItem value={"Arizona Coyotes"}>Arizona Coyotes</MenuItem>
                    <MenuItem value={"Boston Bruins"}>Boston Bruins</MenuItem>
                    <MenuItem value={"Buffalo Sabres"}>Buffalo Sabres</MenuItem>
                    <MenuItem value={"Calgary Flames"}>Calgary Flames</MenuItem>
                    <MenuItem value={"Carolina Hurricanes"}>Carolina Hurricanes</MenuItem>
                    <MenuItem value={"Chicago Blackhawks"}>Chicago Blackhawks</MenuItem>
                    <MenuItem value={"Colorado Avalanche"}>Colorado Avalanche</MenuItem>
                    <MenuItem value={"Columbus Blue Jackets"}>Columbus Blue Jackets</MenuItem>
                    <MenuItem value={"Dallas Stars"}>Dallas Stars</MenuItem>
                    <MenuItem value={"Detroit Red Wings"}>Detroit Red Wings</MenuItem>
                    <MenuItem value={"Edmonton Oilers"}>Edmonton Oilers</MenuItem>
                    <MenuItem value={"Florida Panthers"}>Florida Panthers</MenuItem>
                    <MenuItem value={"Los Angeles Kings"}>Los Angeles Kings</MenuItem>
                    <MenuItem value={"Minnesota Wild"}>Minnesota Wild</MenuItem>
                    <MenuItem value={"Montreal Canadiens"}>Montreal Canadiens</MenuItem>
                    <MenuItem value={"Nashville Predators"}>Nashville Predators</MenuItem>
                    <MenuItem value={"New Jersey Devils"}>New Jersey Devils</MenuItem>
                    <MenuItem value={"New York Islanders"}>New York Islanders</MenuItem>
                    <MenuItem value={"New York Rangers"}>New York Rangers</MenuItem>
                    <MenuItem value={"Ottawa Senators"}>Ottawa Senators</MenuItem>
                    <MenuItem value={"Philadelphia Flyers"}>Philadelphia Flyers</MenuItem>
                    <MenuItem value={"San Jose Sharks"}>San Jose Sharks</MenuItem>
                    <MenuItem value={"Seattle Kraken"}>Seattle Kraken</MenuItem>
                    <MenuItem value={"St. Louis Blues"}>St. Louis Blues</MenuItem>
                    <MenuItem value={"Tampa Bay Lightning"}>Tampa Bay Lightning</MenuItem>
                    <MenuItem value={"Toronto Maple Leafs"}>Toronto Maple Leafs</MenuItem>
                    <MenuItem value={"Vancouver Canucks"}>Vancouver Canucks</MenuItem>
                    <MenuItem value={"Vegas Golden Knights"}>Vegas Golden Knights</MenuItem>
                    <MenuItem value={"Washington Capitals"}>Washington Capitals</MenuItem>
                    <MenuItem value={"Winnipeg Jets"}>Winnipeg Jets
</MenuItem>
                </Select>
            </FormControl>
        </Box >
    );
}
