import React from 'react'
import { BarChart } from '../components/charts/BarChart'
import { BarHoriChart } from '../components/charts/BarHoriChart'
import { LineChart } from '../components/charts/LineChart'
import { useContextState } from '../ContextAPI'
import DashboardClientSelector from '../forms/selectors/DashboardClientSelector'
import { faker } from '@faker-js/faker'
import { MdCampaign } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg'
import GaugeChart from 'react-gauge-chart'
import AudienceSection from '../components/AudienceSection'
import DashboardCampaignSelector from '../forms/selectors/DashboardCampaignSelector'
import { Link } from 'react-router-dom';


const Dashboard = () => {
    const { totalImpressionsAsOne, totalSpendAsOne, clientIndex, totalClicksAsOne, campaignCount, totalSpendAsClient, totalClicksAsClient, totalImpressionsAsClient, campaignIndex, totalSingleClicks, totalSingleImpressions, totalSingleSpend } = useContextState()

    const clickChange = faker.datatype.number({ min: -10, max: 30 })
    const impressionChange = faker.datatype.number({ min: -10, max: 30 })
    const ctrChange = faker.datatype.number({ min: -10, max: 30 })

    return (
        <section className="text-gray-600 body-font mb-10">

            <div className="container px-5 mx-auto flex flex-wrap">
                <p className='py-4 px-5 text-4xl font-bold text-gray-600 w-full'>Welcome! <span className='text-base font-normal'>This dashboard is a fully functional full stack (MERN) application.</span></p>

                <div className='mb-3'>
                    <p className='px-5'>Feel free to add, edit, delete any data to fully test it's functionalities.</p>
                    <p className='px-5'>You can start by adding a <Link to='/clients' className='underline'>client</Link> then a <Link to='/campaigns' className='underline'>campaign</Link>, or simply just browse the current data.</p>
                </div>

                <div className='md:flex flex-row w-full justify-between items-center p-4'>
                    <div className='md:w-1/2 w-full'>
                        <DashboardClientSelector />
                        {clientIndex !== 'all' ? <div className='mt-4'><DashboardCampaignSelector /></div> : <></>}
                    </div>

                    <div className='md:flex flex-row items-center justify-between md:w-1/2 w-full md:ml-10 ml-0 md:mt-0 mt-5'>
                        <p className='pl-1'>Campaign Count:  {campaignCount}</p>
                        <Link to='/clients' className='mx-3 flex items-center px-2 rounded-lg border-2 text-blue-600 md:my-0 my-2 md:py-0 py-3'><CgProfile className='mr-2' />View Clients</Link>
                        <Link to='/campaigns' className='mx-3 flex items-center px-2 rounded-lg border-2 text-blue-600 md:my-0 my-2 md:py-0 py-3'><MdCampaign className='mr-2' />View Campaigns</Link>
                    </div>

                </div>

                <div className="flex-wrap w-full">
                    <div className="flex flex-wrap w-full">

                        {/* total clicks */}
                        <div className="p-4 lg:w-1/4 md:w-full w-full">
                            <div className="flex border-2 rounded-xl border-gray-200 px-8 sm:flex-row flex-col items-center h-full">
                                {/* content */}
                                <div className="p-5 bg-white">
                                    <div className="text-base text-gray-400 ">Total Clicks</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-2xl font-bold text-gray-900 ">{clientIndex === 'all' ? `${totalClicksAsOne}` : campaignIndex === 'all' ? `${totalClicksAsClient}` : `${totalSingleClicks}`}</div>

                                        {/* red/green */}
                                        {totalClicksAsClient === undefined && clientIndex !== 'all' ? <></> : (
                                            clickChange > 0 ? (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-green-600 bg-green-100 ">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{clickChange}%</span>
                                                </span>
                                            ) : (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-red-600 bg-red-100 ">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{clickChange}%</span>
                                                </span>
                                            )
                                        )}
                                        {/* red/green */}


                                    </div>
                                </div>
                                {/* content */}
                            </div>
                        </div>
                        {/* total clicks */}

                        {/* impressions */}
                        <div className="p-4 lg:w-1/4 md:w-full w-full">
                            <div className="flex border-2 rounded-xl border-gray-200 px-8 sm:flex-row flex-col items-center h-full">
                                {/* content */}
                                <div className="p-5 bg-white ">
                                    <div className="text-base text-gray-400 ">Impressions</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-2xl font-bold text-gray-900 ">{clientIndex === 'all' ? `${totalImpressionsAsOne}` : campaignIndex === 'all' ? `${totalImpressionsAsClient}` : `${totalSingleImpressions}`}</div>

                                        {/* red/green */}
                                        {totalImpressionsAsClient === undefined && clientIndex !== 'all' ? <></> : (
                                            impressionChange > 0 ? (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-green-600 bg-green-100 ">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{impressionChange}%</span>
                                                </span>
                                            ) : (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-red-600 bg-red-100 ">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{impressionChange}%</span>
                                                </span>
                                            )
                                        )}
                                        {/* red/green */}

                                    </div>
                                </div>
                                {/* content */}
                            </div>
                        </div>
                        {/* impressions */}

                        {/* click through rate */}
                        <div className="p-4 lg:w-1/4 md:w-full w-full">
                            <div className="flex border-2 rounded-xl border-gray-200 px-8 sm:flex-row flex-col items-center h-full">
                                {/* content */}
                                <div className="p-5 bg-white ">
                                    <div className="text-base text-gray-400 ">Click Through Rate</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-2xl font-bold text-gray-900 ">{clientIndex === 'all' ? `${((totalClicksAsOne / totalImpressionsAsOne) * 100)?.toFixed(2)}%` : campaignIndex === 'all' ? `${((totalClicksAsClient / totalImpressionsAsClient) * 100)?.toFixed(2)}%` : `${((totalSingleClicks / totalSingleImpressions) * 100)?.toFixed(2)}%`}</div>

                                        {/* red/green */}
                                        {totalClicksAsClient === undefined && clientIndex !== 'all' ? <></> : (
                                            ctrChange > 0 ? (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-green-600 bg-green-100">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{ctrChange}%</span>
                                                </span>
                                            ) : (
                                                < span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-red-600 bg-red-100">
                                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span>{ctrChange}%</span>
                                                </span>
                                            )
                                        )}
                                        {/* red/green */}

                                    </div>
                                </div>
                                {/* content */}
                            </div>
                        </div>
                        {/* click through rate */}

                        {/* spend */}
                        <div className="p-4 lg:w-1/4 md:w-full w-full">
                            <div className="flex border-2 rounded-xl border-gray-200 px-8 sm:flex-row flex-col items-center h-full">
                                {/* content */}
                                <div className="p-5 bg-white ">
                                    <div className="text-base text-gray-400 ">Ad Spent</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-2xl font-bold text-gray-900 ">${clientIndex === 'all' ? `${(totalSpendAsOne)?.toFixed(2)}` : campaignIndex === 'all' ? `${(totalSpendAsClient)?.toFixed(2)}` : `${(totalSingleSpend)?.toFixed(2)}`}</div>
                                        {/* <span className="flex items-center px-2 py-0.5 mx-2 text-sm rounded-full text-red-600 bg-red-100 ">
                                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <span>2.5%</span>
                                        </span> */}
                                    </div>
                                </div>
                                {/* content */}
                            </div>
                        </div>
                        {/* spend */}


                        <div className='flex flex-wrap flex-1 w-full'>
                            {/* line */}
                            <div className="p-4 lg:w-1/2 md:w-full w-full">
                                <div className="flex border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                                    {/* content */}
                                    <div className=" bg-white w-full">
                                        <div className="flex text-xl text-gray-600 mb-5 md:justify-start justify-center mt-5">Impressions/ Click</div>
                                        <div className="flex items-center pt-1 h-72">
                                            <LineChart />
                                        </div>
                                    </div>

                                    {/* content */}
                                </div>
                            </div>
                            {/* line */}

                            {/* doughnut */}
                            <div className="p-4 lg:w-1/2 w-full grid">
                                <div className="border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                                    <div className="flex text-xl text-gray-600 md:mb-5 mb-0 md:justify-start justify-center  mt-5">
                                        Click Through Rate
                                    </div>
                                    <GaugeChart
                                        nrOfLevels={20}
                                        percent={clientIndex === 'all' ? `${(totalClicksAsOne / totalImpressionsAsOne)}` : campaignIndex === 'all' ? `${(totalClicksAsClient / totalImpressionsAsClient)}` : `${totalSingleClicks / totalSingleImpressions}`}
                                        colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                        textColor='#00000'
                                        style={{ height: 250 }}
                                        className='flex items-center'
                                    />

                                    {/* content */}
                                </div>
                            </div>
                            {/* doughnut */}
                        </div>


                        {/* vertical bar */}
                        <div className="p-4 lg:w-full md:w-full w-full md:block hidden">
                            <div className="flex border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                                {/* content */}
                                <div className="bg-white w-full">
                                    <div className="flex text-xl text-gray-600 mb-5 md:justify-start justify-center  mt-5">
                                        Overview
                                    </div>
                                    <div className="flex items-center pt-1">
                                        <BarChart />
                                    </div>
                                </div>
                                {/* content */}
                            </div>
                        </div>
                        {/* vertical bar */}

                        {/* vertical bar */}
                        <div className="p-4 lg:w-full md:w-full w-full md:hidden block">
                            <div className="flex border-2 rounded-xl border-gray-200 sm:flex-row flex-col items-center justify-center md:px-4 px-2">
                                {/* content */}
                                <div className="bg-white w-full">
                                    <div className="flex text-xl text-gray-600 mb-5 md:justify-start justify-center mt-5">
                                        Overview
                                    </div>
                                    <div className="relative flex items-center pt-1 h-96">
                                        <BarHoriChart />
                                    </div>
                                </div>

                                {/* content */}
                            </div>
                        </div>
                        {/* vertical bar */}

                        <AudienceSection />
                    </div>


                </div>



            </div>

        </section >
    )
}

export default Dashboard