import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useContextState } from '../../../ContextAPI';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'customer_id',
        numeric: false,
        disablePadding: false,
        label: 'customer_id',
    },
    {
        id: 'order_id',
        numeric: true,
        disablePadding: false,
        label: 'order_id',
    },
    {
        id: 'store',
        numeric: true,
        disablePadding: false,
        label: 'store',
    },
    {
        id: 'order_date',
        numeric: true,
        disablePadding: false,
        label: 'order_date',
    },
    {
        id: 'customer_category',
        numeric: true,
        disablePadding: false,
        label: 'customer_category',
    },
    {
        id: 'marketplace_deliery',
        numeric: true,
        disablePadding: false,
        label: 'marketplace_deliery',
    },
    {
        id: 'dashpass_delivery',
        numeric: true,
        disablePadding: false,
        label: 'dashpass_delivery',
    },
    {
        id: 'pickup,',
        numeric: true,
        disablePadding: false,
        label: 'pickup,',
    },
    {
        id: 'order_total',
        numeric: true,
        disablePadding: false,
        label: 'order_total',
    },
    {
        id: 'avoidable_Wait',
        numeric: true,
        disablePadding: false,
        label: 'avoidable_Wait',
    },
    {
        id: 'is_missing_incorrect',
        numeric: true,
        disablePadding: false,
        label: 'is_missing_incorrect',
    },
    {
        id: 'order_day',
        numeric: true,
        disablePadding: false,
        label: 'order_day',
    },
    {
        id: 'order_month',
        numeric: true,
        disablePadding: false,
        label: 'order_month',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    /* onSelectAllClick: PropTypes.func.isRequired, */
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Data
                </Typography>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function MarvinDataTable() {

    const { marvinData } = useContextState()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    function createData(customer_id, order_id, store, order_date, customer_category, marketplace_deliery, dashpass_delivery, pickup, order_total, avoidable_Wait, is_missing_incorrect, order_day, order_month) {
        return {
            customer_id,
            order_id,
            store,
            order_date,
            customer_category,
            marketplace_deliery,
            dashpass_delivery,
            pickup,
            order_total,
            avoidable_Wait,
            is_missing_incorrect,
            order_day,
            order_month
        };
    }

    const rows = []
    marvinData?.rows?.map((r) => {
        rows.push(createData(r?.c[0].v, r?.c[1].v, r?.c[2].v, r?.c[3].f, r?.c[4].v, r?.c[5].f, r?.c[6].f, r?.c[7].f, r?.c[8].v, r?.c[9].v, r?.c[10].f, r?.c[11].v, r?.c[12].v))
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.name}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                            >
                                                {row.customer_id}
                                            </TableCell>
                                            <TableCell align="right">{row.order_id}</TableCell>
                                            <TableCell align="right">{row.store}</TableCell>
                                            <TableCell align="right">{row.order_date}</TableCell>
                                            <TableCell align="right">{row.customer_category}</TableCell>
                                            <TableCell align="right">{row.marketplace_deliery}</TableCell>
                                            <TableCell align="right">{row.dashpass_delivery}</TableCell>
                                            <TableCell align="right">{row.pickup}</TableCell>
                                            <TableCell align="right">{row.order_total}</TableCell>
                                            <TableCell align="right">{row.avoidable_Wait}</TableCell>
                                            <TableCell align="right">{row.is_missing_incorrect.toString()}</TableCell>
                                            <TableCell align="right">{row.order_day}</TableCell>
                                            <TableCell align="right">{row.order_month}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
