import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useContextState } from '../ContextAPI';
import EditIcon from '@mui/icons-material/Edit';




export default function CampaignAddModal({ data: selectedData, campaign: campaign }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const { campaignID, editMonthData, campaignNameAndParent } = useContextState()


    /* console.log(selectedData)
    console.log(campaign)
    console.log(campaignID)
    console.log(campaignNameAndParent) */

    const [clientFormData, setClientFormData] = useState(
        {
            campaignName: campaignNameAndParent.campaignName,
            parentId: campaignNameAndParent.parentID,
            data: {
                january: {
                    clicks: campaign[0].clicks,
                    impressions: campaign[0].impressions,
                    spend: campaign[0].spend,
                },
                february: {
                    clicks: campaign[1].clicks,
                    impressions: campaign[1].impressions,
                    spend: campaign[1].spend,
                },
                march: {
                    clicks: campaign[2].clicks,
                    impressions: campaign[2].impressions,
                    spend: campaign[2].spend,
                },
                april: {
                    clicks: campaign[3].clicks,
                    impressions: campaign[3].impressions,
                    spend: campaign[3].spend,
                },
                may: {
                    clicks: campaign[4].clicks,
                    impressions: campaign[4].impressions,
                    spend: campaign[4].spend,
                },
                june: {
                    clicks: campaign[5].clicks,
                    impressions: campaign[5].impressions,
                    spend: campaign[5].spend,
                },
                july: {
                    clicks: campaign[6].clicks,
                    impressions: campaign[6].impressions,
                    spend: campaign[6].spend,
                },
                august: {
                    clicks: campaign[7].clicks,
                    impressions: campaign[7].impressions,
                    spend: campaign[7].spend,
                },
                september: {
                    clicks: campaign[8].clicks,
                    impressions: campaign[8].impressions,
                    spend: campaign[8].spend,
                },
                october: {
                    clicks: campaign[9].clicks,
                    impressions: campaign[9].impressions,
                    spend: campaign[9].spend,
                },
                november: {
                    clicks: campaign[10].clicks,
                    impressions: campaign[10].impressions,
                    spend: campaign[10].spend,
                },
                december: {
                    clicks: campaign[11].clicks,
                    impressions: campaign[11].impressions,
                    spend: campaign[11].spend,
                },
            }
        }
    )



    const clearForm = () => {
        setClientFormData({
            campaignName: '',
            parentId: null,
            data: {
                january: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                february: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                march: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                april: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                may: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                june: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                july: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                august: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                september: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                october: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                november: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },
                december: {
                    clicks: 0,
                    impressions: 0,
                    spend: 0,
                },

            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        //addCampaign(selectedID, clientFormData)
        //console.log(clientFormData)
        editMonthData(campaignID, clientFormData)

        handleClose()
        clearForm()

    }

    const MonthBox = ({ month }) => {

        return (
            <div className='md:flex flex-row items-center w-full justify-between'>
                <p className='px-5 text-center md:py-0 py-2 capitalize'>{month}</p>
                <div className='flex md:w-1/2 w-full'>
                    <TextField variant='outlined' label='Clicks' type='number' fullWidth value={clientFormData.data[month].clicks} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], clicks: e.target.valueAsNumber } } })} />
                    <TextField variant='outlined' label='Impressions' type='number' fullWidth value={clientFormData.data[month].impressions} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], impressions: e.target.valueAsNumber } } })} />
                    <TextField variant='outlined' label='Spend ($)' type='number' fullWidth value={clientFormData.data[month].spend} onChange={(e) => setClientFormData({ ...clientFormData, data: { ...clientFormData.data, [month]: { ...clientFormData.data[month], spend: e.target.valueAsNumber } } })} />
                </div>
            </div>
        )
    }




    return (
        <div>
            <Button onClick={handleOpen} className='text-base text-blue-500 flex'><EditIcon />Edit Data</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box className='absolute top-1/2 left-1/2 border-2 p-4 bg-white -translate-x-1/2 -translate-y-1/2 sm:w-1/2 w-full overflow-y-scroll max-h-screen'>
                    <div className='flex justify-between'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Campaign Information
                        </Typography>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleClose}>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>

                    {/* form */}
                    <form autoComplete='off' onSubmit={handleSubmit} className=''>

                        <div className='flex-row items-center'>
                            {MonthBox({ month: selectedData.month })}
                        </div>



                        <div className='flex mt-5'>
                            <Button variant='contained' color='primary' size='large' type='submit' fullWidth>Submit</Button>

                            <Button variant='contained' color='secondary' size='small' onClick={clearForm} fullWidth>Clear</Button>

                        </div>


                    </form>

                </Box>
            </Modal>
        </div >
    );
}
