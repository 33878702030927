import React, { useState } from 'react'
import { useContextState } from '../ContextAPI'
import { auth } from '../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'


const ClientLogin = () => {
    const { loginErrorToast, color, loginSuccessToast, loginFillToast, user, contactGCToast } = useContextState()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()


    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!email || !password) {
            loginFillToast()
            return
        }

        //login
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)

            loginSuccessToast()

            if (result.user.email === process.env.REACT_APP_CLIENT1) {
                navigate('/gilbert')
            }
            if (result.user.email === process.env.REACT_APP_CLIENT2) {
                navigate('/ariel')
            }
            if (result.user.email === process.env.REACT_APP_CLIENT3) {
                navigate('/marvin')
            }
            if (result.user.email === process.env.REACT_APP_CLIENT4) {
                navigate('/rory')
            }
            if (result.user.email === process.env.REACT_APP_CLIENT5) {
                navigate('/oradigital')
            }

        } catch (error) {
            loginErrorToast()
        }



    }



    return (
        <div className='mt-20'>
            {(!user)
                ?
                (
                    <div className="bg-white p-5 max-w-md mx-auto shadow-sm border-2 rounded-xl">
                        <h2 className="text-4xl px-4">Log In</h2>
                        <form className="mt-10 space-y-8" onSubmit={handleSubmit}>
                            <input
                                className="w-full border rounded h-12 px-4 focus:outline-none"
                                placeholder="Email Address "
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <div className="flex items-center ">
                                <input
                                    className="w-full border rounded h-12 px-4 focus:outline-none -mr-7"
                                    placeholder="Password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.607"
                                    height="17.076"
                                    viewBox="0 0 17.607 17.076"
                                >
                                    <path
                                        id="eye-off"
                                        d="M12.392,16.769a8.718,8.718,0,0,1-9.935-5.938A8.675,8.675,0,0,1,3.817,8.2m5.1.79a2.611,2.611,0,1,1,3.692,3.692M8.914,8.985,12.6,12.675M8.916,8.986,6.053,6.124m6.554,6.554,2.863,2.863M2.929,3,6.053,6.124m0,0a8.7,8.7,0,0,1,13.011,4.707,8.723,8.723,0,0,1-3.6,4.708m0,0,3.123,3.123"
                                        transform="translate(-1.957 -2.293)"
                                        fill="none"
                                        stroke="#949090"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeidth="1"
                                    />
                                </svg>
                            </div>
                            <div>
                                <div className="flex flex-col md:flex-row md:items-center justify-between gap-24">
                                    <button
                                        style={{ backgroundColor: color }}
                                        className="text-sm active:bg-gray-700 cursor-pointer font-regular text-white px-4 py-2 rounded uppercase"
                                        type="submit"
                                        value="login now"
                                    //onClick={() => loginErrorToast()}
                                    >
                                        Login now
                                    </button>
                                    <button type='button' onClick={() => contactGCToast()}>
                                        <p className="text-gray-400 text-sm underline self-center md:self-auto mt-4 md:mt-0">
                                            Forgot your password?
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                )
                :
                (
                    <div className="bg-white p-5 max-w-md mx-auto shadow-sm border-2 rounded-xl">
                        <p className='flex justify-center'>You Are Already Logged In</p>
                    </div>
                )}



        </div>
    )
}

export default ClientLogin