import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { MdOutlineLogout } from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useContextState } from '../ContextAPI';
import { signOut } from 'firebase/auth'
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';




const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <TooltipComponent content={title} position='BottomCenter'>
        <button type='button' onClick={customFunc} style={{ color }} className='relative text-xl rounded-full p-3 hover:bg-light-gray'>
            <span style={{ background: dotColor }} className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'></span>
            {icon}
        </button>
    </TooltipComponent>
)


const Navbar = () => {
    const { setActiveMenu, color, user, logoutSuccessToast } = useContextState()
    const navigate = useNavigate()

    const logout = () => {
        signOut(auth)
        logoutSuccessToast()
        navigate('/clientlogin')
    }



    return (
        <div className='flex p-2 relative justify-between items-center'>
            <NavButton
                title='Menu'
                customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
                color={color}
                icon={<AiOutlineMenu className='mx-6 text-xl' />}
            />

            {user && <div className='flex items-center '>
                <p className='md:block hidden'>Welcome, {user.email}</p>
                <NavButton
                    title='Logout'
                    customFunc={() => { logout() }}
                    color={color}
                    icon={<MdOutlineLogout className='text-xl' />}
                />
            </div>}


        </div>
    )
}

export default Navbar